import { Content } from '../models/shared/content';
import moment from 'moment';
import { getGlobals } from '../../globals';
import { CustomerStatus } from '../models/shared';
import utils from '../../application/general/utils';

export const sendErrorReport = (appContext, deviceContext, userContext, errorType: any, content: Content, error) => {
    if (userContext.user.customer == undefined) {
        return '';
    }
    var reporting = {
        errorType: errorType,
        error: error,
        timestamp: moment().unix(),
        service: userContext.user.crm,
        content: content,
        app: {
            version: deviceContext.version,
            package: deviceContext.packageId,
        },
        location: {
            city: userContext.city,
            country: userContext.country,
            latitude: userContext.latitude,
            longitude: userContext.longitude,
        },
        device: {
            model: deviceContext.model,
            type: deviceContext.type,
            os: deviceContext.os,
            uuid: deviceContext.uniqueId,
            formFactor: deviceContext.formFactor,
        },
        network: {
            ipAddress: deviceContext.ipAddress,
            type: deviceContext.networkType,
            provider: deviceContext.provider,
        },
        customer: {
            username: userContext.userId,
            status: CustomerStatus.ACTIVE,
            zipcode: userContext.user.customer.zipcode,
            state: userContext.user.customer.state,
            city: userContext.user.customer.city,
            country: userContext.user.customer.country,
            shopping_cartName: userContext.user.customer.shopping_cartName,
            shopping_cartId: userContext.user.customer.shopping_cartId,
            credit_amount: userContext.user.customer.credit_amount,
            wallet_amount: userContext.user.customer.wallet_amount,
            billing_country: userContext.user.customer.billing_country,
            billing_currency: userContext.user.customer.billing_currency,
            billing_taxrate: userContext.user.customer.billing_taxrate,
            originalCreatedAt: userContext.user.customer.originalCreatedAt,
            is_deleted: false,
            productStatus: userContext.user.variant,
            subscriptionStatus: userContext.user.status,
            renewedDate: userContext.user.start,
            renewalDate: userContext.user.expire,
            payment_provider: userContext.user.customer.payment_provider_name,
            payment_auto_recurring: userContext.user.customer.payment_provider_id,

            plan: userContext.user.plan,
            baseSubscription: userContext.user.base_product,
            extraSubscriptions: userContext.user.extra_products,
        },
    };

    // //safe up the reports
    // global.ErrorReports.push(reporting);
    // if (global.ErrorReports.length < 10) {
    //     utils.storeJson('ErrorReports', global.ErrorReports);
    // } else {
    //     //move function here
    //     utils.storeJson('ErrorReports', []);
    // }

    fetch(
        getGlobals().AppReportingUrl +
            '/apps/error?client=' +
            encodeURIComponent(appContext.application.client) +
            '&deployment=' +
            encodeURIComponent(appContext.application.deployment) +
            '&service=' +
            encodeURIComponent(appContext.application.branding.service),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reporting),
        }
    ).catch((error) => {
        //send error report ??
    });
};
