import * as React from 'react';
import { isPlatformTvos } from '@rnv/renative';
import { Switch as Switch_ } from '@scriptx-com/xtv-toolkit';
import { Switch as TVOSSwitch } from 'react-native-switch';
import Pressable from '../pressable/pressable';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { colorShade } from '../../../styling/colorShade';
import { getPressableStyling } from '../helpers/helper';

export interface SwitchProps {
    onValueChange: any;
    styling: any;
    parentContext?: any;
    focusContext?: any;
    focusOptions?: any;
    value: boolean;
}
const FocusableSwitch = ({ onValueChange, styling, focusContext, parentContext, focusOptions, ...props }: SwitchProps) => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const [focused, setFocused] = React.useState(false);

    if (deviceContext.isPhone || deviceContext.isTablet) {
        return (
            <Switch_
                {...props}
                onKeyDown={(event) => {
                    if (event.key && event.key === 'Enter') {
                        onValueChange();
                    }
                }}
                onValueChange={onValueChange}
                trackColor={{
                    true: styling.selected_color,
                    false: styling.toggle_color,
                }}
                style={{ transform: [{ scaleX: deviceContext.isKaiOs ? 0.6 : 0.9 }, { scaleY: deviceContext.isKaiOs ? 0.6 : 0.9 }] }}
                thumbColor={'white'}
            />
        );
    } else {
        return (
            <Pressable
                focusContext={focusContext}
                focusOptions={focusOptions}
                animatorOptions={{
                    type: 'scale',
                    focus: {
                        scale: 1,
                    },
                }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onPress={() => onValueChange()}
                style={{
                    borderRadius: 100,
                }}
            >
                {isPlatformTvos ? (
                    <TVOSSwitch
                        {...props}
                        trackColor={{
                            true: styling.toggle_color,
                            false: styling.selected_color,
                        }}
                        thumbColor={'white'}
                        activeText=""
                        inActiveText=""
                        backgroundInactive={focused ? getPressableStyling(styling.components?.button._id, appContext).backgroundColor : styling.selected_color}
                        backgroundActive={focused ? getPressableStyling(styling.components?.button._id, appContext).backgroundColor: styling.toggle_color}
                    />
                ) : (
                    <Switch_
                        {...props}
                        //onValueChange={onValueChange}
                        trackColor={{
                            true: focused ? getPressableStyling(styling.components?.button._id, appContext).backgroundColor : styling.selected_color,
                            false: focused ? getPressableStyling(styling.components?.button._id, appContext).backgroundColor: styling.toggle_color,
                        }}
                        thumbColor={'white'}
                    />
                )}
            </Pressable>
        );
    }
};

export default FocusableSwitch;
