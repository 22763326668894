import { View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { ContentContext } from '../../../application/context/contentContext';
import { UserContext } from '../../../application/context/userContext';
import { AppContext } from '../../../application/context/appContext';
import { navigate } from '../../../application/hooks/useNotifications';

const Screen_ = ({ navigation, route, focusContext, showButton }): React.ReactElement => {
    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);

    React.useEffect(() => {
        switch (route.params.redirect) {
            case 'Movie':
                contentContext.setMovieData({
                    movie: {
                        _id: route.params._id,
                    },
                });
                break;
            case 'Seasons':
                contentContext.setSeriesData({
                    serie: {
                        _id: route.params._id,
                    },
                });
                break;
            case 'Course':
                contentContext.setCourseData({
                    course: {
                        _id: route.params._id,
                    },
                });
                break;
            case 'Game':
                contentContext.setGameData({
                    game: {
                        _id: route.params._id,
                    },
                });
                break;
            case 'Short':
                contentContext.setShortData({
                    short: {
                        _id: route.params._id,
                    },
                });
                break;
            default:
                break;
        }

        if (userContext.selectedLanguage == '') {
            navigation.navigate('Language', route.params);
        } else {
            if (appContext.application.settings?.enable_nested == true || userContext.orginalPackageId != '') {
                navigation.navigate('Services', route.params);
            } else {
                navigation.navigate('Login', route.params);
            }
        }
    }, [route.params]);

    return <View></View>;
};
export default Screen_;
