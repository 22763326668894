import { Image, Screen, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Pressable from '../../builder/components/pressable/pressable';
import { AppContext } from '../../context/appContext';
import { DeviceContext } from '../../context/deviceContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import LANG from '../../languages/languages';
import Stacks from '../../stacks/StackSelector';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getFontSize } from '../../styling/fontSizes';
import { getMenuIcon } from '../../styling/menuIcon';
import utils from '../../general/utils';
import Text from '../../builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../builder/components/helpers/helper';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { colorShade } from '../../styling/colorShade';
import { ContentContext } from '../../context/contentContext';
import { getNativeBackPress } from '../../../screens/wrapper/helpers/getNativeBackPress';
import { BackHandler, Keyboard, Platform } from 'react-native';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { CachingTaskView } from '../../builder/components/downloadbutton/downloadbutton';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from '../helpers/helpers';
import { actionType } from '../../../reporting/models/apps';
import { sendActionReport } from '../../../reporting/apis/action';
import { updateDevicesJson } from '../../data/account';
import { NetworkContext } from '../../context/networkContext';
import { getRealWidth } from '../../styling/realDeviceSizes';

const Tab = createBottomTabNavigator();

function MyTabBar({ state, descriptors, navigation }) {
    var menu = '';
    var styling = [] as any;
    var addTaskEvent = undefined;
    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const networkContext = React.useContext(NetworkContext);
    const [activeSettings, setActiveSettings] = useState('');
    const [visible, setVisible] = useState(true);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    global.menuWidth = 0;
    global.menuHeight = 50;
    const tabsToForward = ['Manual', 'Subscription', 'Messages', 'Support', 'About', 'Devices', 'Updates', 'Disclaimer', 'Channels', 'TV Guide', 'Radio', 'Recordings', 'Cams', 'Movies', 'Shorts', 'Series', 'Music', 'Podcasts', 'Courses'];

    React.useEffect(() => {
        let keyboardEventListeners = [];
        if (Platform.OS === 'android') {
            keyboardEventListeners = [Keyboard.addListener('keyboardDidShow', () => setVisible(false)), Keyboard.addListener('keyboardDidHide', () => setVisible(true))];
        }

        return () => {
            keyboardEventListeners.forEach((eventListener) => eventListener.remove());
        };
    }, []);

    React.useEffect(() => {
        const currentNavState = state?.routes?.[0]?.state?.routes?.[0]?.state;
        if (currentNavState) {
            const routeName = currentNavState.routes[currentNavState.index]?.name;
            if (routeName && contentContext.activeMenu !== routeName) {
                contentContext.setActiveMenu(routeName);
            }
        }
    }, [state.routes]);

    React.useEffect(() => {
        const backAction = () => {
            if (!getNativeBackPress()) {
                if (tabsToForward.includes(contentContext.activeMenu)) {
                    contentContext.setActiveMenu((deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads');
                    setActiveSettings('');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: (deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone ? 'Home' : 'Downloads' }],
                        })
                    );
                    return true;
                } else return false;
                return true;
            } else {
                return false;
            }
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

        settingsContext.setTabBarHeight(getCorrectHeight(deviceContext, 40));
        return backHandler.remove;
    }, [navigation, contentContext.activeMenu]);

    const onPress = (menu: any) => {
        if (menu != 'Account' && activeSettings == '') {
            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        }
    };

    // home, search, download, account
    var menuArray = [] as any;
    menuArray = [
        { name: 'home', menu: 'Home' },
        { name: 'search', menu: 'Search' },
    ];
    var test = appContext.application.menus.find((m) => m.name == 'Downloads');
    if (test != undefined) {
        menuArray.push({ name: 'downloads', menu: 'Downloads' });
    }
    menuArray.push({ name: 'account', menu: 'Account' });

    if (settingsContext.showMainMenu && visible) {
        return (
            <Screen
                style={{ flex: deviceContext.isKaiOs || deviceContext.isPwaVertical ? null : 0, height: getCorrectHeight(deviceContext, 40) }}
                focusOptions={{
                    stealFocus: false,
                    group: 'menu-bottom',
                    focusKey: 'menu-bottom',
                    nextFocusUp: ['content-screen', 'settings-screen'],
                }}
            >
                <View
                    style={{
                        flex: 1,
                        backgroundColor: styling.general?.background_color1,
                        width: menuArray.length > 4 ? '100%' : getRealWidth(deviceContext),
                        justifyContent: menuArray.length > 4 ? 'flex-start' : 'space-evenly',
                        flexDirection: 'row',
                        height: getCorrectHeight(deviceContext, 40),
                    }}
                >
                    {menuArray.map((route, index) => {
                        return (
                            <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => onPress(route.menu)}>
                                <>
                                    {downloadStatus == 'loading' && route.menu == 'Downloads' && (
                                        <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 15), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                                            <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                                        </View>
                                    )}
                                    {contentContext.activeMenu != route.menu && (
                                        <View
                                            style={{
                                                height: getCorrectHeight(deviceContext, 34),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: getCorrectWidth(deviceContext, (route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile' ? 45 : 65),
                                                margin: getCorrectWidth(deviceContext, 3),
                                                paddingTop: getCorrectWidth(deviceContext, (route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile' ? 0 : 3),
                                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                            }}
                                        >
                                            {((route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile') && (
                                                <Image
                                                    resizeMethod={'scale'}
                                                    resizeMode={'cover'}
                                                    source={{
                                                        uri: userContext.profile.avatar,
                                                    }}
                                                    style={{
                                                        width: getCorrectWidth(deviceContext, 35),
                                                        height: getCorrectWidth(deviceContext, 35),
                                                        borderRadius: 100,
                                                        // borderColor: appContext.application.theme.selected.navigators.border_bottom_color,
                                                        // borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 2) : getCorrectWidth(deviceContext, 1),
                                                    }}
                                                ></Image>
                                            )}
                                            {route.menu != 'Switch Profile' && route.menu == 'Account' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: appContext.application.theme.icons?.background }} icon={getMenuIcon(route.menu, userContext)} />
                                            )}

                                            {route.menu != 'Switch Profile' && route.menu != 'Account' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: appContext.application.theme.icons?.background }} icon={getMenuIcon(route.menu, userContext)} />
                                            )}

                                            {!deviceContext.isPwaVertical && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isTablet && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: 0,
                                                        paddingLeft: 10,
                                                        paddingRight: 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}

                                            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Search' && route.menu == 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                        paddingLeft: 10,
                                                        paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}
                                            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu == 'Search' && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                        paddingLeft: 10,
                                                        paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}
                                            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Search' && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                        // paddingLeft: 10,
                                                        // paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}
                                        </View>
                                    )}
                                    {contentContext.activeMenu == route.menu && (
                                        <View
                                            style={{
                                                height: getCorrectHeight(deviceContext, 34),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: getCorrectWidth(deviceContext, 65),
                                                margin: getCorrectWidth(deviceContext, 3),
                                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                                backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                            }}
                                        >
                                            {(route.menu == 'Account' || route.menu == 'Switch Profile') && (
                                                <Image
                                                    resizeMethod={'scale'}
                                                    resizeMode={'contain'}
                                                    source={{
                                                        uri: userContext.profile.avatar,
                                                    }}
                                                    style={{
                                                        width: getCorrectWidth(deviceContext, 35),
                                                        height: getCorrectWidth(deviceContext, 35),
                                                        //  borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                                                        borderRadius: 100,
                                                    }}
                                                ></Image>
                                            )}
                                            {route.menu != 'Account' && route.menu != 'Switch Profile' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: getSelectedStyling('Navigators', appContext).text_color }} icon={getMenuIcon(route.menu, userContext)} />
                                            )}
                                            {!deviceContext.isPwaVertical && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isTablet && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: 0,
                                                        //   paddingLeft: 10,
                                                        // paddingRight: 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        color: getSelectedStyling('Navigators', appContext).text_color,
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}
                                            {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                        //  paddingLeft: 10,
                                                        //  paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        color: getSelectedStyling('Navigators', appContext).text_color,
                                                    }}
                                                >
                                                    {LANG.getTranslation(userContext, route.name)}
                                                </Text>
                                            )}
                                        </View>
                                    )}
                                </>
                            </Pressable>
                        );
                    })}
                </View>
            </Screen>
        );
    }

    return null;
}
export default () => {
    return (
        <Tab.Navigator tabBar={(props) => <MyTabBar {...props} />}>
            <Tab.Screen name="AppStack" component={Stacks} />
        </Tab.Navigator>
    );
};
