import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator, BackHandler, Image } from 'react-native';
import { Animated, CoreManager, FocusContext, ScrollView, View, ViewProps } from '@scriptx-com/xtv-toolkit';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { AppContext } from '../../context/appContext';
import { ContentContext } from '../../context/contentContext';
import { DeviceContext } from '../../context/deviceContext';
import { UserContext } from '../../context/userContext';
import RELOAD from '../../data/reload';
import { getCurrentScreenName } from '../../hooks/getScreenName';
import lang from '../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getFontSize } from '../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../styling/realDeviceSizes';
import { getKeyboardStyling, getModalStyling } from '../components/helpers/helper';
import { InputProps, KeyboardWrapper } from '../components/keyboard/keyboard';
import Text from '../components/text';
import Content from './content';
import ContentTV from './content.virtualized';
import Header from './header';
import Hero from './hero';
import Overview from './overview';
import { isFactorMobile, isFactorTv } from '@rnv/renative';
import Programs from '../elements/tvguide/programs';
import dgram from 'react-native-udp';
import Modal from '../components/modal/modal';
import UdpSocket from 'react-native-udp/lib/types/UdpSocket.js';
import { NetworkContext } from '../../context/networkContext';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

const Screen_ = ({
    navigation,
    route,
    screen,
    data,
    type,
    focusContext,
    keyboardProps,
    contentFocusOptions,
}: {
    keyboardProps?: Partial<InputProps>;
    contentFocusOptions?: ViewProps['focusOptions'];
    focusContext?: FocusContext;
    [key: string]: any;
}): React.ReactElement => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const networkContext = React.useContext(NetworkContext);
    const insets = useSafeAreaInsets();
    const [showLoginRequest, setShowLoginRequest] = React.useState(false);
    const [socket, setSocket] = React.useState<UdpSocket>();
    const [incoming, setIncoming] = React.useState([] as any);

    var heroIsAvailable = false;
    var contentIsAvailabe = false;
    var detailsIsAvailable = false;
    var heroIsAnimated = false; //hier kijken voor details pages of er subcontent is ander animated aan
    var heroIsPlayer = false;
    var heroIsSingleType = false;
    var heroIsSliderType = false;

    if (screen != undefined) {
        if (screen.hero != undefined) {
            if (screen.hero[0] != undefined) {
                if (screen.hero[0].singles != undefined) {
                    heroIsAvailable = true;
                    heroIsAnimated = true;
                    heroIsSingleType = true;
                }
                if (screen.hero[0].sliders != undefined) {
                    heroIsAvailable = true;
                    heroIsAnimated = true;
                    heroIsSliderType = true;
                }
                if (screen.hero[0].players != undefined) {
                    heroIsAvailable = true;
                    heroIsAnimated = deviceContext.isTelevision ? false : true;
                    heroIsPlayer = deviceContext.isTelevision ? true : false;
                }

                if (screen.hero[0].details_bottom != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
                if (screen.hero[0].details_centered != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
                if (screen.hero[0].details_minimal != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
                if (screen.hero[0].details_standard != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
                if (screen.hero[0].details_audiostandard != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
                if (screen.hero[0].details_top != undefined) {
                    detailsIsAvailable = true;
                    if (screen.content != undefined) {
                        contentIsAvailabe = true;
                    }
                }
            }
        }
    }

    if (deviceContext.isPhone && type !== 'Home') {
        heroIsAnimated = false;
    }
    var screenAvailabe = false;
    if (screen != undefined) {
        screenAvailabe = true;
    }
    var contentScreensAvailable = false;
    if (screen != undefined && screen.content.length > 0 && screen.type != undefined) {
        contentScreensAvailable = true;
    }

    const screenHeight = deviceContext.isPhone ? (getRealWidth(deviceContext) / 16) * (getCurrentScreenName() == 'Home' ? 13 : 18) : deviceContext.isPwaVertical ? -80 : getRealHeight(deviceContext);
    const contentOffset =
        getCurrentScreenName() == 'Home'
            ? getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? -125 : 125)
            : getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : deviceContext.isWebTV ? 125 : 75);
    const BANNER_H = screenHeight - contentOffset;
    const scrollA = React.useRef(new Animated.Value(0)).current;

    const getCorrectPageType = () => {
        if (contentScreensAvailable) {
            switch (screen.type) {
                case 'Cams':
                    return pageType.CAMS;
                case 'Channels':
                    return pageType.CHANNELS;
                case 'Radio':
                    return pageType.RADIO;
                case 'TV Guide':
                    return pageType.TVGUIDE;
                case 'Program':
                    return pageType.TVGUIDE;
                case 'Recording':
                    return pageType.RECORDINGS;
                case 'Movies':
                    return pageType.MOVIES;
                case 'Shorts':
                    return pageType.SHORTS;
                case 'Movie':
                    return pageType.MOVIE_DETAILS;
                case 'Series':
                    return pageType.SERIES;
                case 'Season':
                    return pageType.SERIES_DETAILS;
                case 'Courses':
                    return pageType.COURSES;
                case 'Course':
                    return pageType.COURSE_DETAILS;
                case 'Podcasts':
                    return pageType.PODCASTS;
                case 'Podcast':
                    return pageType.PODCAST_DETAILS;
                case 'Albums':
                    return pageType.MUSIC;
                case 'Album':
                    return pageType.MUSIC_DETAILS;
                case 'Search':
                    return pageType.SEARCH;
                case 'Recordings':
                    return pageType.RECORDINGS;
                case 'Favorites':
                    return pageType.FAVORITES;
                case 'Watchlist':
                    return pageType.WATCHLIST;
                case 'Rentals':
                    return pageType.RENTALS;
                case 'Purchases':
                    return pageType.PURCHASES;
                case 'Downloads':
                    return pageType.DOWNLOADS;
                case 'Home':
                    return pageType.HOME;
                default:
                    return '';
            }
        }
    };
    const backAction = () => {
        const currentFocus = CoreManager.getCurrentFocus();
        if (currentFocus) {
            const currentGroup = currentFocus.getGroup();
            if (currentGroup === `menu-drawer` && appContext.isMenuDrawerOpen.current) {
                CoreManager.executeDirectionalFocus('right');
                return true;
            }
        }

        if (
            screen?.type == 'Channels' ||
            screen?.type == 'Radio' ||
            screen?.type == 'Recordings' ||
            screen?.type == 'Cams' ||
            screen?.type == 'Movies' ||
            screen?.type == 'Shorts' ||
            screen?.type == 'Series' ||
            screen?.type == 'Music' ||
            screen?.type == 'Podcasts' ||
            screen?.type == 'Education' ||
            screen?.type == 'Favorites' ||
            screen?.type == 'Rentals' ||
            screen?.type == 'Purchases' ||
            screen?.type == 'Downloads' ||
            screen?.type == 'Watchlist' ||
            screen?.type == 'Overview' ||
            screen?.type == 'Courses'
        ) {
            if ((deviceContext.isPhone && networkContext.connected) || !deviceContext.isPhone) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'Home' }],
                    })
                );
                contentContext.setActiveMenu('Home');
            } else {
                if (screen.type !== 'Downloads')
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Downloads' }],
                        })
                    );
                contentContext.setActiveMenu('Downloads');
            }
        } else {
            if (screen?.type !== 'Search') if (navigation.canGoBack()) navigation.goBack();
        }

        return true;
    };
    React.useEffect(() => {
        const backHandlerPage = BackHandler.addEventListener('hardwareBackPress', backAction);
        return backHandlerPage.remove;
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            const TVBackHandler = (event) => {
                const { key, keyCode } = event;
                if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                    backAction();
                }
            };

            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.addEventListener('keydown', TVBackHandler);
            }
            return () => {
                if (deviceContext.isTizen || deviceContext.isWebos) {
                    {
                        window.removeEventListener('keydown', TVBackHandler);
                    }
                }
            };
        }, [])
    );

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, getCorrectPageType(), duration);
                socket && socket.close();
            };
        }, [])
    );

    const getCorrectPaddingBottom = () => {
        if (deviceContext.isAndroid) {
            if (heroIsAvailable) {
                return deviceContext.isPhone ? 0 : 10;
            } else {
                return 0;
            }
        } else if (deviceContext.isApple) {
            if (heroIsAvailable) {
                return deviceContext.isPhone ? 100 : 10;
            } else {
                return 100;
            }
        } else if (deviceContext.isWebTV) {
            if (heroIsAvailable) {
                return 80;
            } else {
                return 40;
            }
        } else {
            return 0;
        }
    };

    const [isLoading, seIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (deviceContext.isPhone || deviceContext.isTablet) {
            checkForLoginRequest();
        }
        const timeout = setTimeout(() => {
            seIsLoading(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
            socket && socket.close();
        };
    }, []);

    //login via network
    const checkForLoginRequest = () => {
        const client = dgram.createSocket({ type: 'udp4', reusePort: true });
        client.bind(8887);
        setSocket(client);
        client.on('message', (data, rinfo) => {
            var incoming = JSON.parse(data);
            if (incoming.type == 'authentication') {
                setIncoming(incoming);
                setShowLoginRequest(true);
            }
        });
        client.on('listening', (data) => {});
    };
    const validateRequest = () => {
        const client = dgram.createSocket({ type: 'udp4', debug: true, reusePort: true });
        client.bind(8887);
        setSocket(client);
        if (incoming != undefined && incoming.type == 'authentication') {
            client.send(
                JSON.stringify({
                    type: 'login',
                    userid: userContext.userId,
                    pass: userContext.pass,
                    appid: userContext.appId,
                }),
                undefined,
                undefined,
                8887,
                incoming.ipaddress,
                (error) => {}
            );
        }
        setShowLoginRequest(false);
    };
    const cancelRequest = () => {
        setShowLoginRequest(false);
    };

    const checkIfHeaderHasLogoWide = () => {
        var test = appContext.application?.screens[0]?.header[0];
        if (test?.logowide) {
            return true;
        } else {
            return false;
        }
    };

    let Wrapper =
        (type === 'Home' && !heroIsPlayer) ||
        type === 'Seasons' ||
        type === 'Movie' ||
        type === 'Course' ||
        type === 'Search' ||
        // type === 'Albums' ||
        type === 'Album' ||
        // type === 'Podcasts' ||
        type === 'Podcast' ||
        type === 'Short' ||
        type === 'Favorites' ||
        // type === 'Recordings' ||
        type === 'Watchlist' ||
        type === 'Rentals' ||
        type === 'Purchases' ||
        type === 'Downloads' ||
        type === 'Recording' ||
        type === 'Program'
            ? ScrollView
            : View;

    if (isFactorTv && type === 'Home') {
        Wrapper = View;
    }

    const Offline = () => {
        return (
            <View
                style={{
                    padding: getCorrectWidth(deviceContext, 20),
                    flexDirection: 'row',
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                    backgroundColor: appContext.application.theme.settings.background_color2,
                    borderRadius: appContext.application.theme.settings.border_radius,
                    margin: getCorrectWidth(deviceContext, 10),
                    justifyContent: 'space-between',
                    alignSelf: deviceContext.isTablet ? 'flex-end' : 'center',
                }}
            >
                <View style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faTriangleExclamation} size={25} color={appContext.application.theme.settings.font_color} />
                </View>

                <View style={{ flexDirection: 'column', width: '80%' }}>
                    <Text
                        style={{
                            color: appContext.application.theme.settings.font_color,
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                        }}
                    >
                        {lang.getTranslation(userContext, 'app_offline_modus')}
                    </Text>
                </View>
            </View>
        );
    };

    return (
        <>
            <Wrapper
                focusContext={focusContext}
                style={{
                    flex: 1,
                    ...(type !== 'Home' &&
                        type !== 'Programs' &&
                        type !== 'Overview' && {
                            flexGrow: 1,
                        }),
                }}
                contentContainerStyle={{
                    ...((type === 'Favorites' || type === 'Watchlist') &&
                        !deviceContext.isPhone && {
                            paddingLeft: 50,
                        }),
                    //add istablet due to details page having white space in the bottom
                    paddingBottom: deviceContext.isTablet ? getCorrectPaddingBottom() : insets.bottom + getCorrectPaddingBottom(),
                    paddingTop: ['Channels', 'Radio', 'Recordings', 'Cams', 'Series', 'Albums', 'Podcasts', 'Courses', 'Shorts', 'Favorites', 'Rentals', 'Purchases', 'Downloads', 'Watchlist'].includes(type) ? insets.top : isLoading ? 0 : 0,
                }}
            >
                {showLoginRequest && (
                    <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'device_login_request'} submitChoice={() => validateRequest()} submitChoice2={cancelRequest}></Modal>
                )}
                <RELOAD navigation={navigation}></RELOAD>
                {/* this is home */}
                {screenAvailabe && heroIsAvailable && contentScreensAvailable && heroIsAnimated && !heroIsPlayer && (
                    <View style={{ flex: 1 }}>
                        {isFactorTv ? (
                            <View style={{ flex: 1 }}>
                                {!isLoading && (
                                    <ContentTV
                                        focusContext={focusContext}
                                        navigation={navigation}
                                        elements={screen.content}
                                        heroIsPlayer={heroIsPlayer}
                                        contentScreensAvailable={contentScreensAvailable}
                                        hero={screen.hero}
                                        heroIsSliderType={heroIsSliderType}
                                        heroIsSingleType={heroIsSingleType}
                                    />
                                )}
                            </View>
                        ) : (
                            <>
                                {checkIfHeaderHasLogoWide() && heroIsSliderType && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: deviceContext.isApple ? 50 : 0,
                                            zIndex: 100,
                                            marginLeft: getCorrectWidth(deviceContext, global.menuWidth),
                                        }}
                                    >
                                        <Header focusContext={focusContext} elements={screen.header} scrollA={scrollA} navigation={navigation}></Header>
                                    </View>
                                )}
                                {!checkIfHeaderHasLogoWide() && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            zIndex: 100,
                                            marginLeft: getCorrectWidth(deviceContext, global.menuWidth),
                                        }}
                                    >
                                        <Header focusContext={focusContext} elements={screen.header} scrollA={scrollA} navigation={navigation}></Header>
                                    </View>
                                )}
                                <ScrollView>
                                    {checkIfHeaderHasLogoWide() && heroIsSingleType && (
                                        <View
                                            style={{
                                                marginLeft: getCorrectWidth(deviceContext, global.menuWidth),
                                            }}
                                        >
                                            <Header focusContext={focusContext} elements={screen.header} scrollA={scrollA} navigation={navigation}></Header>
                                        </View>
                                    )}
                                    {(deviceContext.isPhone || deviceContext.isPwaVertical) && (
                                        <View style={{ position: 'absolute', zIndex: 1, top: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, heroIsSingleType ? 30 : 110) }}>
                                            <Image
                                                source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                                resizeMethod={'scale'}
                                                resizeMode={'cover'}
                                                style={{
                                                    opacity: 1,
                                                    height: getRealHeight(deviceContext),
                                                    width: getRealWidth(deviceContext),
                                                }}
                                            ></Image>
                                        </View>
                                    )}
                                    {!deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                        <Image
                                            source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                            resizeMethod={'scale'}
                                            resizeMode={'stretch'}
                                            style={{
                                                top: getRealHeight(deviceContext) + (heroIsSingleType ? 10 : 0),
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                position: 'absolute',
                                                zIndex: -1,
                                                opacity: heroIsSingleType ? 0.5 : 1,
                                            }}
                                        ></Image>
                                    )}

                                    <View
                                        style={{
                                            zIndex: 4,
                                            flexGrow: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                                    </View>
                                    {!isLoading && (
                                        <View
                                            focusContext={focusContext}
                                            style={{
                                                zIndex: 5,
                                                marginTop: getCorrectHeight(deviceContext, heroIsSliderType && !deviceContext.isPhone ? -100 : 5),
                                                marginLeft: getCorrectWidth(deviceContext, global.menuWidth),
                                            }}
                                        >
                                            <Content focusContext={focusContext} navigation={navigation} elements={screen.content} heroIsPlayer={heroIsPlayer} focusOptions={contentFocusOptions}></Content>
                                        </View>
                                    )}
                                </ScrollView>
                            </>
                        )}
                    </View>
                )}
                {/* this is home */}
                {screenAvailabe && heroIsAvailable && contentScreensAvailable && !heroIsAnimated && heroIsPlayer && (
                    <View>
                        <View
                            style={{
                                position: 'absolute',
                                top: 0,
                                zIndex: 100,
                            }}
                        >
                            <Header focusContext={focusContext} elements={screen.header} scrollA={scrollA} navigation={navigation}></Header>
                        </View>
                        <View>
                            <Image
                                source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                resizeMethod={'scale'}
                                resizeMode={'stretch'}
                                style={{
                                    top: getRealHeight(deviceContext) / 2,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                }}
                            ></Image>
                            <View
                                style={{
                                    marginTop: -getRealHeight(deviceContext),
                                    paddingTop: getRealHeight(deviceContext),
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                }}
                            >
                                <View
                                    style={{
                                        height: getRealHeight(deviceContext) / 2,
                                    }}
                                >
                                    <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                                </View>
                            </View>

                            <ScrollView focusContext={focusContext} style={{}}>
                                <Content heroIsPlayer={heroIsPlayer} focusContext={focusContext} navigation={navigation} elements={screen.content} focusOptions={contentFocusOptions}></Content>
                            </ScrollView>
                        </View>
                    </View>
                )}

                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && detailsIsAvailable && contentScreensAvailable && !deviceContext.isPhone && (
                    <View style={{ flex: 1 }}>
                        <View style={{ zIndex: 2 }}>
                            <View style={{ zIndex: 2 }}>
                                <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                                <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                            </View>
                            <Image
                                source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                resizeMode={'stretch'}
                                style={{
                                    minHeight: getRealHeight(deviceContext),
                                    width: getRealWidth(deviceContext),
                                    position: 'absolute',
                                    zIndex: 0,
                                    top: getRealHeight(deviceContext),
                                    flexGrow: 1,
                                }}
                            ></Image>

                            <View style={{ zIndex: 2 }}>
                                <Content focusContext={focusContext} navigation={navigation} elements={screen.content} heroIsPlayer={heroIsPlayer} focusOptions={contentFocusOptions}></Content>
                            </View>
                        </View>
                    </View>
                )}
                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && detailsIsAvailable && contentScreensAvailable && deviceContext.isPhone && (
                    <View style={{ flexGrow: 1, paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                        <View style={{ flexGrow: 1, zIndex: 2 }}>
                            <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                            <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                            <Image
                                source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                resizeMethod={'scale'}
                                resizeMode={'stretch'}
                                style={{
                                    top: getRealHeight(deviceContext),
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                }}
                            ></Image>
                        </View>

                        <View style={{ flexGrow: 1, zIndex: 2 }}>
                            <View
                                style={{
                                    zIndex: 2,
                                    paddingBottom: getCorrectHeight(deviceContext, 30),
                                }}
                            >
                                <Content focusContext={focusContext} navigation={navigation} elements={screen.content} heroIsPlayer={heroIsPlayer} focusOptions={contentFocusOptions}></Content>
                            </View>
                        </View>
                    </View>
                )}
                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && detailsIsAvailable && !contentScreensAvailable && (
                    <View style={{ flex: 1 }}>
                        <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                        <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                        {deviceContext.isPhone && (
                            <Image
                                source={require('../../../assets/images/gradient-home-top-bottom.png')}
                                resizeMethod={'scale'}
                                resizeMode={'stretch'}
                                style={{
                                    top: getRealHeight(deviceContext) / 2,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                }}
                            ></Image>
                        )}
                    </View>
                )}

                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && heroIsAvailable && contentScreensAvailable && !heroIsAnimated && !heroIsPlayer && !detailsIsAvailable && (
                    <View style={{ flex: 1 }}>
                        <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                        <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                        <Content focusContext={focusContext} navigation={navigation} elements={screen.content} heroIsPlayer={heroIsPlayer} focusOptions={contentFocusOptions}></Content>
                    </View>
                )}
                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && heroIsAvailable && !contentScreensAvailable && !heroIsAnimated && !detailsIsAvailable && !heroIsPlayer && (
                    <View style={{ flex: 1 }}>
                        <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                        <Hero focusContext={focusContext} navigation={navigation} elements={screen.hero} contentScreensAvailable={contentScreensAvailable}></Hero>
                    </View>
                )}
                {/* this is subpage */}
                {type != 'Overview' && type != 'Programs' && screenAvailabe && !heroIsAvailable && contentScreensAvailable && !detailsIsAvailable && !heroIsPlayer && (
                    <View style={{ flex: 1 }}>
                        {deviceContext.isPhone && !networkContext.connected && screen?.type === 'Downloads' && <Offline />}
                        <Header focusContext={focusContext} elements={screen.header} scrollA={0} navigation={navigation}></Header>
                        <Content focusContext={focusContext} navigation={navigation} elements={screen.content} heroIsPlayer={heroIsPlayer} focusOptions={contentFocusOptions}></Content>
                    </View>
                )}
                {/* pages with no page in app builder*/}

                {/* this is no screen available */}
                {!screenAvailabe && type != 'Overview' && type != 'Programs' && (
                    <View
                        style={{
                            width: getRealWidth(deviceContext) - 10,
                            backgroundColor: '#000',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-end',
                            padding: getCorrectHeight(deviceContext, 20),
                            margin: 5,
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Large'),
                            }}
                        >
                            {lang.getTranslation(userContext, 'no_screem_available')}
                        </Text>
                    </View>
                )}
                {type == 'Programs' && (
                    <View style={{ flex: 1 }}>
                        <Programs route={route} focusContext={focusContext} navigation={navigation}></Programs>
                    </View>
                )}
                {type == 'Overview' && (
                    <View style={{ flex: 1 }}>
                        <Overview route={route} focusContext={focusContext} navigation={navigation}></Overview>
                    </View>
                )}
            </Wrapper>
            {isLoading && type == 'Home' && (
                <View style={{ backgroundColor: 'black', position: 'absolute', width: getRealWidth(deviceContext), height: getRealHeight(deviceContext), justifyContent: 'center' }}>
                    <ActivityIndicator size={'large'} color={'#ffffff'}></ActivityIndicator>
                </View>
            )}
            <View style={{ marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                <KeyboardWrapper width={getRealWidth(deviceContext) * 0.8} styling={getKeyboardStyling(appContext.application.theme.keyboard.filter, appContext)} {...keyboardProps} />
            </View>
        </>
    );
};

export default Screen_;
