import * as React from 'react';
import { View, ActivityIndicator, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { Details_Top as styling } from '../../../models/elements/details/top/top';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { TextFade } from '../../animations/textFade';
import { setRecommendations, watchingStatus } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { ContentContext } from '../../../context/contentContext';
import { UserContext } from '../../../context/userContext';
import Modal from '../../components/modal/modal';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Parental from '../../components/parental/parental';
import Image from '../../components/image/image';
import { getModalStyling } from '../../components/helpers/helper';
import { ActorsRowText, BackgroundImage, ButtonRow, MetaRow, StatusTimeRow, DescriptionRow, ProgressRow, LogoRow, ActorsRowImages, EpisodeRails, LessonRails } from './helpers/helpers_landscape';
import {
    onPressContinue,
    onPressFavorite,
    onPressPlay,
    onPressPlayCatchupTV,
    onPressPlayCourse,
    onPressPlayRecording,
    onPressPlaySeason,
    onPressRecording,
    onPressRecordingDelete,
    onPressRemind,
    onPressReplay,
    onPressShare,
    onPressTrailer,
    onPressWatchlist,
    playEpisode,
    playLesson,
    recordProgram,
    remindProgram,
} from './helpers/functions';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { SettingsContext } from '../../../context/settingsContext';
import moment from 'moment';
import FocusHelper from '../../../focushelper/FocusHelper';
import PayPerView from '../../components/payperview/payperview';

const TextFadeWithContext = withParentContextMapper(TextFade);

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
    contentScreensAvailable: boolean;
    isFromSocialSharing?: boolean;
    socialScreenName?: string;
}

export const Details_Top = ({ styling, navigation, focusContext, contentScreensAvailable, isFromSocialSharing, socialScreenName }: Props) => {
    const [data, setData] = React.useState([] as any);

    const [showRecording, setShowRecording] = React.useState(false);
    const [showRecordingSuccess, setShowRecordingSuccess] = React.useState(false);
    const [showRecordingError, setShowRecordingError] = React.useState(false);
    const [showRecordingWaiting, setShowRecordingWaiting] = React.useState(false);
    const [showParentalRecording, setShowParentalRecording] = React.useState(false);
    const [showRecordingRemoved, setShowRecordingRemoved] = React.useState(false);
    const [showPayPerView, setShowPayPerView] = React.useState(false);
    const [error, setError] = React.useState('');

    const [showReminder, setShowReminder] = React.useState(false);
    const [showReminderSuccess, setShowReminderSuccess] = React.useState(false);
    const [showReminderRemoved, setShowReminderRemoved] = React.useState(false);
    const [showParentalReminder, setShowParentalReminder] = React.useState(false);

    const screenName = socialScreenName != undefined ? socialScreenName :  getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const settingsContext = React.useContext(SettingsContext);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);
    const [updateScreenPayPerView, setUpdatePayPerView] = React.useState(0);

    const getData = async () => {
        var path = '';
        if (screenName == 'Short') {
            setData(contentContext.shortData.short);
        } else {
            if (screenName == 'Movie') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/details/' + contentContext.movieData.movie._id + '_details.json';
            }
            if (screenName == 'Seasons') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/details/' + contentContext.seriesData.series._id + '_details.json';
            }
            if (screenName == 'Course') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/details/' + contentContext.courseData.course._id + '_details.json';
            }
            if (screenName == 'Program') {
                const date = moment().add(contentContext.epgOffSetDay, 'day').format('DD') + '-' + Number(moment().add(contentContext.epgOffSetDay, 'day').month() + 1) + '-' + moment().add(contentContext.epgOffSetDay, 'day').year();
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/details/' + contentContext.channelData.channel._id + '_' + date + '_details.json';
            }
            if (screenName == 'Recording') {
                const date = moment.unix(contentContext.recordingData.program.s).format('DD') + '-' + Number(moment.unix(contentContext.recordingData.program.s).month() + 1) + '-' + moment.unix(contentContext.recordingData.program.s).year();
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/details/' + contentContext.recordingData.channel._id + '_' + date + '_details.json';
            }
            try {
                let response = await fetch(path);

                let data = await response.json();
                if (data != undefined) {
                    if (screenName == 'Seasons') {
                        contentContext.setSeriesData({
                            ...contentContext.seriesData,
                            series: data,
                            seasons: data.seasons,
                            season: data.seasons[0],
                            seasonCategoryIndex: 0,
                        });
                        setRecommendations(contentContext.seriesData.series.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Course') {
                        contentContext.setCourseData({
                            ...contentContext.courseData,
                            course: data,
                        });
                        setRecommendations(data.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Movie') {
                        contentContext.setMovieData({
                            ...contentContext.movieData,
                            movie: data,
                        });
                        setRecommendations(data.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Program') {
                        var program = data.find((d) => d.i == contentContext.channelData.program.i);
                        contentContext.setRecordingData({
                            recordingIndex: 0,
                            program: program,
                            channel: contentContext.channelData.channel,
                        });
                        if (program != undefined) {
                            data = { program: program };
                        } else {
                            data = { program: contentContext.channelData.program };
                        }
                    }
                    if (screenName == 'Recording') {
                        var program = data.find((e) => e.i == contentContext.recordingData.program.i);
                        data = { program: program };
                    }
                    setData(data);
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
        if (socialScreenName != undefined) {
            getData();
        }
    }, [navigation]);

    React.useEffect(() => {
        return () => {
            FocusHelper.cfEpisodeIndex = undefined;
            FocusHelper.cfSeasonIndex = undefined;
        };
    }, []);

    const onSubmitRecording = async () => {
        setShowRecording(false);
        setShowRecordingWaiting(true);
        var result = (await recordProgram(userContext, appContext, data.program, contentContext.channelData.channel)) as any;
        if (result?.success == false) {
            setError(result.reason);
            setShowRecordingWaiting(false);
            setShowRecordingError(true);
        } else {
            setShowRecordingWaiting(false);
            setShowRecordingSuccess(true);
        }
    };
    const onSubmitReminder = () => {
        setShowReminder(false);
        remindProgram(userContext, appContext, data.program, contentContext.channelData.channel);
        setShowReminderSuccess(true);
    };

    let episodeIndex = 0;
    let seasonIndex = 0;

    if (screenName == 'Seasons') {
        const watchlist = watchingStatus('Seasons', data._id, userContext);
        if (watchlist != undefined) {
            episodeIndex = watchlist.continue.episode_index ?? 0;
            seasonIndex = watchlist.continue.season_index ?? 0;
        }
    }


    return (
        <View style={{ flexGrow: 1, minHeight: getRealHeight(deviceContext), overflow: 'hidden' }} focusContext={focusContext}>
            {showPayPerView && (
                <PayPerView
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.payperview, appContext)}
                    stylingContent={styling}
                    setShowModal={() => setShowPayPerView(false)}
                    type={screenName}
                    data={screenName == 'Program' ? contentContext.channelData.channel : data}
                    startContent={() => {
                        setUpdatePayPerView(moment().unix());
                        setShowPayPerView(false);
                    }}
                ></PayPerView>
            )}
            {showParentalRecording && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={() => {
                        setShowRecording(true);
                        setShowParentalRecording(false);
                    }}
                    setShowParental={setShowParentalRecording}
                    type={'access_content'}
                ></Parental>
            )}
            {showParentalReminder && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={() => {
                        setShowReminder(true);
                        setShowParentalReminder(false);
                    }}
                    setShowParental={setShowParentalRecording}
                    type={'access_content'}
                ></Parental>
            )}
            {showRecordingSuccess && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording_success'}
                    submitChoice={null}
                    setShowModal={() => setShowRecordingSuccess(false)}
                ></Modal>
            )}
            {showRecordingError && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording_error'}
                    error={error}
                    submitChoice={null}
                    setShowModal={() => setShowRecordingError(false)}
                ></Modal>
            )}
            {showRecordingWaiting && <Modal program={data.program} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.recording, appContext)} type={'recording_waiting'} submitChoice={null}></Modal>}
            {showRecording && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording'}
                    submitChoice={onSubmitRecording}
                    setShowModal={() => setShowRecording(false)}
                ></Modal>
            )}
            {showReminder && (
                <Modal program={data.program} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)} type={'reminder'} setShowModal={setShowReminder} submitChoice={onSubmitReminder}></Modal>
            )}
            {showReminderSuccess && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'reminder_success'}
                    setShowModal={setShowReminderSuccess}
                    submitChoice={() => setShowReminderSuccess(false)}
                ></Modal>
            )}
            {showReminderRemoved && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'reminder_removed'}
                    setShowModal={setShowReminderRemoved}
                    submitChoice={() => setShowReminderRemoved(false)}
                ></Modal>
            )}
            {showRecordingRemoved && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'recording_removed'}
                    setShowModal={setShowRecordingRemoved}
                    submitChoice={() => setShowRecordingRemoved(false)}
                ></Modal>
            )}

            <View focusContext={focusContext} style={{ flexGrow: 1 }}>
                {data != null && data?.length == 0 && (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth),
                            height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : 100),
                        }}
                    >
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                        </View>
                    </View>
                )}
                {data != null && data.length != 0 && (
                    <View style={{ flexGrow: 1 }}>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: '100%',
                                zIndex: 1,
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{ height: deviceContext.isWebTV ? '100vh' : getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}
                            ></Image>
                        </View>

                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: '100%',
                                zIndex: 1,
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-home-2.png')}
                                resizeMode={'cover'}
                                style={{ height: deviceContext.isWebTV ? '100vh' : getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}
                            ></Image>
                        </View>

                        {(screenName == 'Seasons' || screenName == 'Course') && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: getRealHeight(deviceContext),
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext),
                                    zIndex: 2,
                                    transform: [
                                        { rotateY: '180deg' }, //vertically
                                        { scaleY: -1 }, //vertically
                                    ],
                                }}
                            >
                                <Image
                                    isLocal={true}
                                    deviceContext={deviceContext}
                                    source={require('../../../../assets/images/gradient-home-2.png')}
                                    resizeMode={'cover'}
                                    style={{
                                        height: deviceContext.isWebTV ? '100%' : '100%',
                                        width: getRealWidth(deviceContext),
                                    }}
                                ></Image>
                            </View>
                        )}
                        {(screenName == 'Seasons' || screenName == 'Course') && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: getRealHeight(deviceContext),
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext),
                                    zIndex: 2,
                                    transform: [
                                        { rotateY: '180deg' }, //vertically
                                        { scaleY: -1 }, //vertically
                                    ],
                                }}
                            >
                                <Image
                                    isLocal={true}
                                    deviceContext={deviceContext}
                                    source={require('../../../../assets/images/gradient-home-2.png')}
                                    resizeMode={'cover'}
                                    style={{
                                        height: deviceContext.isWebTV ? '100%' : '100%',
                                        width: getRealWidth(deviceContext),
                                    }}
                                ></Image>
                            </View>
                        )}

                        <View style={{ zIndex: 0 }}>
                            <BackgroundImage program={undefined} channel={undefined} item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>
                        </View>
                        <View
                            style={{
                                zIndex: 3,
                                flex: 1,
                                width: getRealWidth(deviceContext),
                                paddingTop: getCorrectHeight(deviceContext, 40),
                                paddingLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                                marginBottom: getCorrectHeight(deviceContext, 40),
                                justifyContent: 'center',
                            }}
                        >
                            <TextFadeWithContext>
                                <LogoRow channel={undefined} item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                                <StatusTimeRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></StatusTimeRow>
                                <MetaRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></MetaRow>
                                <DescriptionRow item={data} type={screenName} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                                <ActorsRowText type={screenName} item={data} styling={styling} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowText>
                                {isFromSocialSharing == undefined && (
                                    <ProgressRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></ProgressRow>
                                )}
                            </TextFadeWithContext>
                            <View>
                                {isFromSocialSharing == undefined && (
                                    <ButtonRow
                                        focusContext={focusContext}
                                        item={data}
                                        type={screenName}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        contentContext={contentContext}
                                        styling={styling}
                                        onPressShare={() => onPressShare(data, appContext, screenName)} 
                                        onPressRecordingDelete={(program) => onPressRecordingDelete(userContext, appContext, data.program, navigation)}
                                        onPressBack={() => navigation.goBack()}
                                        onPressPlay={(item) => onPressPlay(screenName, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                                        onPressPlayRecording={(item) => onPressPlayRecording(navigation, settingsContext)}
                                        onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                                        onPressPlayLesson={(lessonIndex) => onPressPlayCourse(contentContext, data, navigation, lessonIndex, settingsContext)}
                                        onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                                        onPressTrailer={(item) => onPressTrailer(screenName, navigation, item, settingsContext)}
                                        onPressReplay={() => onPressReplay(screenName, navigation, settingsContext)}
                                        onPressPurchase={() => setShowPayPerView(true)}
                                        onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, screenName, appContext, deviceContext, setUpdateScreenFavorite, item)}
                                        onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) => onPressWatchlist(isWatchlist, userContext, screenName, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)}
                                        onPressContinue={(item) => onPressContinue(screenName, navigation, item, settingsContext)}
                                        onPressRecording={(isRecorded) => onPressRecording(isRecorded, userContext, appContext, data.program, setShowRecording, setShowRecordingRemoved)}
                                        onPressRemind={(isReminded) => onPressRemind(isReminded, data.program, appContext, userContext, setShowReminder, setShowReminderRemoved)}
                                    ></ButtonRow>
                                )}
                            </View>
                            <View>
                                <ActorsRowImages focusContext={focusContext} type={screenName} item={data} styling={styling} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowImages>
                            </View>

                            {isFromSocialSharing == undefined && screenName == 'Seasons' && data.seasons[seasonIndex]?.episodes != undefined && data.seasons[seasonIndex]?.episodes.length > 0 && (
                                <View style={{ zIndex: 2, flex: 1 }}>
                                    <View style={{ flex: 1, flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 40) }} focusContext={focusContext}>
                                        <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 10), marginLeft: -getCorrectWidth(deviceContext, 2) }}>
                                            {data.seasons.map((season, _seasonIndex) => (
                                                <EpisodeRails
                                                    screenName={screenName}
                                                    playEpisode={(item, seasonIndex, episodeIndex) => {
                                                        FocusHelper.cfSeasonIndex = seasonIndex;
                                                        FocusHelper.cfEpisodeIndex = episodeIndex;
                                                        playEpisode(contentContext, item, navigation, seasonIndex, episodeIndex, settingsContext);
                                                    }}
                                                    focusContext={focusContext}
                                                    season={season}
                                                    watchingSeasonIndex={seasonIndex}
                                                    seasonIndex={_seasonIndex}
                                                    styling={styling}
                                                    deviceContext={deviceContext}
                                                    userContext={userContext}
                                                    appContext={appContext}
                                                    contentContext={contentContext}
                                                ></EpisodeRails>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                            {isFromSocialSharing == undefined && screenName == 'Course' && (
                                <View style={{ zIndex: 2, flex: 1 }}>
                                    <LessonRails
                                        screenName={screenName}
                                        playLesson={(item, lessonIndex) => {
                                            FocusHelper.cfEpisodeIndex = lessonIndex;
                                            playLesson(contentContext, item, navigation, lessonIndex, settingsContext);
                                        }}
                                        focusContext={focusContext}
                                        course={data}
                                        styling={styling}
                                        deviceContext={deviceContext}
                                        userContext={userContext}
                                        appContext={appContext}
                                        contentContext={contentContext}
                                    ></LessonRails>
                                </View>
                            )}
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
};

export default Details_Top;
