import { Webview as styling } from '../../../models/elements/misc/webview/webview';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid } from '@rnv/renative';
import { View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React, { useEffect } from 'react';
import { BackHandler, Dimensions, View as RNView } from 'react-native';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../../reporting/apis/page';
import { pageType } from '../../../../reporting/models/apps';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { WebView } from 'react-native-webview';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth, getRealHeight } from '../../../styling/realDeviceSizes';
import Pressable from '../../components/pressable/pressable';
import { getFocusStyling } from '../../components/helpers/helper';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

export interface Props {
    navigation: any;
    styling: styling;
    focusContext?: any;
}

export const WebViews = ({ styling, focusContext, navigation }: Props) => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);
    const insets = useSafeAreaInsets();
    const wrapperRef = React.useRef<RNView>(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const isLandscape = deviceContext.isPhone || deviceContext.isPwaVertical;
    const [urlAndParams, setUrlAndParams] = React.useState('');

    useEffect(() => {
        setIsLoaded(false);
        setUrlAndParams(styling.url + '?language=' + userContext.iso2);
        setIsLoaded(true);
    }, [navigation]);

    const onBack = () => {
        setIsLoaded(false);
        setTimeout(() => {
            navigation.navigate('Home');
        }, 100);
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GAMES_WEBVIEW, duration);
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        onBack();
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    const insetFromBottom = isLandscape ? insets.bottom : 0;
    const styles = isLandscape
        ? {
              width: isPlatformAndroid ? '100%' : Dimensions.get('screen').width - insetFromBottom,
              height: deviceContext.isIos ? Dimensions.get('window').height - insetFromBottom : '100%',
              paddingLeft: getCorrectWidth(deviceContext, global.menuWidth), //done cause android this not have full height on android phone
          }
        : ({
              justifyContent: 'center',
              alignItems: 'center',
          } as any);

    return (
        <View ref={wrapperRef} style={styles} focusContext={focusContext}>
            {(deviceContext.isAndroid || deviceContext.isIos) && deviceContext.isPhone && (
                <>
                    {isLoaded && urlAndParams != '' && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext),
                       flex:1,
                                    backgroundColor: '#000000',
                                },
                            ]}
                        >
                            <WebView
                                source={{ uri: urlAndParams }}
                                allowsInlineMediaPlayback
                                ignoreSilentHardwareSwitch
                                startInLoadingState
                                allowsFullscreenVideo
                                autoManageStatusBarEnabled={false}
                    
                                textZoom={100}
                                contentMode={'mobile'}
                                scalesPageToFit={true}
                                style={{
                                    border: 'none',
                                    backgroundColor: '#000000',
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            />
                        </View>
                    )}
                </>
            )}
            {(deviceContext.isAndroid || deviceContext.isIos) && deviceContext.isTablet && (
                <>
                    {isLoaded && urlAndParams != '' && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext),
                                    backgroundColor: '#000000',
                                },
                            ]}
                        >
                            <WebView
                                source={{ uri: urlAndParams }}
                              
                                allowsInlineMediaPlayback
                                ignoreSilentHardwareSwitch
                                startInLoadingState
                                allowsFullscreenVideo
                                autoManageStatusBarEnabled={false}
   
                                textZoom={100}
                                style={{
                                    border: 'none',
                                    backgroundColor: '#000000',
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            />
                        </View>
                    )}
                </>
            )}
            {deviceContext.isWebTV && (
                <>
                    <View style={{ width: getRealWidth(deviceContext), justifyContent: 'flex-start', flexDirection: 'row', backgroundColor: '#000000' }}>
                        <Pressable
                            hasPreferredFocus={true}
                            focusOptions={{ focusKey: 'back', nextFocusUp: 'playPause' }}
                            style={{ padding: getCorrectHeight(deviceContext, deviceContext.isPhone ? 20 : 10), backgroundColor: '#000000' }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => onBack()}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#ffffff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraExtraLarge' : 'ExtraLarge')} style={{}}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                    </View>
                    {isLoaded && urlAndParams != '' && (
                        <View
                            style={[
                                {
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, 30),
                                    backgroundColor: '#000000',
                                },
                            ]}
                        >
                            <iframe
                                title="myiframe"
                                id="myiframe"
                                sandbox="allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                                scrolling="true"
                                seamless
                                allow="fullscreen"
                                style={{ border: 'none', display: 'block', height: '100vh', width: '100%' }}
                                loading="lazy"
                                src={urlAndParams}
                            ></iframe>
                        </View>
                    )}
                </>
            )}
        </View>
    );
};
export default WebViews;
