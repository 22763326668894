import moment from 'moment';
import * as React from 'react';
import { useContext, createContext, useState, useRef, useEffect } from 'react';
import { UserContext } from './userContext';
import { Channels } from '../models/content/channels/channels';
import { Program } from '../models/content/epg/epg_data';
import Modal from '../builder/components/modal/modal';
import { playChannel } from '../builder/elements/rails/helpers/helper';
import { AppContext } from './appContext';
import { getModalStyling } from '../builder/components/helpers/helper';
import { CoreManager, Screen, View } from '@scriptx-com/xtv-toolkit';
import { navigationRef } from '../navigation/navigation.base';
import { ContentContext } from './contentContext';
import { SettingsContext } from './settingsContext';
import { DeviceContext } from './deviceContext';
import { isFactorTv } from '@rnv/renative';
const CLOCK_INTERVAL_MS = 30000;
const REMINDER_AHEAD_S = 120;
const MIN_REMINDER_INTERVAL = 30000;

type ReminderType = { channel: Channels; program: Program; timeout?: NodeJS.Timeout };

interface IReminderContext {
    currentReminder: Readonly<ReminderType | undefined>;
}

export const ReminderContext = createContext<IReminderContext>(undefined);

export function ReminderProvider({ children }: { children: any }) {
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const contentContext = useContext(ContentContext);
    const settingsContext = useContext(SettingsContext);
    const deviceContext = useContext(DeviceContext);
    //setInterval returns NodeJS.Timer and clearInterval expects NodeJS.Timeout (maybe due to how old this node version is). Making type to any
    //to avoid ts errors while compiling webtv and tizen
    const clockRef = useRef<any>();
    const lastRemindedTS = useRef<number>(0);
    const pendingReminders = useRef<ReminderType[]>([]);
    const nearestPendingCallbackTS = useRef<number>();
    const lastPendingCallbackTS = useRef<number>();
    const executedCallBacks = useRef<{ channeId: any; programStart: any }[]>([]);
    const [currentReminder, setCurrentReminder] = useState<ReminderType>();

    const lastFocusedScreen = React.useRef<string>(undefined);
    const [focusSaved, setFocusSaved] = React.useState(deviceContext.isPhone);

    useEffect(() => {
        if (!!userContext.profile.id) clockRef.current = setInterval(handleCheck, CLOCK_INTERVAL_MS);
        return () => {
            if (clockRef.current) {
                clearInterval(clockRef.current);
                clockRef.current = undefined;
                pendingReminders.current.forEach((cb) => clearTimeout(cb.timeout));
                pendingReminders.current = [];
                lastRemindedTS.current = 0;
                setCurrentReminder(undefined);
                executedCallBacks.current = [];
                nearestPendingCallbackTS.current = undefined;
                lastPendingCallbackTS.current = undefined;
                lastFocusedScreen.current = undefined;
                setFocusSaved(false);
            }
        };
    }, [userContext.profile.id]);

    useEffect(() => {
        if (!clockRef.current || !pendingReminders.current || !userContext?.profile?.reminders?.length || pendingReminders.current.length === userContext.profile?.reminders) return;
        else {
            for (let i = 0; i < pendingReminders.current.length; i++) {
                if (!userContext.profile.reminders.find((r: Omit<ReminderType, 'timeout'>) => r.channel._id === pendingReminders[i].channel._id && r.program.s === pendingReminders[i].program.s)) {
                    clearTimeout(pendingReminders[i].timeout);
                    pendingReminders.current.splice(i, 1);
                }
            }
        }
    }, [userContext.profile.reminders]);

    const handleCheck = () => {
        const reminders = userContext.profile.reminders as Omit<ReminderType, 'timeout'>[];
        for (const reminder of reminders?.sort((a, b) => a.program.s - b.program.s) ?? []) {
            if (!executedCallBacks.current.find((v) => v.channeId === reminder.channel._id && v.programStart === reminder.program.s) && reminder.program.s > moment().unix() && reminder.program.s <= moment().unix() + REMINDER_AHEAD_S) {
                if (pendingReminders.current.length === 0 && lastRemindedTS.current < moment().unix() * 1000 - MIN_REMINDER_INTERVAL) {
                    setCurrentReminder(reminder);
                    executedCallBacks.current.push({ channeId: reminder.channel._id, programStart: reminder.program.s });
                    lastRemindedTS.current = moment().unix() * 1000;
                } else {
                    if (!pendingReminders.current.find((v) => v.channel._id === reminder.channel._id && v.program.s === reminder.program.s)) {
                        pushTimeout(reminder);
                    }
                }
            } else break;
        }
    };

    const pushTimeout = (reminder: ReminderType) => {
        let after: number | undefined = undefined;
        if (!nearestPendingCallbackTS.current) {
            after = MIN_REMINDER_INTERVAL;
            nearestPendingCallbackTS.current = after;
            lastPendingCallbackTS.current = after;
        } else {
            after = lastPendingCallbackTS.current + MIN_REMINDER_INTERVAL;
        }
        const timeout = setTimeout(() => {
            setCurrentReminder(reminder);
            executedCallBacks.current.push({ channeId: reminder.channel._id, programStart: reminder.program.s });
            pendingReminders.current = pendingReminders.current.splice(
                pendingReminders.current.findIndex((r) => r.channel._id === reminder.channel._id && r.program.s === reminder.program.s),
                1
            );
        }, after);
        pendingReminders.current.push({ ...reminder, timeout: timeout });
    };

    const setFocusToLastFocusedScreen = () => {
        const focus = Object.values(CoreManager.getScreens())
            .find((screen) => screen?.getId() === lastFocusedScreen.current)
            ?.getCurrentFocus();
        if (focus) {
            CoreManager.executeFocus(focus);
            lastFocusedScreen.current = undefined;
            setFocusSaved(false);
        } else {
            lastFocusedScreen.current = undefined;
            setFocusToAnyScreenInForeground();
        }
    };

    const setFocusToAnyScreenInForeground = () => {
        const focus = Object.values(CoreManager.getScreens())
            .find((screen) => screen.isInForeground() && screen.hasStealFocus() && screen.getCurrentFocus() !== null)
            ?.getCurrentFocus();
        if (focus) {
            CoreManager.executeFocus(focus);
        }
        setFocusSaved(false);
    };

    React.useEffect(() => {
        if (isFactorTv && currentReminder) {
            lastFocusedScreen.current = CoreManager.getCurrentFocus()?.getScreen()?.getId();
            setFocusSaved(true);
        }

        return () => {
            if (currentReminder && isFactorTv) {
                //should always pass this, else condition is as failsafe
                if (lastFocusedScreen.current !== undefined) {
                    setFocusToLastFocusedScreen();
                } else {
                    setFocusToAnyScreenInForeground();
                }
            }
        };
    }, [currentReminder]);

    return (
        <ReminderContext.Provider value={{ currentReminder }}>
            {children}
            {currentReminder && (!isFactorTv || focusSaved) && (
                <Screen focusOptions={{ selfScreenStateSwitchEnabled: false, screenOrder: 9999, screenState: 'foreground' }} style={{ position: 'absolute', zIndex: 9999, top: 0, left: 0, bottom: 0, right: 0 }}>
                    <Modal
                        focusContext={undefined}
                        styling={getModalStyling(appContext.application.theme.modals.exit, appContext)}
                        type={'reminder_start'}
                        submitChoice={() => {
                            playChannel(contentContext, userContext, appContext, currentReminder.channel, navigationRef.current, settingsContext);
                            setCurrentReminder(undefined);
                        }}
                        setShowModal={() => setCurrentReminder(undefined)}
                        program={currentReminder.program}
                        rotated={deviceContext.isPhone && navigationRef.current.getCurrentRoute().name.includes('Player')}
                    />
                </Screen>
            )}
        </ReminderContext.Provider>
    );
}
