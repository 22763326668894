import { Pressable, ScrollView, View, Screen } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';
import CryptoJS from '../../../application/general/cryptojs';
import utils from '../../../application/general/utils';
import { ContentContext } from '../../../application/context/contentContext';

const SubscriptionScreen = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [show, setShow] = React.useState(false);
    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        const fetchData = async () => {
            await getUser();
            setShow(true);
        };
        fetchData();
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            contentContext.setActiveMenu(route.name);
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.SUBINFO, duration);
            };
        }, [])
    );

    const getUser = async () => {
        let userCryptedPassword = CryptoJS.AES.encrypt(userContext.pass, CryptoJS.enc.Hex.parse(userContext.pass), {
            iv: CryptoJS.enc.Hex.parse('fedcba9876543210'),
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();

        let path =
            'https://cloudtv.akamaized.net/' +
            appContext.application.client +
            '/' +
            appContext.application.branding.deployment +
            '/' +
            appContext.application.branding.service +
            '/users/' +
            utils.toAlphaNumeric(userContext.userId).split('').join('/') +
            '/' +
            utils.toAlphaNumeric(userCryptedPassword) +
            '.json';
        let response = await fetch(path);
        if (response.status == 200) {
            let data = await response.json();
            if (data.DELETED) {
                return lang.getTranslation(userContext, 'error_credentials');
            }
            if (data != undefined) {
                userContext.setUser(data);
            }
        }
    };

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <Screen focusOptions={{ ...focusOptions }} style={{ flex: 1, width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + global.settingsMenu) }}>
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: appContext.application.theme.settings.background_color1,
                    borderRadius: appContext.application.theme.settings.border_radius,
                    margin: getCorrectWidth(deviceContext, 10),
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                        fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                    }}
                >
                    {lang.getTranslation(userContext, 'your_subscription')}
                </Text>
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                        fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                        color: '#ffffff',
                        textAlign: 'center',
                    }}
                >
                    {lang.getTranslation(userContext, 'subscription_info').toUpperCase()}
                </Text>
            </View>
            <View style={{ flex: 9, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}>
                <ScrollView
                    contentContainerStyle={{
                        paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                    }}
                    showsVerticalScrollIndicator={false}
                >
                    <Pressable
                        focusOptions={{
                            hasPreferredFocus: true,
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        {userContext.user.customer != undefined && (
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'your_information')}
                                </Text>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'userid')}:{' '}
                                    </Text>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {userContext.userId}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'first_name')}:{' '}
                                    </Text>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {userContext.user.customer.first_name}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'last_name')}:{' '}
                                    </Text>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {userContext.user.customer.last_name}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'email')}:{' '}
                                    </Text>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {userContext.user.customer.email ?? ''}
                                    </Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'phone')}:{' '}
                                    </Text>
                                    <Text
                                        style={{
                                            color: appContext.application.theme.settings.font_color,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {userContext.user.customer.phone ?? ''}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </Pressable>
                    <Pressable
                        focusOptions={{
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <View
                            // animatorOptions={appContext.application.authentication.login.animation}
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                padding: getCorrectWidth(deviceContext, 20),
                            }}
                        >
                            <Text
                                style={{
                                    color: appContext.application.theme.settings.font_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    marginBottom: getCorrectWidth(deviceContext, 10),
                                }}
                            >
                                {lang.getTranslation(userContext, 'base_subscription')}
                            </Text>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'product_name')}:{' '}
                                </Text>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {userContext.product.name}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'status')}:{' '}
                                </Text>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {userContext.user.status.charAt(0).toUpperCase() + userContext.user.status.slice(1)}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'expires')}:{' '}
                                </Text>
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                    }}
                                >
                                    {moment.unix(userContext.user.expire).format('llll')}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                    {userContext.user.extra_products.length > 0 && (
                        <Pressable
                            focusOptions={{
                                animator: {
                                    type: 'scale',
                                    focus: {
                                        scale: 1.01,
                                    },
                                },
                            }}
                            style={{
                                flexDirection: 'row',
                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20 + global.settingsMenu),
                                backgroundColor: appContext.application.theme.settings.background_color2,
                                borderRadius: appContext.application.theme.settings.border_radius,

                                margin: getCorrectWidth(deviceContext, 10),
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'extra_subscriptions')}
                                </Text>
                                {userContext.user?.extra_products?.map((product) => {
                                    return (
                                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'product_name')}:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {product.product.name}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'status')}:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {userContext.user.status.charAt(0).toUpperCase() + userContext.user.status.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'expires')}:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color: appContext.application.theme.settings.font_color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                    }}
                                                >
                                                    {moment.unix(userContext.user.expire).format('llll')}
                                                </Text>
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        </Pressable>
                    )}
                    {(userContext.product?.services?.recordings || userContext.product?.services?.catchuptv || userContext.product?.services?.pausetv) && (
                        <Pressable
                            focusOptions={{
                                animator: {
                                    type: 'scale',
                                    focus: {
                                        scale: 1.01,
                                    },
                                },
                            }}
                            style={{
                                flexDirection: 'row',
                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20 + global.settingsMenu),
                                backgroundColor: appContext.application.theme.settings.background_color2,
                                borderRadius: appContext.application.theme.settings.border_radius,

                                margin: getCorrectWidth(deviceContext, 10),
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    padding: getCorrectWidth(deviceContext, 20),
                                }}
                            >
                                <Text
                                    style={{
                                        color: appContext.application.theme.settings.font_color,
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'your_services')}
                                </Text>
                                {userContext.product.services.recordings.recordings && (
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'recording')}:{' '}
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {userContext.product.services.recordings.hours} {lang.getTranslation(userContext, 'hours')}
                                        </Text>
                                    </View>
                                )}
                                {userContext.product.services.catchuptv && (
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'replay')}:{' '}
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'enabled')}
                                        </Text>
                                    </View>
                                )}
                                {userContext.product.services.pausetv && (
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'pausetv')}:{' '}
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'enabled')}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                    )}
                    <Pressable
                        focusOptions={{
                            animator: {
                                type: 'scale',
                                focus: {
                                    scale: 1.01,
                                },
                            },
                        }}
                        style={{
                            padding: getCorrectWidth(deviceContext, 20),
                            flexDirection: 'column',
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20 + global.settingsMenu),
                            backgroundColor: appContext.application.theme.settings.background_color2,
                            borderRadius: appContext.application.theme.settings.border_radius,

                            margin: getCorrectWidth(deviceContext, 10),
                        }}
                    >
                        <View>
                            <Text
                                style={{
                                    color: appContext.application.theme.settings.font_color,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    marginBottom: getCorrectWidth(deviceContext, 10),
                                }}
                            >
                                {lang.getTranslation(userContext, 'your_supported_devices')}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'mediaplayer')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Roku
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            AndroidTV
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            FireTV
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'phones')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            iPhone
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Android
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'tablets')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            iPhone
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Android
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'smarttv')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Samsung Tizen
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            LG WebOS
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: getCorrectWidth(deviceContext, 10),
                                    }}
                                >
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'stb')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Linux
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Android
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                marginBottom: getCorrectWidth(deviceContext, 5),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'desktop')}:
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            MacOS
                                        </Text>
                                        <Text
                                            style={{
                                                color: appContext.application.theme.settings.font_color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            Windows
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Pressable>
                </ScrollView>
            </View>
        </Screen>
    );
};
export default SubscriptionScreen;
