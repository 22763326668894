import { ActivityIndicator, View } from '@scriptx-com/xtv-toolkit';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { ContentContext } from '../../../application/context/contentContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import { loadContent } from '../../../application/data/content';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import Promotions_KaiAds from '../../../application/builder/elements/promotions/kaiad';
import Promotions_Ads from '../../../application/builder/elements/promotions/ad';
import ScreenWrapper from '../../wrapper';
import { Promotions_CastoolaFullscreen } from '../../../application/builder/elements/promotions/castoolafullscreen';
import Promotions_CastoolaAds from '../../../application/builder/elements/promotions/castoolaads';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import Text from '../../../application/builder/components/text';
import { getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import { getFontSize } from '../../../application/styling/fontSizes';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [elementHeader, setElementHeader] = React.useState([] as any);
    const [elementFooter, setElementFooter] = React.useState([] as any);
    const [elementContent, setElementContent] = React.useState([] as any);
    const [startTimer, setStartTimer] = React.useState(false);

    useEffectOnce(() => {
        var loaderScreen = appContext.application.screens.find((s) => s.type == 'Loader');
        if (loaderScreen != undefined && elementHeader.length == 0 && loaderScreen.header.length > 0) {
            setElementHeader(loaderScreen.header[0]);
        }
        if (loaderScreen != undefined && elementFooter.length == 0 && loaderScreen.footer.length > 0) {
            setElementFooter(loaderScreen.footer[0]);
        }
        if (loaderScreen != undefined && elementContent.length == 0 && loaderScreen.content.length > 0) {
            setElementContent(loaderScreen.content[0]);
        }
        loadContent(appContext, contentContext, userContext).then((result) => {
            const date = moment().add(0, 'day').format('DD') + '-' + Number(moment().add(0, 'day').month() + 1) + '-' + moment().add(0, 'day').year();
            contentContext.setDataLoadedTimestamp(moment().unix());
            contentContext.setEpgLoadedDate(date);
            if (loaderScreen != undefined) {
                if (loaderScreen.content.length > 0 && loaderScreen.content[0].castoolafullscreen != undefined) {
                    setStartTimer(true);
                } else {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Profiles', params: route.params }],
                        })
                    );
                }
            } else {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles', params: route.params }],
                    })
                );
            }
        });
    });

    const [timerCount, setTimer] = React.useState(5);

    React.useEffect(() => {
        if (startTimer == true) {
            let interval = setInterval(() => {
                if (timerCount > -1) {
                    setTimer((lastTimerCount) => {
                        lastTimerCount <= 1 && clearInterval(interval);
                        return lastTimerCount - 1;
                    });
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [startTimer]);

    React.useEffect(() => {
        if (timerCount == 0) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Profiles', params: route.params }],
                })
            );
        }
    }, [timerCount]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.LOADER, duration);
            };
        }, [])
    );

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <View style={{ flex: 4, flexDirection: 'row' }}>
                {elementContent.castoolafullscreen != undefined && (
                    <View style={{ position: 'absolute' }}>
                        <Promotions_CastoolaFullscreen fromLoader={true} key={2} styling={elementContent.castoolafullscreen} focusContext={focusContext}></Promotions_CastoolaFullscreen>
                    </View>
                )}
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        {elementHeader.kaiads != undefined && <Promotions_KaiAds fromLoader={true} key={1} styling={elementHeader.kaiads} focusContext={focusContext}></Promotions_KaiAds>}
                        {elementHeader.ads != undefined && <Promotions_Ads fromLoader={true} key={2} styling={elementHeader.ads} focusContext={focusContext}></Promotions_Ads>}
                        {elementHeader.castoolaads != undefined && <Promotions_CastoolaAds fromLoader={true} key={2} styling={elementHeader.castoolaads} focusContext={focusContext}></Promotions_CastoolaAds>}
                    </View>

                    <View style={{ flex: 1 }}>
                        {elementContent.castoolafullscreen == undefined && (
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: getRealWidth(deviceContext) }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        )}
                        {elementContent.castoolafullscreen != undefined && (
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: getRealWidth(deviceContext) }}>
                                <View
                                    style={{
                                        width: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 45),
                                        height: getCorrectWidth(deviceContext, deviceContext.isPhone ? 35 : 45),
                                        backgroundColor: 'black',
                                        justifyContent: 'center',
                                        borderRadius: 100,
                                    }}
                                >
                                    <ActivityIndicator size={deviceContext.isPhone ? 'small' : 'large'} color={'white'}></ActivityIndicator>
                                </View>
                                <View
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        width: getCorrectWidth(deviceContext, 45),
                                        padding: getCorrectWidth(deviceContext, 5),
                                        backgroundColor: 'black',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                    }}
                                >
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        }}
                                    >
                                        00:0{timerCount}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        {elementFooter.kaiads != undefined && <Promotions_KaiAds fromLoader={true} key={3} styling={elementFooter.kaiads} focusContext={focusContext}></Promotions_KaiAds>}
                        {elementFooter.ads != undefined && <Promotions_Ads fromLoader={true} key={4} styling={elementFooter.ads} focusContext={focusContext}></Promotions_Ads>}
                        {elementFooter.castoolaads != undefined && <Promotions_CastoolaAds fromLoader={true} key={2} styling={elementFooter.castoolaads} focusContext={focusContext}></Promotions_CastoolaAds>}
                    </View>
                </View>
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
