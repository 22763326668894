import React from 'react';
import { StyleSheet, Pressable, Dimensions, ActivityIndicator } from 'react-native';
import { CoreManager, setFocus, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '../../../../screens/wrapper/useFocusEffect';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import { getCorrectWidth } from '../../../styling/correctSizes';
import { isFactorTv } from '@rnv/renative';
import { getRealHeight } from '../../../styling/realDeviceSizes';

const screenWidth = Dimensions.get('screen').width;

const NEXT_SLIDE_TIMEOUS_MS = 8000;

const SwiperButtonsKeys = ['btn-main', 'purchase_option', 'favorites', 'watchlist', 'download', 'replay', 'trailer'];

const Swiper = ({ focusContext, children, onIndexChanged, activeDotColor, activeDotStyle, dotStyle, dotColor, paginationStyle, isPlaceholderSlide, deviceContext, hideSwiper }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const chCount = React.Children.count(children) - 1;
    const timeout1 = React.useRef<NodeJS.Timeout>();
    const focusedButtonKey = React.useRef<string | undefined>();
    // const [paused, setPaused] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const isMountFocus = React.useRef(true);

    const setFocusOnButton = () => {
        if (CoreManager.getCurrentFocus()) {
            return;
        }

        const isExists = CoreManager.isElementExistInFocusMap('btn-main', 'view');
        if (isExists) {
            setFocus('btn-main');
        } else {
            const interval = setInterval(() => {
                const isExists = CoreManager.isElementExistInFocusMap('btn-main', 'view');
                if (isExists) {
                    setFocus('btn-main');
                    clearInterval(interval);
                }
            }, 50);
        }
    };

    React.useEffect(() => {
        if (!isPlaceholderSlide) {
            setIsLoaded(true);
            setFocusOnButton();
        }
    }, [isPlaceholderSlide]);

    // React.useEffect(() => {
    //     setFocusOnButton();

    //     return () => {
    //         clearTimeout(timeout1.current);
    //         setPaused(true);
    //     };
    // }, []);

    // useFocusEffect(
    //     React.useCallback(() => {
    //         setPaused(false);
    //         return () => {
    //             setPaused(true);
    //         };
    //     }, [])
    // );

    React.useEffect(() => {
        if (!isPlaceholderSlide) {
            timeout1.current = setTimeout(() => {
                // if (paused === false) {
                onBeforeSlideChange();
                const nextIndex = currentIndex + 1 > chCount ? 0 : currentIndex + 1;
                onIndexChanged(nextIndex);
                setCurrentIndex(nextIndex);

                //  }
            }, NEXT_SLIDE_TIMEOUS_MS);
        }
        return () => clearTimeout(timeout1.current);
    }, [isPlaceholderSlide, currentIndex]);

    useFocusEffect(
        React.useCallback(() => {
            const recoverFocus = () => {
                const homeScreen = CoreManager.getElementByFocusKey('content-screen', 'screen');
                if (!homeScreen) return;
                if (!homeScreen.isInForeground()) setTimeout(recoverFocus, 1);
                else if (!homeScreen.getCurrentFocus()) {
                    for (const child of homeScreen.getChildren()) homeScreen.remeasureSelfAndChildrenLayouts(child);

                    for (const key of SwiperButtonsKeys) {
                        const focusable = CoreManager.getElementByFocusKey(key, 'view');
                        if (focusable) {
                            CoreManager.executeFocus(focusable);
                            break;
                        }
                    }
                }
            };
            //to not call this function on initial load
            //or when using navigation.reset where focus is behaving like expected
            if (isFactorTv && !isMountFocus.current) recoverFocus();
            else isMountFocus.current = false;
        }, [])
    );

    React.useEffect(() => {
        if (focusedButtonKey.current) {
            const isExists = CoreManager.isElementExistInFocusMap(focusedButtonKey.current, 'view');
            if (isExists) {
                setFocus(focusedButtonKey.current);
            } else {
                const isExists = CoreManager.isElementExistInFocusMap('btn-main', 'view');
                if (isExists) {
                    setFocus('btn-main');
                } else {
                    const firstExists = SwiperButtonsKeys.find((key) => CoreManager.isElementExistInFocusMap(key, 'view'));
                    if (firstExists) {
                        setFocus(firstExists);
                    }
                }
            }
        } else if (isFactorTv) {
            const homeScreen = CoreManager.getElementByFocusKey('content-screen', 'screen');
            if (!homeScreen?.isInForeground()) return;
            if (!homeScreen.getCurrentFocus()) {
                for (const key of SwiperButtonsKeys) {
                    const focusable = CoreManager.getElementByFocusKey(key, 'view');
                    if (focusable) {
                        homeScreen.setCurrentFocus(focusable);
                        break;
                    }
                }
            }
        }
    }, [currentIndex]);

    const onBeforeSlideChange = () => {
        const focusKey = CoreManager.getCurrentFocus()?.getFocusKey();
        focusedButtonKey.current = SwiperButtonsKeys.find((key) => key === focusKey);
    };

    const renderPagination = () => {
        const chCount = React.Children.count(children);
        // By default, dots only show when `total` >= 2
        if (chCount <= 1) return null;

        let dots = [];
        const ActiveDot = (
            <View
                style={[
                    {
                        backgroundColor: activeDotColor || '#007aff',
                        width: getCorrectWidth(deviceContext, 9),
                        height: getCorrectWidth(deviceContext, 9),
                        borderRadius: getCorrectWidth(deviceContext, 9),
                        margin: getCorrectWidth(deviceContext, 3),
                    },
                    activeDotStyle,
                ]}
            />
        );

        for (let i = 0; i < chCount; i++) {
            dots.push(
                i === currentIndex
                    ? React.cloneElement(ActiveDot, { key: i })
                    : React.cloneElement(
                          <View
                              style={[
                                  dotStyle,
                                  {
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: 'transparent',
                                  },
                              ]}
                          >
                              <View
                                  style={[
                                      {
                                          backgroundColor: dotColor || 'rgba(0,0,0,.2)',
                                          width: getCorrectWidth(deviceContext, 4),
                                          height: getCorrectWidth(deviceContext, 4),
                                          borderRadius: getCorrectWidth(deviceContext, 4),
                                          margin: getCorrectWidth(deviceContext, 3),
                                      },
                                  ]}
                              ></View>
                          </View>,
                          { key: i }
                      )
            );
        }

        return <View style={[styles.paginationX, paginationStyle]}>{dots}</View>;
    };

    return (
        <>
            {isLoaded && (
                <View focusContext={focusContext} style={{ flexDirection: 'row' }}>
                    {children[currentIndex]}

                    {hideSwiper ? null : renderPagination()}
                </View>
            )}
            {!isLoaded && (
                <View focusContext={focusContext} style={{ flex: 1,flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator color={'#ffffff'} size={'large'}></ActivityIndicator>
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    paginationX: {
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: 9999,
        maxWidth: screenWidth,
    },
});

export default Swiper;
