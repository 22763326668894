import { CreateListRenderItemInfo, FlashList, FocusContext, View, setFocus } from '@scriptx-com/xtv-toolkit';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { Navigation_Menu as styling } from '../../../models/elements/navigation/menu/menu';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import Dropdown_ from '../../components/dropdown/dropdown';
import Pressable from '../../components/pressable/pressable';
import Text from '../../components/text';
import { getCorrectName } from '../players/helpers/functions';
import { getPressableStyling, getSelectedStyling, getFocusStyling, getPressableTextStyling, getDropdownStyling } from '../../components/helpers/helper';
import { colorShade } from '../../../styling/colorShade';
import { filterAgeRatings } from '../grids/helpers/functions';

export interface Props {
    styling: styling;
    focusContext: FocusContext;
}

type DropdownRef = {
    current: {
        isOpen: () => boolean;
        toggleDropdown: (value: boolean) => void;
    };
};
export const Navigation_Menu = ({ styling, focusContext }: Props) => {
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const [subMenus, setSubMenus] = React.useState([] as any);
    const [subIndex, setSubIndex] = React.useState(0);
    const [mainIndex, setMainIndex] = React.useState(0);
    const [localCategoryIndex, setLocalCategoryIndex] = React.useState(0);
    const screenName = getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const appContext = React.useContext(AppContext);
    const main = React.useRef<FlashList_<number> | null>(null);
    const sub = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);

    const dropdowns = React.useRef<{ ref1: DropdownRef; ref2: DropdownRef }>({
        ref1: React.createRef(),
        ref2: React.createRef(),
    }).current;

    if (styling.general == undefined && styling.placement != undefined) {
        styling.general = styling.placement;
    }

    var screenWidth = getRealWidth(deviceContext) - getCorrectWidth(deviceContext, styling.general?.margin_left + styling.general?.margin_right) - getCorrectWidth(deviceContext, global.menuWidth);

    const getData = async () => {
        var categories = [] as any;
        if (screenName == 'Channels') {
            categories = contentContext.channels.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.channelData.localCategoryIndex != 0 ? contentContext.channelData.localCategoryIndex : contentContext.channelData.channelCategoryIndex);
                setMainIndex(contentContext.channelData.channelCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Radio') {
            categories = contentContext.radios.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.radioData.localCategoryIndex != 0 ? contentContext.radioData.localCategoryIndex : contentContext.radioData.radioCategoryIndex);
                setMainIndex(contentContext.radioData.radioCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Cams') {
            categories = contentContext.cams.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.camData.localCategoryIndex != 0 ? contentContext.camData.localCategoryIndex : contentContext.camData.camCategoryIndex);
                setMainIndex(contentContext.camData.camCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Movies') {
            categories = contentContext.movies.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.movieData.localCategoryIndex != 0 ? contentContext.movieData.localCategoryIndex : contentContext.movieData.movieCategoryIndex);
                setMainIndex(contentContext.movieData.movieCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Games') {
            categories = contentContext.games.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.gameData.localCategoryIndex != 0 ? contentContext.gameData.localCategoryIndex : contentContext.gameData.gameCategoryIndex);
                setMainIndex(contentContext.gameData.gameCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Shorts') {
            categories = contentContext.shorts.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.shortData.localCategoryIndex != 0 ? contentContext.shortData.localCategoryIndex : contentContext.shortData.shortCategoryIndex);
                setMainIndex(contentContext.shortData.shortCategoryIndex);
                setSubIndex(contentContext.nestedCategorySubIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Series') {
            categories = contentContext.series.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.seriesData.localCategoryIndex != 0 ? contentContext.seriesData.localCategoryIndex : contentContext.seriesData.seriesCategoryIndex);
                setMainIndex(contentContext.seriesData.seriesCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Seasons') {
            categories = contentContext.seriesData.seasons;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.seriesData.localCategoryIndex != 0 ? contentContext.seriesData.localCategoryIndex : contentContext.seriesData.seasonCategoryIndex);
                setMainIndex(contentContext.seriesData.seasonCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Podcasts') {
            categories = contentContext.podcasts.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.podcastData.localCategoryIndex != 0 ? contentContext.podcastData.localCategoryIndex : contentContext.podcastData.podcastCategoryIndex);
                setMainIndex(contentContext.podcastData.podcastCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Music') {
            categories = contentContext.albums.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.albumData.localCategoryIndex != 0 ? contentContext.albumData.localCategoryIndex : contentContext.albumData.albumCategoryIndex);
                setMainIndex(contentContext.albumData.albumCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
        if (screenName == 'Courses') {
            categories = contentContext.courses.categories;
            if (categories != undefined) {
                setLocalCategoryIndex(contentContext.courseData.localCategoryIndex != 0 ? contentContext.courseData.localCategoryIndex : contentContext.courseData.courseCategoryIndex);
                setMainIndex(contentContext.courseData.courseCategoryIndex);
                setData(getCats(categories));
                setShow(true);
            }
        }
    };

    const getCats = (categories) => {
        var cats = [] as any;
        var sub = subMenus;
        var index = 0;
        var newIndex = 0;
        categories.forEach((category) => {
            if (category.nested != undefined && category.nested.length > 0) {
                newIndex++;
                var test = sub.findIndex((s) => s.group[0].description == category.nested[0].description);
                if (test == -1) {
                    sub.push({
                        categories: [
                            {
                                ...category,
                                index: index,
                            },
                        ],
                        group: category.nested,
                    });
                    cats.push({
                        ...category,
                        translations: category.nested,
                        index: index,
                        nested: true,
                    });
                } else {
                    sub[test].categories.push({
                        ...category,
                        index: index,
                        nested: true,
                    });
                }
            } else {
                cats.push({
                    ...category,
                    index: index + newIndex,
                    nested: false,
                });
            }
            newIndex = 0;
            index++;
        });
        setSubMenus(sub);
        return cats;
    };

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        setFocus(`menu-item-${mainIndex}`);
    }, [mainIndex]);

    const selectedItem = (mainIndex: any, subIndex: any, itemIndex: any, type: any) => {
        setLocalCategoryIndex(mainIndex);
        if (type == '') {
            setMainIndex(mainIndex);
            if (data[mainIndex]?.nested) {
                setSubIndex(0);
                contentContext.setNestedCategorySubIndex(0);
            } else {
                setSubIndex(-1);
                contentContext.setNestedCategorySubIndex(-1);
            }
        } else {
            setSubIndex(subIndex);
            contentContext.setNestedCategorySubIndex(subIndex);
        }
        if (screenName == 'Channels') {
            contentContext.setChannelData({
                ...contentContext.channelData,
                channelCategoryIndex: mainIndex,
                channelListCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Radio') {
            contentContext.setRadioData({
                ...contentContext.radioData,
                radioCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Cams') {
            contentContext.setCamData({
                ...contentContext.camData,
                camCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Movies') {
            contentContext.setMovieData({
                ...contentContext.movieData,
                movieCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Games') {
            contentContext.setGameData({
                ...contentContext.gameData,
                gameCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Shorts') {
            contentContext.setShortData({
                ...contentContext.shortData,
                shortCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Series') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seriesCategoryIndex: mainIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Seasons') {
            contentContext.setSeriesData({
                ...contentContext.seriesData,
                seasonCategoryIndex: mainIndex,
                season: contentContext.seriesData.seasons[mainIndex],
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Podcasts') {
            contentContext.setPodcastData({
                ...contentContext.podcastData,
                podcastCategoryIndex: itemIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Music') {
            contentContext.setAlbumData({
                ...contentContext.albumData,
                albumCategoryIndex: itemIndex,
                localCategoryIndex: itemIndex,
            });
        }
        if (screenName == 'Courses') {
            contentContext.setCourseData({
                ...contentContext.courseData,
                courseCategoryIndex: itemIndex,
                localCategoryIndex: itemIndex,
            });
        }
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        var length = '';
        var extra = '';
        if (screenName == 'Seasons' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.episodes, userContext, appContext).length + ')' ?? '';
            extra = item.number != undefined ? item.number + '. ' : '';
        }
        if (screenName == 'Channels' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.channels, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Radio' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.radios, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Cams' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.cams, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Movies' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.movies, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Series' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.series, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Podcasts' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.podcasts, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Music' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.albums, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Courses' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.courses, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Games' && item.nested == false) {
            length = ' (' + filterAgeRatings(item.games, userContext, appContext).length + ')' ?? '';
        }
        if (screenName == 'Shorts' && item.nested == false && !item.external) {
            length = ' (' + filterAgeRatings(item.shorts, userContext, appContext).length + ')' ?? '';
        }
        var name = '';
        if (screenName != 'Seasons') {
            name = getCorrectName(item, userContext);
        } else {
            name = item.name;
        }
        var background = 'transparent';
        if (index == mainIndex) {
            background = getSelectedStyling('Menus', appContext).background_color;
        }

        return (
            <Pressable
                style={{
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    height: getCorrectHeight(deviceContext, 29),
                    marginRight: styling.general?.margin_right ?? 0,
                    margin: getCorrectHeight(deviceContext, 3),
                }}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => selectedItem(index, 0, item.index, '')}
                focusOptions={{
                    focusKey: index === localCategoryIndex ? `menu-item-active-0` : `menu-item-0-${index}`,
                    hasPreferredFocus: index === mainIndex,
                }}
            >
                <View
                    style={{
                        paddingHorizontal: getCorrectWidth(deviceContext, 10),
                        height: getCorrectHeight(deviceContext, 24),
                        justifyContent: 'center',
                        alignContent: 'center',
                        backgroundColor: background,
                        margin: getCorrectWidth(deviceContext, 2),
                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius / 2,
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={{
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            maxWidth: getCorrectWidth(deviceContext, 150),
                            includeFontPadding: false,
                        }}
                    >
                        {extra}
                        {name}
                        {length}
                    </Text>
                </View>
            </Pressable>
        );
    };
    const renderItemSub = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        var length = '';
        var extra = '';
        if (screenName == 'Seasons' && item.nested == false) {
            length = ' (' + item.episodes.length + ')' ?? '';
            extra = item.number != undefined ? item.number + '. ' : '';
        }
        if (screenName == 'Channels' && item.nested == false) {
            length = ' (' + item.channels.length + ')' ?? '';
        }
        if (screenName == 'Radio' && item.nested == false) {
            length = ' (' + item.radios.length + ')' ?? '';
        }
        if (screenName == 'Cams' && item.nested == false) {
            length = ' (' + item.cams.length + ')' ?? '';
        }
        if (screenName == 'Movies' && item.nested == false) {
            length = ' (' + item.movies.length + ')' ?? '';
        }
        if (screenName == 'Series' && item.nested == false) {
            length = ' (' + item.series.length + ')' ?? '';
        }
        if (screenName == 'Podcasts' && item.nested == false) {
            length = ' (' + item.podcasts.length + ')' ?? '';
        }
        if (screenName == 'Music' && item.nested == false) {
            length = ' (' + item.albums.length + ')' ?? '';
        }
        if (screenName == 'Courses' && item.nested == false) {
            length = ' (' + item.courses.length + ')' ?? '';
        }
        if (screenName == 'Games' && item.nested == false) {
            length = ' (' + item.games.length + ')' ?? '';
        }
        if (screenName == 'Shorts' && item.nested == false && !item.external) {
            length = ' (' + item.shorts.length + ')' ?? '';
        }
        var name = '';
        if (screenName != 'Seasons') {
            name = getCorrectName(item, userContext);
        } else {
            name = item.name;
        }
        var background = 'transparent';
        if (index == subIndex) {
            background = getSelectedStyling('Menus', appContext).background_color;
        }
        return (
            <Pressable
                style={{
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    height: getCorrectHeight(deviceContext, 29),
                    marginRight: styling.general?.margin_right ?? 0,
                    margin: getCorrectHeight(deviceContext, 3),
                }}
                // hasPreferredFocus={true}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    focusKey: index === subIndex ? `menu-item-active-1` : `menu-item-1-${index}`,
                    hasPreferredFocus: index === subIndex,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => selectedItem(mainIndex, index, item.index, 'sub')}
            >
                <View
                    style={{
                        paddingHorizontal: getCorrectWidth(deviceContext, 10),
                        height: getCorrectHeight(deviceContext, 23),
                        justifyContent: 'center',
                        alignContent: 'center',
                        backgroundColor: background,
                        margin: getCorrectWidth(deviceContext, 3),
                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius / 2,
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={{
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            maxWidth: getCorrectWidth(deviceContext, 150),
                            includeFontPadding: false,
                        }}
                    >
                        {extra}
                        {name}
                        {length}
                    </Text>
                </View>
            </Pressable>
        );
    };

    // const getInitialScrollIndex = () => {
    //     if (screenName == 'Seasons') {
    //         return contentContext.seriesData.seasonCategoryIndex;
    //     }
    //     if (screenName == 'Courses') {
    //         return contentContext.courseData.courseCategoryIndex;
    //     }
    //     if (screenName == 'Channels') {
    //         return contentContext.channelData.channelCategoryIndex;
    //     }
    //     if (screenName == 'Radio') {
    //         return contentContext.radioData.radioCategoryIndex;
    //     }
    //     if (screenName == 'Cams') {
    //         return contentContext.camData.camCategoryIndex;
    //     }
    //     if (screenName == 'Movies') {
    //         return contentContext.movieData.movieCategoryIndex;
    //     }
    //     if (screenName == 'Series') {
    //         return contentContext.seriesData.seriesCategoryIndex;
    //     }
    //     if (screenName == 'Podcasts') {
    //         return contentContext.podcastData.podcastCategoryIndex;
    //     }
    //     if (screenName == 'Music') {
    //         return contentContext.albumData.albumCategoryIndex;
    //     }
    //     if (screenName == 'Courses') {
    //         return contentContext.courseData.courseCategoryIndex;
    //     }
    //     return 0;
    // };

    const scrollRightMain = () => {
        main.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
    };

    const scrollLeftMain = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        main.current?.scrollToIndex({ animated: true, index: next });
    };

    const scrollRightSub = () => {
        sub.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
    };

    const scrollLeftSub = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        sub.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    const getSubMenuData = () => {
        if (data[mainIndex]?.nested == true) {
            var test = subMenus.findIndex((s) => s.group[0].description == data[mainIndex].translations[0].description);
            if (test > -1) {
                return subMenus[test].categories;
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    React.useEffect(() => {
        getSubMenuData();
    }, [mainIndex]);

    const selectedItemDropdown = (index, item, sub) => {
        if (sub != '') {
            selectedItem(mainIndex, index, item.index, 'sub');
        } else {
            selectedItem(index, 0, item.index, '');
        }
    };

    return (
        <View focusContext={focusContext} style={{ zIndex: 9999, paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
            {deviceContext.isPhone && (
                <>
                    {data.length > 0 && show && (
                        <>
                            <View
                                style={{
                                    zIndex: 999,
                                    height: getCorrectHeight(deviceContext, data[mainIndex]?.nested ? 35 : 0),
                                }}
                            >
                                {data.length > 0 && show && (
                                    <>
                                        <View
                                            style={{
                                                flex: 1,
                                                height: getCorrectHeight(deviceContext, 35),
                                                //  backgroundColor: styling.general?.background_color,
                                                overflow: 'visible',
                                                alignItems: 'center',
                                                width: getRealWidth(deviceContext),
                                                position: 'absolute',
                                                zIndex: 9999,
                                            }}
                                        >
                                            {data.length > 0 && (
                                                <Dropdown_
                                                    width={getRealWidth(deviceContext) * 0.96}
                                                    height={getRealHeight(deviceContext) * 0.35}
                                                    setSelection={(index, item) => selectedItemDropdown(index, item, '')}
                                                    styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                                    animation={getFocusStyling('Dropdowns', appContext)}
                                                    value={data[localCategoryIndex]}
                                                    dataIn={data}
                                                    type={'Menu'}
                                                    dropdownRef={dropdowns.ref1}
                                                    onToggleDropdown={(value) => {
                                                        if (value && dropdowns.ref2.current && dropdowns.ref2.current.isOpen()) {
                                                            dropdowns.ref2.current.toggleDropdown(false);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </View>
                                        {data[mainIndex]?.nested && subMenus.length > 0 && data.length > 0 && (
                                            <View
                                                style={{
                                                    flex: 1,
                                                    top: getCorrectHeight(deviceContext, 35),
                                                    height: getCorrectHeight(deviceContext, 35),
                                                    backgroundColor: styling.general?.background_color,
                                                    overflow: 'visible',
                                                    alignItems: 'center',
                                                    width: getRealWidth(deviceContext),
                                                    position: 'absolute',
                                                    zIndex: 9998,
                                                }}
                                            >
                                                <Dropdown_
                                                    width={getRealWidth(deviceContext) * 0.96}
                                                    height={getRealHeight(deviceContext) * 0.35}
                                                    setSelection={(index, item) => selectedItemDropdown(index, item, 'sub')}
                                                    styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                                    animation={getFocusStyling('Dropdowns', appContext)}
                                                    value={getSubMenuData()[subIndex]}
                                                    dataIn={getSubMenuData()}
                                                    type={'Menu'}
                                                    dropdownRef={dropdowns.ref2}
                                                    onToggleDropdown={(value) => {
                                                        if (value && dropdowns.ref1.current && dropdowns.ref1.current.isOpen()) {
                                                            dropdowns.ref1.current.toggleDropdown(false);
                                                        }
                                                    }}
                                                />
                                            </View>
                                        )}
                                    </>
                                )}
                            </View>
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 35),
                                }}
                            ></View>
                        </>
                    )}
                </>
            )}
            {!deviceContext.isPhone && (
                <View
                    style={{
                        height: getCorrectHeight(deviceContext, data[mainIndex]?.nested ? 70 : 35),
                    }}
                >
                    {show && (
                        <View>
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 35),
                                    flexDirection: 'row',
                                    backgroundColor: styling.general?.background_color,
                                }}
                            >
                                {deviceContext.isWebTV && (
                                    <Pressable
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => scrollLeftMain()}
                                        style={{
                                            height: getCorrectHeight(deviceContext, 25),
                                            width: getCorrectWidth(deviceContext, 25),
                                            margin: getCorrectHeight(deviceContext, 5),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                    </Pressable>
                                )}
                                {!deviceContext.isWebTV && (
                                    <View
                                        style={{
                                            height: getCorrectHeight(deviceContext, 25),
                                            width: getCorrectWidth(deviceContext, 25),
                                            margin: getCorrectHeight(deviceContext, 5),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                    </View>
                                )}
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 35),
                                        width: screenWidth - getCorrectWidth(deviceContext, 70), // - getCorrectWidth(deviceContext, global.menuWidth ?? 0) - (deviceContext.isWebTV && data.length > viewableItemsCount ? getCorrectWidth(deviceContext, 140) : 0),
                                    }}
                                >
                                    {data.length > 0 && (
                                        <FlashList
                                            ref={main}
                                            type="row"
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            extraData={mainIndex}
                                            data={data}
                                            estimatedItemSize={getCorrectHeight(deviceContext, 150)}
                                            numColumns={1}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            focusOptions={{ nextFocusDown: ['menu-item-active-1', 'grid-item-0'], forbiddenFocusDirections: ['right'] }}
                                            renderItem={renderItem}
                                            //wont work on nontv since can't add srollIndex, because items are not even width and sometimes fl scrolls to blank area
                                            initialFocusIndex={mainIndex}
                                            style={{
                                                flex: 1,
                                            }}
                                        />
                                    )}
                                </View>
                                {deviceContext.isWebTV && (
                                    <Pressable
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => scrollRightMain()}
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 25),
                                            width: getCorrectWidth(deviceContext, 25),
                                            margin: getCorrectHeight(deviceContext, 5),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                    </Pressable>
                                )}
                                {!deviceContext.isWebTV && (
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 25),
                                            width: getCorrectWidth(deviceContext, 25),
                                            margin: getCorrectHeight(deviceContext, 5),
                                            ...getPressableStyling(styling.components?.button?._id, appContext),
                                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                            {data[mainIndex]?.nested && (
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 35),
                                        flexDirection: 'row',
                                        backgroundColor: styling.general?.background_color,
                                    }}
                                >
                                    {deviceContext.isWebTV && (
                                        <Pressable
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => scrollLeftSub()}
                                            style={{
                                                height: getCorrectHeight(deviceContext, 25),
                                                width: getCorrectWidth(deviceContext, 25),
                                                margin: getCorrectHeight(deviceContext, 5),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                        </Pressable>
                                    )}
                                    {!deviceContext.isWebTV && (
                                        <View
                                            style={{
                                                height: getCorrectHeight(deviceContext, 25),
                                                width: getCorrectWidth(deviceContext, 25),
                                                margin: getCorrectHeight(deviceContext, 5),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                        </View>
                                    )}
                                    <View
                                        style={{
                                            height: getCorrectHeight(deviceContext, 35),
                                            width: screenWidth - getCorrectWidth(deviceContext, 70), // - getCorrectWidth(deviceContext, global.menuWidth ?? 0) - (deviceContext.isWebTV && data.length > viewableItemsCount ? getCorrectWidth(deviceContext, 140) : 0),
                                        }}
                                    >
                                        {data.length > 0 && (
                                            <FlashList
                                                ref={sub}
                                                type="row"
                                                onViewableItemsChanged={onViewableItemsChanged}
                                                extraData={subIndex}
                                                data={getSubMenuData()}
                                                estimatedItemSize={getCorrectHeight(deviceContext, 150)}
                                                numColumns={1}
                                                focusOptions={{ nextFocusDown: ['grid-item-0'] }}
                                                horizontal={true}
                                                showsHorizontalScrollIndicator={false}
                                                renderItem={renderItemSub}
                                                initialScrollIndex={0}
                                                style={{
                                                    flex: 1,
                                                }}
                                            />
                                        )}
                                    </View>
                                    {deviceContext.isWebTV && (
                                        <Pressable
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => scrollRightSub()}
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: getCorrectHeight(deviceContext, 25),
                                                width: getCorrectWidth(deviceContext, 25),
                                                margin: getCorrectHeight(deviceContext, 5),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faChevronRight} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                        </Pressable>
                                    )}
                                    {!deviceContext.isWebTV && (
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: getCorrectHeight(deviceContext, 25),
                                                width: getCorrectWidth(deviceContext, 25),
                                                margin: getCorrectHeight(deviceContext, 5),
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faChevronRight} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.4}></FontAwesomeIcon>
                                        </View>
                                    )}
                                </View>
                            )}
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};
export default Navigation_Menu;
