import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../application/styling/fontSizes';
import lang from '../../../application/languages/languages';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import ScreenWrapper from '../../wrapper';
import { AppContext } from '../../../application/context/appContext';
//import QRCode from 'react-native-qrcode-svg';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import Input from '../../../application/builder/components/input/input';
import { faTv } from '@fortawesome/pro-light-svg-icons';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import { getGlobals } from '../../../globals';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getPressableTextStyling, getPressableStyling, getInputStyling, getKeyboardStyling, getModalStyling } from '../../../application/builder/components/helpers/helper';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import { KeyboardContext } from '../../../application/context/keyboardContext';
import { ActivityIndicator } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [code, setCode] = React.useState('');
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const [loading, setLoading] = React.useState(false);

    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState('');

    React.useEffect(() => {}, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.CODE, duration);
            };
        }, [])
    );

    const checkCode = async () => {
        setLoading(true);
        await fetch(getGlobals().AppApiUrl + '/auth/check?code=' + code + '&userid=' + userContext.userId + '&pass=' + userContext.pass + '&serviceid=' + userContext.appId, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setLoading(false);
                if (response.code == 200) {
                    setSuccess(true);
                } else {
                    setError(response.code);
                }
            });
    };

    const closeError = () => {
        setError('');
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {success === false && error != '' && (
                <Modal
                    error={lang.getTranslation(userContext, 'error_code')}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.exit, appContext)}
                    type={'error'}
                    setShowModal={closeError}
                    submitChoice={closeError}
                ></Modal>
            )}
            {keyboardVisible && (
                <Keyboard
                    onCloseKeyboard={() => setKeyboardVisible(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.services.components?.keyboard._id, appContext)}
                    onChangText={setCode}
                    value={code}
                    focusOptions={{ nextFocusUp: 'submit-button' }}
                ></Keyboard>
            )}
            <View style={{ flex: 1 }}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                            fontWeight: appContext.application.authentication?.connect?.header.title_bold ? 'bold' : 'normal',
                            color: appContext.application.authentication?.connect?.header.title_color,
                        }}
                    >
                        {lang.getTranslation(userContext, 'connect_code')}
                    </Text>
                </View>
                {loading && success == false && error == '' && (
                    <View
                        style={{
                            flex: 4,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            paddingBottom: deviceContext.isPhone ? 100 : 10,
                        }}
                    >
                        <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                    </View>
                )}
                {!loading && success == false && (
                    <View style={{ flex: 4, justifyContent: 'center', alignItems: 'center' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                fontWeight: appContext.application.authentication?.connect?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.connect?.header.title_color,
                                marginBottom: getCorrectHeight(deviceContext, 20),
                            }}
                        >
                            {lang.getTranslation(userContext, 'enter_code')}
                        </Text>

                        <Input
                            value={code}
                            onChangText={(value) => setCode(value.toUpperCase())}
                            icon={faTv}
                            width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                            isSecure={false}
                            placeholder={lang.getTranslation(userContext, 'code')}
                            animation={getFocusStyling('Inputs', appContext)}
                            styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                            focusOptions={{ focusKey: 'code' }}
                            onFocus={() => {
                                if (keyboardVisible) {
                                    setKeyboardVisible(false);
                                }
                            }}
                            showKeyboard={() => {
                                setKeyboardVisible(true);
                            }}
                        ></Input>

                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => checkCode()}>
                            <View
                                style={{
                                    marginTop: getCorrectHeight(deviceContext, 20),
                                    width: getRealWidth(deviceContext) * 0.3,
                                    height: getCorrectHeight(deviceContext, 30),
                                    ...getPressableStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        ...getPressableTextStyling(appContext.application.authentication.connect.components?.button?._id, appContext),
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'submit')}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                )}
                {!loading && success == true && error == '' && (
                    <View
                        style={{
                            flex: 4,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            paddingBottom: deviceContext.isPhone ? 100 : 10,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                fontWeight: appContext.application.authentication?.connect?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.connect?.header.title_color,
                                marginBottom: getCorrectHeight(deviceContext, 20),
                            }}
                        >
                            {lang.getTranslation(userContext, 'success_code')}
                        </Text>
                    </View>
                )}
            </View>
        </ScreenWrapper>
    );
};
export default Screen_;
