import { ActivityIndicator, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getGlobals } from '../../../globals';
import { sendActionReport } from '../../../reporting/apis/action';
import { getUserIp } from '../../../application/data/account';
import { PlayerContext } from '../../../application/context/playerContext';
import { getFocusStyling, getInputStyling } from '../../../application/builder/components/helpers/helper';
import { BackHandler, KeyboardAvoidingView as KAV } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import HTMLRenderer from '../../../libs/react-native-render-html';
import Text from '../../../application/builder/components/text';
import { getModalStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import { getAuthenticationText } from '../helper/helper';
import { languageCodes } from '../../../application/languages/language_codes';
import closeApp from '../../content/home/helpers/index';

const KeyboardAvoidingView = withParentContextMapper(KAV);

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const [username, setUsername] = React.useState(userContext.userId);
    const [password, setPassword] = React.useState(userContext.pass);
    const [error, setError] = React.useState('');
    const [showExit, setShowExit] = React.useState(false);
    const showExitSync = React.useRef(false);
    const [loading, setLoading] = React.useState(false);
    const backCount = React.useRef(0);
    const disableNativeHardwareBackPress = () => {
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            if (['back', 'menu'].includes(eventType)) {
                if (appContext.application.settings.languages.length > 1) {
                    goBack();
                    return;
                } else {
                    backCount.current++;
                    if (backCount.current == 1) {
                        setShowExit(true);
                        showExitSync.current = true;
                    }
                    if (backCount.current == 2) {
                        if (deviceContext.isTizen) {
                            // @ts-expect-error
                            if (typeof tizen !== 'undefined') {
                                // @ts-expect-error
                                window.tizen.application.getCurrentApplication().exit();
                            }
                        } else if (deviceContext.isWebos) {
                            // @ts-expect-error
                            if (typeof webOS !== 'undefined') {
                                // @ts-expect-error
                                webOS.platformBack();
                            }
                        } else {
                            closeApp();
                        }
                    }
                }
            }
        } else {
            if (showExitSync.current == true) {
                cancelExit();
            }
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                if (appContext.application.settings.languages.length > 1) {
                    goBack();
                    return;
                } else {
                    backCount.current++;
                    if (backCount.current == 1) {
                        setShowExit(true);
                        showExitSync.current = true;
                    }
                    if (backCount.current == 2) {
                        if (deviceContext.isTizen) {
                            // @ts-expect-error
                            if (typeof tizen !== 'undefined') {
                                // @ts-expect-error
                                window.tizen.application.getCurrentApplication().exit();
                            }
                        } else if (deviceContext.isWebos) {
                            // @ts-expect-error
                            if (typeof webOS !== 'undefined') {
                                // @ts-expect-error
                                webOS.platformBack();
                            }
                        } else {
                            closeApp();
                        }
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const cancelExit = () => {
        setShowExit(false);
        showExitSync.current = false;
        backCount.current = 0;
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.REGISTER, duration);
            };
        }, [])
    );

    const loginPaid = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Login' }],
            })
        );
    };
    const loginGuest = () => {
        setLoading(true);
        if (userContext.pass != undefined && userContext.userId != undefined) {
            loginUser(userContext.userId, userContext.pass);
        } else {
            getUserLocation();
        }
    };

    const goBack = () => {
        if (appContext.application.settings.languages.length > 1) {
            userContext.setSelectedLanguage('');
            userContext.setSelectedLanguageCode('');
            userContext.setAutoLogin(false);
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Language' }],
                })
            );
        } else {
        }
    };

    const loginUser = async (userid, pass) => {
        sendActionReport(appContext, deviceContext, userContext, actionType.LOGIN);
        var result = await getUserIp(userid, pass, appContext, userContext, deviceContext, userContext, playerContext, false);
        if (result != 'success') {
            getUserLocation();
        } else {
            setLoading(true);
            startContentLoader();
        }
    };

    const closeError = () => {
        setError('');
    };

    const startContentLoader = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Loader' }],
            })
        );
    };

    const getUserLocation = async () => {
        try {
            let response = await fetch('https://cloudtv.akamaized.net/location.php?_=' + moment().unix());
            let data = await response.json();
            if (data != undefined) {
                var country = languageCodes.find((l) => l.code == data.country).name != undefined ? languageCodes.find((l) => l.code == data.country).name : 'United States';
                var city = data.city.toLowerCase();
                city = data.city.charAt(0).toUpperCase() + city.slice(1);
                userContext.setCity(city);
                userContext.setCountry(country);
                userContext.setLatitude(data.latitude);
                userContext.setLongitude(data.longitude);
                userContext.setIso2(data.country);
                return await registerUser(city, country, data.country);
            } else {
                userContext.setCity('New York');
                userContext.setCountry('United States');
                userContext.setLatitude('40.7128');
                userContext.setLongitude('-74.0060');
                userContext.setIso2('us');
                return await registerUser('New York', 'United States', 'US');
            }
        } catch (error) {
            userContext.setCity('New York');
            userContext.setCountry('United States');
            userContext.setLatitude('40.7128');
            userContext.setLongitude('-74.0060');
            userContext.setIso2('us');
            return await registerUser('New York', 'United States', 'US');
        }
    };
    const registerUser = async (city, country, iso2) => {
        var len = deviceContext.uniqueId.length;
        var user = deviceContext.uniqueId.substring(0, len / 2);
        var pass = deviceContext.uniqueId.substring(len / 2);

        fetch(
            getGlobals().AppApiUrl +
                '/register/register?client=' +
                encodeURIComponent(appContext.application.client) +
                '&deployment=' +
                encodeURIComponent(appContext.application.deployment) +
                '&service=' +
                encodeURIComponent(appContext.application.branding.service),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cart_id: appContext.cart._id,
                    email: 'anonymous@tvms.io',
                    mobile: '0031612345678',
                    firstname: 'Anonymous',
                    lastname: 'User',
                    password: pass,
                    userid: user,
                    model: deviceContext.model,
                    uuid: deviceContext.uniqueId,
                    city: city,
                    country: country,
                    number: '0031201234567',
                    iso2: iso2,
                }),
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.success == true) {
                    userContext.setUserId(user);
                    utils.storeJson('UserID', user);
                    setUsername(user);

                    userContext.setPass(pass);
                    utils.storeJson('Pass', pass);
                    setPassword(pass);

                    userContext.setAutoLogin(true);
                    utils.storeJson('AutoLogin', true);

                    loginUser(user, pass);
                } else {
                }
            })
            .catch((error) => {
                //error
            });
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {error != '' && <Modal error={error} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'error'} setShowModal={closeError} submitChoice={closeError}></Modal>}
            {showExit && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'exit'} submitChoice={cancelExit} timerTime={3}></Modal>}
            {appContext.application.settings.enable_upsell == true && (
                <KeyboardAvoidingView behavior={deviceContext.isApple ? 'padding' : 'height'} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ flex: 1, width: getRealWidth(deviceContext) }}>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: appContext.application.authentication?.login?.header.title_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.login?.header.title_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'anonymous')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, appContext.application.authentication?.login?.header.sub_size),
                                    fontWeight: appContext.application.authentication?.login?.header.sub_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.login?.header.sub_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'anonymouswhy').toUpperCase()}
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 4,
                                flexDirection: appContext.application.authentication?.login?.translations?.translations?.length > 0 && !deviceContext.isKaiOs ? 'row' : 'column',
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Pressable
                                    style={{
                                        marginTop: 25,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => loginPaid()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'continue_as_paid')}
                                        </Text>
                                    </View>
                                </Pressable>

                                <Pressable
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => loginGuest()}
                                >
                                    <View>
                                        {!loading && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'continue_as_guest')}
                                            </Text>
                                        )}
                                        {loading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext).color} size={'small'}></ActivityIndicator>}
                                    </View>
                                </Pressable>
                                {appContext.application.settings.languages.length > 1 && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => goBack()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'back')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                            </View>
                            {appContext.application.authentication?.login?.text.length > 0 && (
                                <View
                                    style={{
                                        borderColor: appContext.application.authentication?.login?.header.title_color,
                                        borderWidth: 1,
                                        marginVertical: 50,
                                    }}
                                ></View>
                            )}
                            {appContext.application.authentication?.login?.translations?.translations?.length > 0 && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <View
                                        style={{
                                            borderRadius: getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext).borderRadius,

                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.4),
                                            marginVertical: 50,
                                        }}
                                    >
                                        <HTMLRenderer
                                            html={getAuthenticationText(appContext.application.authentication?.login?.translations?.translations, userContext)}
                                            contentWidth={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                        />
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                </KeyboardAvoidingView>
            )}
            {appContext.application.settings.enable_upsell == false && (
                <View style={{ flex: 4, flexDirection: 'row' }}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View style={{ flex: 1, justifyContent: 'center' }}></View>
                        <View style={{ flex: 1 }}>
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: getRealWidth(deviceContext) }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'center' }}></View>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
