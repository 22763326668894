import moment from 'moment';
import { manageProfiles } from '../../../../data/account';
import { reloadEpgChannels, reloadEpgRadio } from '../../../../data/content';
import { findCamIndexAndCategoryIndex, findChannelIndexAndCategoryIndex, findRadioIndexAndCategoryIndex } from '../../players/helpers/functions';
import { getRealWidth } from '../../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getCorrectLeftMargin } from '../../../../styling/appLeftMargin';

export const getRailsWidth = (deviceContext, appContext, styling, screenName) => {
    if (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs) {
        return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 5);
    } else {
        var newWidth =
            getRealWidth(deviceContext) -
            getCorrectWidth(deviceContext, styling.placement.padding * 2) -
            getCorrectWidth(deviceContext, global.menuWidth) -
            getCorrectWidth(deviceContext, styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' ? 10 : 0) -
            getCorrectLeftMargin(deviceContext, appContext, screenName);
        return newWidth;
    }
};
export const getRailsHeight = (deviceContext, appContext, styling, height) => {
    if (styling.placement.height < height * 2) {
        return null;
    } else {
        var test = getCorrectHeight(deviceContext, styling.placement.height + styling.placement.padding * 2 + appContext.application.theme.rails_bottom_margin + appContext.application.theme.rails_top_margin);
        return test;
    }
};
export const extraTopBottomMargin = (deviceContext, styling) => {
    if (styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent') {
        return getCorrectHeight(deviceContext, styling.placement.padding);
    } else {
        return 0;
    }
};

export const checkIfUserGeneratedListAndEmpty = (styling, userContext, contentContext, appContext) => {
    if (styling.content.type == 'Watched') {
        var items = getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
        if (items != undefined && items.length > 0) {
            return false;
        } else {
            return true;
        }
    } else if (styling.content.type == 'Favorites') {
        var items = getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
        if (items != undefined && items.length > 0) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};
export const checkIfUserGeneratedListAndEmptyVirtualized = (styling, userContext, contentContext, appContext) => {
    if (styling.content.type == 'Watched') {
        var items = getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
        if (items != undefined && items.length > 0) {
            return false;
        } else {
            return true;
        }
    } else if (styling.content.type == 'Favorites') {
        var items = getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext) as any;
        if (items != undefined && items.length > 0) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};
export const getDetailsScreen = (styling, userContext, contentContext, appContext, navigation, item, settingsContext) => {
    storeSearch(styling, userContext, contentContext, appContext);
    if (styling.content.play_directly && !item.payperview?.enable) {
        if (styling.content.list?.type == 'Channels') {
            playChannel(contentContext, appContext, userContext, item, navigation, settingsContext);
        }
        if (styling.content.list?.type == 'Radio') {
            playRadio(contentContext, appContext, userContext, item, navigation);
        }
        if (styling.content.list?.type == 'Cams') {
            playCams(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Movies') {
            playMovie(contentContext, item, navigation, settingsContext);
        }
        if (styling.content.list?.type == 'Shorts') {
            playShort(contentContext, item, navigation, settingsContext);
        }
        if (styling.content.list?.type == 'Music') {
            playAlbum(contentContext, item, navigation, 0);
        }
        if (styling.content.list?.type == 'Podcasts') {
            playPodcast(contentContext, item, navigation, 0);
        }
        if (styling.content.list?.type == 'Games') {
            playGame(contentContext, item, navigation, 0, []);
        }
    } else {
        if (styling.content.list?.type == 'Channels') {
            playChannel(contentContext, appContext, userContext, item, navigation, settingsContext);
        }
        if (styling.content.list?.type == 'Radio') {
            playRadio(contentContext, appContext, userContext, item, navigation);
        }
        if (styling.content.list?.type == 'Cams') {
            playCams(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Series') {
            getDetailsSeries(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Movies') {
            getDetailsMovie(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Courses') {
            getDetailsCourse(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Shorts') {
            getDetailsShort(contentContext, item, navigation);
        }
        if (styling.content.list?.type == 'Music') {
            getDetailsAlbum(contentContext, item, navigation, 0);
        }
        if (styling.content.list?.type == 'Podcasts') {
            getDetailsPodcast(contentContext, item, navigation, 0);
        }
        if (styling.content.list?.type == 'Games') {
            playGame(contentContext, item, navigation, 0, []);
        }
    }
};

export const getSortingByRecommendation = (items, recommendations, skipSort?) => {
    items.forEach((item, index) => {
        if (item.tags == undefined || items.tags == null || item.tags == '') {
            items[index].recommended = 0;
        } else {
            var tags = item.tags.split(', ');
            if (tags.length == 0) {
                items[index].recommended = 0;
            } else {
                tags.forEach((tag) => {
                    var test = recommendations.recommendations.find((r) => r.tag == tag);
                    if (test == undefined) {
                        items[index].recommended = 0;
                    } else {
                        if (items[index].recommended == undefined) {
                            items[index].recommended = test.count;
                        } else {
                            if (items[index].recommended < test.count) {
                                items[index].recommended = test.count;
                            }
                        }
                    }
                });
            }
        }
    });
    if (skipSort == undefined) {
        items = items.sort((a, b) => b.recommended - a.recommended);
    }
    return items;
};

export const getFavoriteEmptyTextForRails = (type) => {
    if (type == 'Channels') {
        return 'favorites_channel_empty';
    }
    if (type == 'Cams') {
        return 'favorites_cam_empty';
    }
    if (type == 'Music') {
        return 'favorites_album_empty';
    }
    if (type == 'Podcasts') {
        return 'favorites_podcast_empty';
    }
    if (type == 'Shorts') {
        return 'favorites_short_empty';
    }
    if (type == 'Courses') {
        return 'favorites_course_empty';
    }
    if (type == 'Series') {
        return 'favorites_series_empty';
    }
    if (type == 'Movies') {
        return 'favorites_movie_empty';
    }
};
export const getWatchlistEmptyTextForRails = (type) => {
    if (type == 'Channels') {
        return 'watchlist_channel_empty';
    }
    if (type == 'Cams') {
        return 'watchlist_cam_empty';
    }
    if (type == 'Music') {
        return 'watchlist_album_empty';
    }
    if (type == 'Podcasts') {
        return 'watchlist_podcast_empty';
    }
    if (type == 'Shorts') {
        return 'watchlist_short_empty';
    }
    if (type == 'Courses') {
        return 'watchlist_course_empty';
    }
    if (type == 'Series') {
        return 'watchlist_series_empty';
    }
    if (type == 'Movies') {
        return 'watchlist_movie_empty';
    }
};
export const checkIfAccessToContent = (appContext, type) => {
    if (appContext.application.menus?.find((m) => m.name == type) == undefined) {
        return false;
    } else {
        return true;
    }
};
export const getFavoritesForRails = (type, userContext, contentContext, appContext) => {
    if (type == 'Channels' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.channels;
        return items;
        //return getChannelsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Cams' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.cams;
        return items;
        /// return getCamsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Music' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.albums;
        return items;
        // return getAlbumsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Podcasts' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.podcasts;
        return items;
        //return getPodcastsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Shorts' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.shorts;
        return items;
        // return items; // getShortsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Courses' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.courses;
        return items;
        // return getCoursesFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Series' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.series;
        return items;
        // return getSeriesFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Movies' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.favorites.movies;
        return items;
        // return getMoviesFromSubscriptionAndRating(contentContext, items);
    }
    return undefined;
};

export const getWatchlistForRails = (type, userContext, contentContext, appContext) => {
    if (type == 'Channels' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.channels;
        return items;
        //return getChannelsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Cams' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.cams;
        return items;
        //return getCamsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Music' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.albums;
        return items;
        //return getAlbumsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Podcasts' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.podcasts;
        return items;
        //return getPodcastsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Courses' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.courses;
        return items;
        //return getCoursesFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Shorts' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.shorts;
        return items;
        // return items; //getShortsFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Series' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.series;
        items = items.filter((i) => i._id != undefined);
        return items;
        // return getSeriesFromSubscriptionAndRating(contentContext, items);
    }
    if (type == 'Movies' && checkIfAccessToContent(appContext, type)) {
        var items = userContext.profile.watchings.movies;
        return items;
        // return getMoviesFromSubscriptionAndRating(contentContext, items);
    }
    return undefined;
};

export const getChannelsFromSubscriptionAndRating = (contentContext, channelsIn) => {
    var dataOut = [] as any;
    channelsIn.forEach((channelIn) => {
        contentContext.channels.categories.forEach((category) => {
            category.channels.forEach((channel) => {
                if (channelIn?._id == channel._id) {
                    var test = dataOut.find((c) => c._id == channelIn._id);
                    if (test == undefined) {
                        dataOut.push(channel);
                    }
                }
            });
        });
    });
    return dataOut;
};
// export const getChannelFromSubscription = (contentContext, _id) => {
//     var dataOut = [] as any;
//     contentContext.channels.categories.forEach((category) => {
//         category.channels.forEach((channel) => {
//             if (_id == channel._id) {
//                 var test = dataOut.find((c) => c._id == _id);
//                 if (test == undefined) {
//                     return test;
//                 }
//             }
//         });
//     });
// };
export const getRadiosFromSubscriptionAndRating = (contentContext, radios) => {
    var dataOut = [] as any;
    radios.forEach((radio) => {
        contentContext.radios.categories.forEach((category) => {
            category.radios.forEach((radio_) => {
                if (radio._id == radio_._id) {
                    var test = dataOut.find((c) => c._id == radio._id);
                    if (test == undefined) {
                        dataOut.push(radio_);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getCamsFromSubscriptionAndRating = (contentContext, cams) => {
    var dataOut = [] as any;
    cams.forEach((cam) => {
        contentContext.cams.categories.forEach((category) => {
            category.cams.forEach((cam_) => {
                if (cam._id == cam_._id) {
                    var test = dataOut.find((c) => c._id == cam._id);
                    if (test == undefined) {
                        dataOut.push(cam_);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getAlbumsFromSubscriptionAndRating = (contentContext, albums) => {
    var dataOut = [] as any;
    albums.forEach((album) => {
        contentContext.albums.categories.forEach((category) => {
            category.albums.forEach((album_) => {
                if (album._id == album_._id) {
                    var test = dataOut.find((a) => a._id == album._id);
                    if (test == undefined) {
                        dataOut.push(album_);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getPodcastsFromSubscriptionAndRating = (contentContext, podcasts) => {
    var dataOut = [] as any;
    podcasts.forEach((podcast) => {
        contentContext.podcasts.categories.forEach((category) => {
            category.podcasts.forEach((podcast_) => {
                if (podcast._id == podcast_._id) {
                    var test = dataOut.find((a) => a._id == podcast._id);
                    if (test == undefined) {
                        dataOut.push(podcast_);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getMoviesFromSubscriptionAndRating = (contentContext, movies) => {
    var dataOut = [] as any;
    movies.forEach((movie) => {
        contentContext.movies.categories.forEach((category) => {
            category.movies.forEach((movie_) => {
                if (movie._id == movie_._id) {
                    var test = dataOut.find((m) => m._id == movie._id);
                    if (test == undefined) {
                        dataOut.push(movie);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getShortsFromSubscriptionAndRating = (contentContext, shorts) => {
    if (shorts == undefined) {
        return [];
    }
    var dataOut = [] as any;
    shorts.forEach((short) => {
        contentContext.shorts.categories.forEach((category) => {
            category.shorts.forEach((short_) => {
                if (short._id == short._id) {
                    var test = dataOut.find((m) => m._id == short._id);
                    if (test == undefined) {
                        dataOut.push(short_);
                    }
                }
            });
        });
    });
    return dataOut;
};

export const getSeriesFromSubscriptionAndRating = (contentContext, series) => {
    var dataOut = [] as any;
    series.forEach((serie) => {
        contentContext.series.categories.forEach((category) => {
            category.series.forEach((serie_) => {
                if (serie._id == serie_._id) {
                    var test = dataOut.find((m) => m._id == serie._id);
                    if (test == undefined) {
                        dataOut.push(serie);
                    }
                }
            });
        });
    });
    return dataOut;
};
export const getCoursesFromSubscriptionAndRating = (contentContext, courses) => {
    var dataOut = [] as any;
    courses.forEach((course) => {
        contentContext.courses.categories.forEach((category) => {
            category.courses.forEach((course_) => {
                if (course._id == course_._id) {
                    var test = dataOut.find((m) => m._id == course._id);
                    if (test == undefined) {
                        dataOut.push(course_);
                    }
                }
            });
        });
    });
    return dataOut;
};
export const getGamesFromSubscriptionAndRating = (contentContext, games) => {
    var dataOut = [] as any;
    games.forEach((game) => {
        contentContext.games.categories.forEach((category) => {
            category.games.forEach((game_) => {
                if (game._id == game_._id) {
                    var test = dataOut.find((m) => m._id == game._id);
                    if (test == undefined) {
                        dataOut.push(game_);
                    }
                }
            });
        });
    });
    return dataOut;
};

////
export const getDetailsChannel = (contentContext, channel, navigation) => {
    var indexes = findChannelIndexAndCategoryIndex(contentContext.channels.categories, channel._id);
    if (contentContext.epgTv[0] != undefined) {
        var epg = contentContext.epgTv?.find((e) => e.i == channel._id)?.epg;
        var currentIndex = 0;
        if (epg != undefined) {
            var currentTime = moment().unix();
            currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        }
        contentContext.setChannelData({
            ...contentContext.channelData,
            channel: channel,
            program: epg[currentIndex],
            channelCategoryIndex: indexes.category_index,
            channelListCategoryIndex: indexes.category_index,
            channelIndex: indexes.channel_index,
            channelList: contentContext.channels.categories[indexes.category_index].channels,
            programIndex: currentIndex,
            programList: epg,
        });
        navigation && navigation.push('Program');
    }
};
export const playChannelIndex = async (contentContext, appContext, userContext, channel, index, navigation, settingsContext) => {
    if (contentContext.epgTv[0] != undefined) {
        var epg = contentContext.epgTv?.find((e) => e.i == channel._id)?.epg;
        var currentIndex = 0;
        if (epg != undefined) {
            var currentTime = moment().unix();
            currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        } else {
            await reloadEpgChannels(appContext.application, contentContext, userContext);
        }
        contentContext.setChannelData({
            ...contentContext.channelData,
            channel: channel,
            program: epg[currentIndex],
            channelIndex: index,
            programIndex: currentIndex,
            programList: epg,
            channelListList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
            channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
        });
    } else {
        contentContext.setChannelData({
            ...contentContext.channelData,
            channel: channel,
            program: [],
            channelIndex: index,
            programIndex: currentIndex,
            programList: [],
            channelListList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
            channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,
        });
    }
    if (navigation != null) {
        settingsContext.setShowMainMenu(false);
        navigation && navigation.navigate('Channel_Player');
    }
};
export const playChannel = async (contentContext, userContext, appContext, channel, navigation, settingsContext, onNavigation?: () => void) => {
    var indexes = findChannelIndexAndCategoryIndex(contentContext.channels.categories, channel._id);

    if (contentContext.epgTv[0] != undefined) {
        var epg = contentContext.epgTv?.find((e) => e.i == channel._id)?.epg;
        var currentIndex = 0;

        if (indexes.category_index != undefined) {
            if (epg != undefined) {
                var currentTime = moment().unix();
                currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
            } else {
                await reloadEpgChannels(appContext.application, contentContext, userContext);
            }
            contentContext.setChannelData({
                ...contentContext.channelData,
                channel: channel,
                program: epg[currentIndex],
                channelCategoryIndex: indexes.category_index,
                channelListCategoryIndex: indexes.category_index,
                localCategoryIndex: 0,
                channelIndex: indexes.channel_index,
                channelList: contentContext.channels.categories[indexes.category_index].channels,
                programIndex: currentIndex,
                programList: epg,
            });
            settingsContext.setShowMainMenu(false);
            if (onNavigation) {
                onNavigation();
            } else if (navigation) navigation.navigate('Channel_Player');
        }
    } else {
        if (indexes.category_index != undefined) {
            contentContext.setChannelData({
                channel: channel,
                program: [],
                channelCategoryIndex: indexes.category_index,
                channelListCategoryIndex: indexes.category_index,
                localCategoryIndex: 0,
                channelIndex: indexes.channel_index,
                channelList: contentContext.channels.categories[indexes.category_index].channels,
                programIndex: 0,
                programList: [],
            });
            settingsContext.setShowMainMenu(false);
            if (onNavigation) onNavigation();
            else if (navigation) navigation.navigate('Channel_Player');
        }
    }
};

export const playCatchupTVIndex = async (contentContext, channel, navigation, startType, settingsContext, programIndex) => {
    var indexes = findChannelIndexAndCategoryIndex(contentContext.channels.categories, channel._id);
    if (contentContext.epgTv[0] != undefined) {
        var epg = contentContext.epgTv?.find((e) => e.i == channel._id)?.epg;
        contentContext.setChannelData({
            ...contentContext.channelData,
            channel: channel,
            program: epg != undefined ? epg[programIndex] : [],
            channelCategoryIndex: indexes.category_index,
            channelListCategoryIndex: indexes.category_index,
            localCategoryIndex: 0,
            channelIndex: indexes.channel_index,
            channelList: contentContext.channels.categories[indexes.category_index].channels,
            programIndex: programIndex,
            programList: epg,
        });
        settingsContext.setShowMainMenu(false);
        navigation &&
            navigation.navigate('CatchupTV_Player', {
                startType: startType,
            });
    }
};
export const playRadioIndex = async (contentContext, appContext, userContext, radio, index, navigation) => {
    if (contentContext.epgRadio[0] != undefined) {
        var epg = contentContext.epgRadio.find((e) => e.i == radio._id)?.epg;
        var currentIndex = 0;
        if (epg != undefined) {
            var currentTime = moment().unix();
            currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        } else {
            await reloadEpgRadio(appContext.application, contentContext, userContext);
        }
        if (epg[currentIndex] != undefined) {
            contentContext.setRadioData({
                ...contentContext.radioData,
                radio: radio,
                radioList: contentContext.radios.categories[contentContext.radioData.radioCategoryIndex].radios,
                program: epg[currentIndex],
                radioIndex: index,
                programIndex: currentIndex,
                programList: epg,
            });
        }
    } else {
        contentContext.setRadioData({
            ...contentContext.radioData,
            radio: radio,
            program: [],
            radioIndex: index,
            programIndex: currentIndex,
            programList: [],
            radioList: contentContext.radios.categories[contentContext.radioData.radioCategoryIndex].radios,
        });
    }
    if (navigation != null) {
        navigation && navigation.navigate('Radio_Player');
    }
};
export const playRadio = async (contentContext, appContext, userContext, radio, navigation) => {
    var indexes = findRadioIndexAndCategoryIndex(contentContext.radios.categories, radio._id);
    if (contentContext.epgRadio[0] != undefined) {
        var epg = contentContext.epgRadio.find((e) => e.i == radio._id)?.epg;
        var currentIndex = 0;
        if (epg != undefined) {
            var currentTime = moment().unix();
            currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
        } else {
            await reloadEpgRadio(appContext.application, contentContext, userContext);
        }
        contentContext.setRadioData({
            ...contentContext.radioData,
            radio: radio,
            program: epg[currentIndex],
            localCategoryIndex: 0,
            radioCategoryIndex: indexes.category_index,
            radioIndex: indexes.radio_index,
            radioList: contentContext.radios.categories[indexes.category_index].radios,
            programIndex: currentIndex,
            programList: epg,
        });
        navigation && navigation.navigate('Radio_Player');
    } else {
        contentContext.setRadioData({
            ...contentContext.radioData,
            radio: radio,
            program: [],
            localCategoryIndex: 0,
            radioCategoryIndex: indexes.category_index,
            radioIndex: indexes.radio_index,
            radioList: contentContext.radios.categories[indexes.category_index].radio,
            programIndex: 0,
            programList: [],
        });
        navigation && navigation.navigate('Radio_Player');
    }
};
export const playCamsIndex = (contentContext, cam, index, navigation) => {
    var epg = contentContext.epgCam;
    var currentIndex = 0;
    if (epg != undefined) {
        var currentTime = moment().unix();
        currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
    }
    contentContext.setCamData({
        ...contentContext.camData,
        cam: cam,
        program: {
            i: 0,
            n: '',
            d: '',
            s: moment().startOf('hour').unix(),
            e: moment().startOf('hour').add('hour', 1).unix(),
            m: '',
            b: false,
            p: '',
            t: '',
        },
        camIndex: index,
        programIndex: currentIndex,
        programList: epg,
        camList: contentContext.cams.categories[contentContext.camData.camCategoryIndex].cams,
    });

    if (navigation != null) {
        navigation && navigation.navigate('Cam_Player');
    }
};
export const playCams = (contentContext, cam, navigation) => {
    var indexes = findCamIndexAndCategoryIndex(contentContext.cams.categories, cam._id);
    var epg = contentContext.epgCam;
    var currentIndex = 0;
    if (epg != undefined) {
        var currentTime = moment().unix();
        currentIndex = epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
    }
    contentContext.setCamData({
        ...contentContext.camData,
        cam: cam,
        program: {
            i: 0,
            n: '',
            d: '',
            s: moment().startOf('hour').unix(),
            e: moment().startOf('hour').add('hour', 1).unix(),
            m: '',
            b: false,
            p: '',
            t: '',
        },
        camCategoryIndex: indexes.category_index,
        camIndex: indexes.channel_index,
        localCategoryIndex: 0,
        camList: contentContext.cams.categories[indexes.category_index].cams,
        programIndex: currentIndex,
        programList: epg,
    });
    navigation && navigation.navigate('Cam_Player');
};
export const getDetailsAlbum = (contentContext, album, navigation, index) => {
    contentContext.setAlbumData({
        ...contentContext.albumData,
        album: album,
        albumList: contentContext.albums.categories[contentContext.albumData.albumCategoryIndex].albums,
        albumIndex: index,
    });
    navigation && navigation.push('Album');
};
export const playAlbum = (contentContext, song, navigation, index?) => {
    contentContext.setAlbumData({
        ...contentContext.albumData,
        localCategoryIndex: 0,
        songIndex: index,
        songs: contentContext.albumData.album.songs,
        song: song,
    });
    navigation && navigation.navigate('Album_Player');
};
export const playAlbumIndex = (contentContext, album, navigation, index?) => {
    contentContext.setAlbumData({
        ...contentContext.albumData,
        localCategoryIndex: 0,
        songIndex: 0,
        albumIndex: index,
        album: album,
        songs: album.songs,
        song: album.songs[0].song ?? album.songs[0],
    });
    navigation && navigation.navigate('Album_Player');
};
export const getDetailsPodcast = (contentContext, podcast, navigation, index) => {
    contentContext.setPodcastData({
        ...contentContext.podcastData,
        podcast: podcast,
        podcastList: contentContext.podcasts.categories[contentContext.podcastData.podcastCategoryIndex].episodes,
        podcastIndex: index,
    });
    navigation && navigation.push('Podcast');
};
export const playPodcast = (contentContext, episode, navigation, index?) => {
    contentContext.setPodcastData({
        ...contentContext.podcastData,
        localCategoryIndex: 0,
        episodes: contentContext.podcastData.podcast.episodes,
        episodeIndex: index,
        episode: episode,
    });
    navigation && navigation.navigate('Podcast_Player');
};
export const playPodcastIndex = (contentContext, podcast, navigation, index?) => {
    contentContext.setPodcastData({
        ...contentContext.podcastData,
        localCategoryIndex: 0,
        podcast: podcast,
        podcastIndex: index,
        episodes: podcast.episodes,
        episodeIndex: 0,
        episode: podcast.episodes[0].episode,
    });
    navigation && navigation.navigate('Podcast_Player');
};
export const getDetailsSeriesIndex = (contentContext, series, navigation, seriesIndex?, categoryIndex?) => {
    contentContext.setSeriesData({
        ...contentContext.seriesData,
        series: series,
        seriesIndex: seriesIndex,
        seriesCategoryIndex: categoryIndex,
        seasonCategoryIndex: 0,
    });
    navigation && navigation.push('Seasons');
};
export const getDetailsSeries = (contentContext, series, navigation) => {
    // var indexes = findSeriesIndexAndCategoryIndex(contentContext.series.categories, series._id);
    contentContext.setSeriesData({
        ...contentContext.seriesData,
        series: series,
        seasonCategoryIndex: 0,
    });
    navigation && navigation.push('Seasons');
};

export const playSeries = (contentContext, episode, navigation, index, list, settingsContext, seasonIndex) => {
    contentContext.setSeriesData({
        ...contentContext.seriesData,
        localCategoryIndex: seasonIndex,
        seasonCategoryIndex: seasonIndex,
        episodeList: list,
        episode: episode,
        episodeIndex: index,
    });
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Series_Player');
};

export const playGame = (contentContext, game, navigation, index, list) => {
    contentContext.setGameData({
        ...contentContext.gameData,
        localCategoryIndex: 0,
        game: game,
        gameIndex: index,
        games: list,
    });
    navigation && navigation.navigate('Game_Webview');
};
export const getDetailsCourseIndex = (contentContext, course, navigation, courseIndex?, categoryIndex?) => {
    contentContext.setCourseData({
        ...contentContext.courseData,
        course: course,
        courseCategoryIndex: categoryIndex,
        courseIndex: courseIndex,
    });
    navigation && navigation.push('Course');
};
export const getDetailsCourse = (contentContext, course, navigation) => {
    contentContext.setCourseData({
        ...contentContext.courseData,
        course: course,
    });
    navigation && navigation.push('Course');
};
export const playCourse = (contentContext, lesson, navigation, index, list, settingsContext) => {
    contentContext.setCourseData({
        ...contentContext.courseData,
        localCategoryIndex: 0,
        lesson: lesson,
        lessonIndex: index,
        lessonList: list,
    });
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Course_Player');
};
export const getDetailsShortIndex = (contentContext, short, navigation, shortIndex?, categoryIndex?) => {
    contentContext.setShortData({
        ...contentContext.shortData,
        short: short,
        shortCategoryIndex: categoryIndex,
        shortList: contentContext.shorts.categories[contentContext.shortData.shortCategoryIndex].shorts,
        shortIndex: shortIndex,
    });
    navigation && navigation.push('Short');
    //navigation && navigation.push('Short_Player');
};
export const getDetailsShort = (contentContext, short, navigation) => {
    contentContext.setShortData({
        ...contentContext.shortData,
        short: short,
    });
    navigation && navigation.push('Short');
};
export const playShort = (contentContext, short, navigation, settingsContext) => {
    contentContext.setShortData({
        ...contentContext.shortData,
        localCategoryIndex: 0,
        short: short,
        shortCategoryIndex: 0,
        shortIndex: 0,
    });
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Short_Player');
};
export const getDetailsMovieIndex = (contentContext, movie, navigation, movieIndex?, categoryIndex?) => {
    contentContext.setMovieData({
        ...contentContext.movieData,
        movie: movie,
        movieIndex: movieIndex,
        movieCategoryIndex: categoryIndex,
    });
    navigation && navigation.push('Movie');
};
export const getDetailsMovie = (contentContext, movie, navigation) => {
    contentContext.setMovieData({
        ...contentContext.movieData,
        movie: movie,
    });
    navigation && navigation.push('Movie');
};
export const playMovie = (contentContext, movie, navigation, settingsContext) => {
    contentContext.setMovieData({
        ...contentContext.movieData,
        localCategoryIndex: 0,
        movie: movie,
        movieCategoryIndex: 0,
        movieIndex: 0,
    });
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Movie_Player');
};
export const getDetailsRecording = (contentContext, channel, program, navigation, index) => {
    contentContext.setRecordingData({
        recordingIndex: index,
        program: program,
        channel: channel,
    });
    navigation && navigation.navigate('Recording');
};
export const playRecording = (navigation, settingsContext) => {
    settingsContext.setShowMainMenu(false);
    navigation && navigation.navigate('Recording_Player');
};

export const storeSearch = (styling, userContext, contentContext, appContext) => {
    if (styling.content.type == 'Search' || styling.content.type == 'Search External') {
        var searches = userContext.profile.searches ?? [];
        var test = searches?.find((t) => t == contentContext.search);
        if (test == undefined) {
            searches.unshift(contentContext.search);
            if (searches.length > 10) {
                searches.slice(0, -1);
            }
            manageProfiles(userContext, userContext.profile, 'searches', searches, appContext.application);
        }
    }
};

export const EMPTY_DATA_ARRAY = [{ type: 'empty' }, { type: 'empty' }, { type: 'empty' }];
export const EMPTY_DATA_ARRAY_SQUARE = [{ type: 'empty' }, { type: 'empty' }, { type: 'empty' }, { type: 'empty' }, { type: 'empty' }, { type: 'empty' }];
export const EMPTY_DATA_ARRAY_RECT = [{ type: 'empty' }, { type: 'empty' }, { type: 'empty' }, { type: 'empty' }];
export const LOADER_TIMEOUT_MS = 500;
