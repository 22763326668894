import * as React from 'react';
import { View, FlashList, CreateListRenderItemInfo, FocusContext, setFocus, CoreManager } from '@scriptx-com/xtv-toolkit';
import Widescreen from '../shared/widescreen';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import {
    checkIfAccessToContent,
    checkIfUserGeneratedListAndEmpty,
    EMPTY_DATA_ARRAY,
    extraTopBottomMargin,
    getCamsFromSubscriptionAndRating,
    getChannelsFromSubscriptionAndRating,
    getCoursesFromSubscriptionAndRating,
    getDetailsScreen,
    getFavoritesForRails,
    getGamesFromSubscriptionAndRating,
    getRadiosFromSubscriptionAndRating,
    getRailsHeight,
    getRailsWidth,
    getSeriesFromSubscriptionAndRating,
    getSortingByRecommendation,
    getWatchlistForRails,
} from './helpers/helper';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Pressable from '../../components/pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faRectangleHistory } from '@fortawesome/pro-light-svg-icons';
import Text from '../../components/text';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../../components/helpers/helper';
import Modal from '../../components/modal/modal';
import { favoriteStatus, manageFavorite, manageWatching, watchingStatus } from '../../../data/account';
import lang from '../../../../application/languages/languages';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { isFactorMobile, isPlatformAndroid, isPlatformIos, isPlatformTvos } from '@rnv/renative';
import { MediaCache } from 'react-native-theoplayer';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { SettingsContext } from '../../../context/settingsContext';
import { GetScrollElement, GetTopPart, PressAndHold } from './helpers/views';
import useBaseRail from './useBaseRail';
import { useFocusEffect } from '@react-navigation/native';
import { filterAgeRatings } from '../grids/helpers/functions';

export interface Data {
    items: [
        {
            image: string;
            title: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    reloadElements: any;
    heroIsPlayer: boolean;
    railIndex?: number;
}

export const Widescreen_Rails = React.memo(({ styling, focusContext, navigation, reloadElements, heroIsPlayer, railIndex }: Props) => {
    const screenName = getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const settingsContext = React.useContext(SettingsContext);
    const [favoriteCount, setFavoriteCount] = React.useState();
    const [data, setData] = React.useState(screenName === 'Home' && !checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext) ? EMPTY_DATA_ARRAY : []);
    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement?.height)).current : getCorrectHeight(deviceContext, styling.placement?.height);
    if (height == 0 || isNaN(height)) {
        height = getCorrectHeight(deviceContext, 100);
    }
    if (deviceContext.formFactor == 'Phone') {
        height = height * 0.7;
    }
    if (styling.texts.placement_text == 'Below') {
        height = height + getCorrectHeight(deviceContext, styling.content.list?.type == 'Channels' ? 50 : styling.content.list?.type == 'Games' ? 30 : 15);
    }
    var width = (height / 9) * 16;

    useFocusEffect(() => {
        if (styling.content.type === 'Content List') {
            const favName = styling.content.list?.type === 'Series' ? 'series' : styling.content.list?.type === 'Courses' ? 'courses' : styling.content.list?.type === 'Channels' ? 'channels' : 'cams';
            setFavoriteCount(userContext.profile.favorites[favName].length);
        }
    });

    //mobile width check
    if (width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone') {
        height = ((getRealWidth(deviceContext) * 0.7) / 16) * 9;
        if (styling.texts.placement_text == 'Below') {
            height = height + getCorrectHeight(deviceContext, styling.content.list?.type == 'Channels' ? 50 : styling.content.list?.type == 'Games' ? 30 : 15);
        }
        width = (height / 9) * 16;
    }

    const [dataRefreshed, setDataRefreshed] = React.useState<any>();
    const currentFocusRailIndex = React.useRef<number | undefined>();

    const getData = async () => {
        if (styling.content.type === 'Search') {
            handleSearch();
            return;
        }

        if (styling.content.type == 'Watched') {
            var items = (await getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    setData(items);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }

        if (styling.content.type == 'Favorites') {
            var items = (await getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    setData(items);
                    setDataRefreshed(Date.now());
                    return;
                }
            }
        }

        if (styling.content.type == 'Downloads') {
            const items = MediaCache.tasks as any;
            if (items != undefined && items.length > 0) {
                setData(items);
                return;
            }
        }

        if (styling.content.type == 'Content List') {
            var path = '';
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Cams') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/cams/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (styling.content.list?.type == 'Channels') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getChannelsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Cams') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCamsFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Radio') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getRadiosFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Series') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getSeriesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Courses') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCoursesFromSubscriptionAndRating(contentContext, sortedByRecommended);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                    if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscriptionAndRating(contentContext, data[0].items);
                        if (appContext.application.settings.agerating?.ratings != undefined) {
                            dataOut = filterAgeRatings(dataOut, userContext, appContext);
                        }
                        setData(dataOut);
                        if (dataOut?.length) return;
                    }
                }
            } catch (error) {
                //
            }
        }
        // setData(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'wide',
        contentType: styling.content.type,
        getData,
        emptyItemRenderer: (props: any) => {
            return renderItem({
                item: EMPTY_DATA_ARRAY[0],
                index: props.index,
                target: 'Cell',
                emptyRailProps: props,
            });
        },
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: checkIfUserGeneratedListAndEmpty(styling, userContext, contentContext, appContext),
    });

    React.useEffect(() => {
        if (railIndex === currentFocusRailIndex.current) {
            if (data.length === 0) {
                setFocus(`empty-wide-placeholder-${railIndex}`);
            } else {
                const focusIndex = data.length > 2 ? 2 : 0;
                setFocus(`wide-${railIndex}-${focusIndex}`);
            }
        }
    }, [dataRefreshed]);

    React.useEffect(() => {
        if (isFactorMobile && styling.content.type === 'Search') handleSearch();
    }, [contentContext.search]);

    async function handleSearch() {
        try {
            if (contentContext.searchRef.current.length <= 2) {
                setData([]);
                return;
            }

            if (styling.content.list?.type == 'Recordings' && appContext.application.menus?.find((m) => m.name == 'Channels') != undefined) {
                var recordings = [] as any;

                contentContext.recordings.forEach((recording) => {
                    if (recording.program.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                        if (!recordings.some((_) => _._id === recording._id)) {
                            recordings.push(recording);
                        }
                    }
                });

                if (recordings.length > 0) {
                    setData(recordings);
                    return;
                }
            }
            if (styling.content.list?.type == 'Channels' && appContext.application.menus?.find((m) => m.name == 'Channels') != undefined) {
                var channels = [] as any;
                contentContext.channels?.categories?.forEach((category) => {
                    category.channels.forEach((channel) => {
                        if (channel.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                            if (!channels.some((_) => _._id === channel._id)) {
                                channels.push(channel);
                            }
                        }
                    });
                });

                if (channels.length > 0) {
                    if (appContext.application.settings.agerating?.ratings != undefined) {
                        channels = filterAgeRatings(channels, userContext, appContext);
                    }
                    setData(channels);
                    return;
                }
            }
            if (styling.content.list?.type == 'Cams' && appContext.application.menus?.find((m) => m.name == 'Cams') != undefined) {
                var cams = [] as any;

                contentContext.cams?.categories?.forEach((category) => {
                    category.cams.forEach((cam) => {
                        if (cam.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                            if (!cams.some((_) => _._id === cam._id)) {
                                cams.push(cam);
                            }
                        }
                    });
                });

                if (cams.length > 0) {
                    if (appContext.application.settings.agerating?.ratings != undefined) {
                        cams = filterAgeRatings(cams, userContext, appContext);
                    }
                    setData(cams);
                    return;
                }
            }
            if (styling.content.list?.type == 'Courses' && appContext.application.menus?.find((m) => m.name == 'Courses') != undefined) {
                var courses = [] as any;
                contentContext.courses?.categories?.forEach((category) => {
                    category.courses.forEach((course) => {
                        if (course.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                            if (!courses.some((_) => _._id === course._id)) {
                                courses.push(course);
                            }
                        }
                    });
                });
                if (courses.length > 0) {
                    if (appContext.application.settings.agerating?.ratings != undefined) {
                        courses = filterAgeRatings(courses, userContext, appContext);
                    }
                    setData(courses);
                    return;
                }
            }
            if (styling.content.list?.type == 'Series' && appContext.application.menus?.find((m) => m.name == 'Series') != undefined) {
                var series = [] as any;
                contentContext.series?.categories?.forEach((category) => {
                    category.series.forEach((serie) => {
                        if (serie.name.toLowerCase().indexOf(contentContext.searchRef.current.toLowerCase()) > -1) {
                            if (!series.some((_) => _._id === serie._id)) {
                                series.push(serie);
                            }
                        }
                    });
                });
                if (series.length > 0) {
                    if (appContext.application.settings.agerating?.ratings != undefined) {
                        series = filterAgeRatings(series, userContext, appContext);
                    }
                    setData(series);
                    return;
                }
            }
            if (styling.content.list?.type == 'Shorts' && appContext.application.menus?.find((m) => m.name == 'Shorts') != undefined) {
                var shorts = [] as any;

                contentContext.shorts?.categories?.forEach((category) => {
                    category.shorts.forEach((short) => {
                        if (short.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                            if (!shorts.some((_) => _._id === shorts._id)) {
                                shorts.push(short);
                            }
                        }
                    });
                });
                if (shorts.length > 0) {
                    if (appContext.application.settings.agerating?.ratings != undefined) {
                        shorts = filterAgeRatings(shorts, userContext, appContext);
                    }
                    setData(shorts);
                    return;
                }
            }
            setData([]);
        } catch (e) {
            setData([]);
        }
    }

    const detailsItem = (item: any, index: any) => {
        getDetailsScreen(styling, userContext, contentContext, appContext, navigation, item, settingsContext);
    };

    const renderItem = ({ item, focusRepeatContext, index, emptyRailProps }: CreateListRenderItemInfo<any> & { emptyRailProps?: any }) => {
        if (styling.content.type == 'Downloads') {
            //item = userContext.downloads.find((d) => d._id == item.source.metadata._id);
        }
        const isFavorite = favoriteStatus(styling.content.list?.type, item?._id, userContext);
        return (
            <Widescreen
                elementType={'Rails'}
                heroIsPlayer={heroIsPlayer}
                rails={true}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusOptions={{
                    focusKey: `wide-${railIndex}-${index}`,
                }}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    if (item.type !== 'empty') {
                        detailsItem(item, index);
                    }
                }}
                onLongPress={() => {
                    if (item.type !== 'empty') {
                        showLongpressModal(item, index);
                    }
                }}
                isFirst={index === 0}
                isLast={data.length - 1 === index}
                isFavorite={isFavorite}
                {...emptyRailProps}
            ></Widescreen>
        );
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        const currentFocusParent = CoreManager.getCurrentFocus()?.getParent()?.getParent();
        const parentGroup = currentFocusParent;
        // @ts-ignore
        currentFocusRailIndex.current = Number(parentGroup?._focusKey?.split?.('-')?.[1]);

        if (styling.content.press_and_hold && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(styling.content.list?.type, item._id, userContext));
            setWatchlist(watchingStatus(styling.content.list?.type, item._id, userContext) == undefined ? false : true);
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        setWatchlist(!watchlist);
        manageWatching(userContext, styling.content.list?.type, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setShowLongpress(false);
        reloadElements();
    };
    const manageFavorite_ = () => {
        setFavorite(!favorite);
        manageFavorite(contentContext, styling.content.list?.type, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setShowLongpress(false);
        reloadElements();
    };

    React.useEffect(() => {
        if (showLongpress) {
            appContext.setModal(
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => setShowLongpress(false)}
                    data={longItem}
                    contentType={styling.content.list?.type}
                />
            );
        } else {
            appContext.setModal(null);
        }
    }, [showLongpress, watchlist, favorite, longItem]);

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }

    return (
        <View focusContext={focusContext}>
            {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: 0,
                        marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                        marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext, styling),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,

                                height: styling.content.type === 'Search' && data.length === 0 ? 0 : getRailsHeight(deviceContext, appContext, styling, height),
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingHorizontal:
                                    styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                        ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                            ? getCorrectWidth(deviceContext, 5)
                                            : getCorrectWidth(deviceContext, styling.placement.padding)
                                        : 0,
                            }}
                        >
                            <GetTopPart
                                screenName={screenName}
                                focusContext={focusContext}
                                openOverviewPage={openOverviewPage}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            ></GetTopPart>
                            <View style={{ flexDirection: 'row' }}>
                                {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && (
                                    <View style={{ marginRight: getCorrectWidth(deviceContext, 4) }}>
                                        <Pressable
                                            style={[
                                                {
                                                    ...getPressableStyling(styling.components.button._id, appContext),
                                                    height: height - getCorrectWidth(deviceContext, 7),
                                                    width: width,
                                                },
                                            ]}
                                            focusContext={focusContext}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => openOverviewPage()}
                                        >
                                            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faRectangleHistory} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraLarge')}></FontAwesomeIcon>
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        marginTop: 10,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        color: styling.placement.title_color,
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'more').toUpperCase()}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    </View>
                                )}
                                <View
                                    style={{ flex: 1, width: getRailsWidth(deviceContext, appContext, styling, screenName) }}
                                    focusContext={focusContext}
                                    focusOptions={{
                                        group: screenName === 'Search' ? `search-rail-${railIndex}` : `wide-${railIndex}`,
                                        focusKey: screenName === 'Search' ? `search-rail-${railIndex}` : `wide-${railIndex}`,
                                        allowFocusOutsideGroup: true,
                                    }}
                                >
                                    <View>{Loader()}</View>
                                    {data != null && data.length > 0 && (
                                        <FlashList
                                            ref={railsRef}
                                            data={data}
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            estimatedItemSize={height}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderItem}
                                            extraData={favoriteCount}
                                            initialScrollIndex={0}
                                            type={'row'}
                                            {...flashListProps}
                                            focusOptions={{
                                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                                ...flashListProps.focusOptions,
                                                ...(screenName === 'Search' && {
                                                    nextFocusDown: Array.from({ length: 9 }, (_, i) => railIndex + 1 + i).map((i) => `search-rail-${i}`),
                                                    nextFocusUp:
                                                        railIndex > 0
                                                            ? Array.from({ length: railIndex }, (_, i) => i)
                                                                  .map((i) => `search-rail-${i}`)
                                                                  .reverse()
                                                            : undefined,
                                                }),
                                            }}
                                            {...(isPlatformTvos && {
                                                contentInset: { left: 27 },
                                            })}
                                        />
                                    )}
                                    {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                        <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                    )}
                                </View>
                            </View>
                            {deviceContext.formFactor != 'Phone' && styling.content.press_and_hold && styling.content.press_and_hold_message && data != null && data.length > 0 && (
                                <PressAndHold deviceContext={deviceContext} appContext={appContext} userContext={userContext} lang={lang}></PressAndHold>
                            )}
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
});
export default Widescreen_Rails;
