import { getCorrectWidth } from './correctSizes';

export const getCorrectLeftMargin = (deviceContext, appContext, screenName) => {
    var width = getCorrectWidth(deviceContext, appContext.application.theme.home_details_left_margin);
    if (
        screenName == 'Movies' ||
        screenName == 'Series' ||
        screenName == 'Channels' ||
        screenName == 'Radio' ||
        screenName == 'Shorts' ||
        screenName == 'Podcasts' ||
        screenName == 'Courses' ||
        screenName == 'Favorites' ||
        screenName == 'Watchlist' ||
        screenName == 'Downloads' ||
        screenName == 'Games' ||
        screenName == 'TV Guide'
    ) {
        var app = appContext.application as any;
        var screen = app.screens.find((s) => s.type == screenName);
        var contents = screen.content;
        var element = [] as any;
        for (let content of Object.values(contents)) {
            if (content['long_grids'] != undefined) {
                element = content['long_grids'];
            }
            if (content['widescreen_grids'] != undefined) {
                element = content['widescreen_grids'];
            }
            if (content['square_grids'] != undefined) {
                element = content['square_grids'];
            }
            if (content['morphing_grids'] != undefined) {
                element = content['morphing_grids'];
            }
            if (content['rectangle_grids'] != undefined) {
                element = content['rectangle_grids'];
            }
            if (content['tile_grids'] != undefined) {
                element = content['tile_grids'];
            }
            width = element.length != 0 ? element.placement.margin_left : 20;
        }
    }
    if (screenName.indexOf('Short') > -1 || screenName.indexOf('Game') > -1 || screenName.indexOf('Program') > -1 || screenName.indexOf('Movie') > -1 || screenName.indexOf('Seasons') > -1 || screenName.indexOf('Course') > -1) {
        width = width + getCorrectWidth(deviceContext, global.menuWidth);
    }
    if (screenName == 'Search') {
        width = getCorrectWidth(deviceContext, 10) + getCorrectWidth(deviceContext, global.menuWidth);
    }
    switch (deviceContext.deviceType as any) {
        case 'KaiOS':
            return 5;
        case 'FireTV':
            return width;
        case 'AndroidTV':
            return width;
        case 'AppleTV':
            return width;
        case 'SmartTV_Tizen':
            return width;
        case 'SmartTV_LG':
            return width;
        case 'PWA':
            return 10;
        case 'WebTV':
            return width;
        case 'iOS':
            return 10;
        case 'iPad':
            return width;
        case 'Android_Mobile':
            return 10;
        case 'Android_Tablet':
            return width;
        case 'SetTopBox':
            return width;
        default:
            break;
    }
};
