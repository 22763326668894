import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { DeviceContext } from '../../context/deviceContext';
import { UserContext } from '../../context/userContext';
import { getFontSize } from '../../styling/fontSizes';
import Text from '../../builder/components/text';
import NetInfo from '@react-native-community/netinfo';

const Offline = (): React.ReactElement => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [connected, setConnected] = React.useState(true);

    React.useEffect(() => {
        getConnectionStatus();
    }, []);

    const getConnectionStatus = async () => {
        setConnected((await NetInfo.fetch()).isConnected);
    };

    if (!connected) {
        return (
            <View
                style={{
                    position: 'absolute',
                    backgroundColor: '#333',
                    height: '100%',
                    width: '100%',
                    flex: 1,
                    zIndex: 99999,
                }}
            >
                <View style={{ flex: 1, paddingTop: 50, paddingLeft: 50 }}>
                    <Text
                        style={{
                            color: '#fff',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                        }}
                    >
                        No internet Connection found
                    </Text>
                </View>
                <View
                    style={{
                        flex: 2,
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <FontAwesomeIcon size={200} style={{ color: '#999' }} icon={faExclamationCircle} />
                </View>
                <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', padding: 20 }}>
                    <ActivityIndicator color={'#999'} size={'large'}></ActivityIndicator>
                </View>
            </View>
        );
    }
    return null;
};

export default Offline;
