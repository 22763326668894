import * as React from 'react';
import { View, ActivityIndicator, withParentContextMapper, FlashList, CreateListRenderItemInfo } from '@scriptx-com/xtv-toolkit';
import { Details_Top as styling } from '../../../models/elements/details/top/top';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import lang from '../../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { TextFade } from '../../animations/textFade';
import { Translations } from '../../../models/content/shared/translations';
import { Streams } from '../../../models/content/shared/streams';
import { PayPerView } from '../../../models/content/shared/payperview';
import { Security } from '../../../models/content/shared/security';
import { Images } from '../../../models/content/shared/images';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft, faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { favoriteStatus, manageFavorite } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { ContentContext } from '../../../context/contentContext';
import { UserContext } from '../../../context/userContext';
import { ImageFadeBlurZoom } from '../../animations/imageFadeBlurZoom';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import { playAlbum, playPodcast } from '../rails/helpers/helper';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling } from '../../components/helpers/helper';

const TextFadeWithContext = withParentContextMapper(TextFade);

export interface Songs {
    name: string;
    producer?: string;
    writer?: string;
    duration: string;
    streams: Streams;
}
export interface Episodes {
    name: string;
    producer: string;
    writer: string;
    duration: string;
    streams: Streams;
}
export interface Data {
    _id?: string;
    name: string;

    artist?: string;

    creator?: string;
    tagline?: string;
    release_period?: string;
    avarage_length?: string;
    language?: string;

    episodes: Episodes[];
    songs?: Songs[];

    payperview: PayPerView;
    security: Security;
    translations?: Translations[];
    images?: Images;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
    isFromSocialSharing?: boolean;
    socialScreenName?: string;
}

export const Details_AudioStandard = ({ styling, navigation, focusContext, isFromSocialSharing, socialScreenName }: Props) => {
    const [hide, setHide] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState<Data>([] as any);
    const [isFavorite, setIsFavorite] = React.useState(false);
    const screenName = socialScreenName != undefined ? socialScreenName :  getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);

    const getData = async () => {
        var path = '';
        if ((socialScreenName != undefined && socialScreenName == 'Album') || screenName == 'Album') {
            path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/albums/details/' + contentContext.albumData.album._id + '_details.json';
        }
        if ((socialScreenName != undefined && socialScreenName == 'Podcast') || screenName == 'Podcast') {
            path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/podcasts/details/' + contentContext.podcastData.podcast._id + '_details.json';
        }
        try {
            let response = await fetch(path);
            let data = await response.json();
            if (data != undefined) {
                if (screenName == 'Album') {
                    contentContext.setAlbumData({
                        ...contentContext.albumData,
                        album: data,
                    });
                }
                if (screenName == 'Podcast') {
                    contentContext.setPodcastData({
                        ...contentContext.podcastData,
                        podcast: data,
                    });
                }
                setData(data);
                setIsFavorite(favoriteStatus(screenName, data._id, userContext));
                setShow(true);
            }
        } catch (error) {
            setHide(true);
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
    }, [navigation]);

    const playItem = (item, index) => {
        if (screenName == 'Album') {
            playAlbum(contentContext, item, navigation, index);
        }
        if (screenName == 'Podcast') {
            playPodcast(contentContext, item, navigation, index);
        }
    };

    const getTotalLength = () => {
        if (screenName == 'Album') {
            var mins = 0;
            var secs = 0;
            if (data.songs != undefined) {
                data.songs.forEach((song) => {
                    if (song.duration != undefined) {
                        var timeArr = song.duration.split(':');
                        mins = mins + Number(timeArr[0]);
                        secs = secs + Number(timeArr[1]);
                    }
                });
            }
            var extraMins = Math.round(secs / 60);
            return mins + extraMins + ' min';
        }
        if (screenName == 'Podcast') {
            var mins = 0;
            var secs = 0;
            if (data.episodes != undefined) {
                data.episodes.forEach((episode) => {
                    if (episode.duration != undefined) {
                        var timeArr = episode.duration.split(':');
                        mins = mins + Number(timeArr[0]);
                        secs = secs + Number(timeArr[1]);
                    }
                });
            }
            var extraMins = Math.round(secs / 60);
            return mins + extraMins + ' min';
        }
    };

    const manageFavoriteLocal = () => {
        manageFavorite(contentContext, screenName, data, appContext.application, isFavorite, userContext, deviceContext, appContext);
        setIsFavorite(!isFavorite);
    };

    const renderAlbum = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                focusRepeatContext={focusRepeatContext}
                style={{
                    height: getCorrectHeight(deviceContext, 50),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    marginHorizontal: getCorrectWidth(deviceContext, 2.5),

                    margin: getCorrectHeight(deviceContext, 2),
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
                key={index}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => (isFromSocialSharing == undefined ? playItem(item, index) : null)}
            >
                <View>
                    <View
                        style={{
                            flexDirection: 'row',
                            padding: getCorrectWidth(deviceContext, 5),
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <View
                                style={{
                                    paddingHorizontal: getCorrectHeight(deviceContext, 10),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                    }}
                                >
                                    {index + 1}.
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.75,
                            }}
                        >
                            <View
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }}
                            >
                                <View>
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {item.name}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {item.duration}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    };

    const renderPodcast = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                focusRepeatContext={focusRepeatContext}
                style={{
                    height: getCorrectHeight(deviceContext, 50),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    marginHorizontal: getCorrectWidth(deviceContext, 2.5),

                    margin: getCorrectHeight(deviceContext, 2),
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: getCorrectWidth(deviceContext, 5),
                }}
                key={index}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => (isFromSocialSharing == undefined ? playItem(item, index) : null)}
            >
                <View>
                    <View
                        style={{
                            flexDirection: 'row',
                            padding: getCorrectWidth(deviceContext, 5),
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <View
                                style={{
                                    paddingHorizontal: getCorrectHeight(deviceContext, 10),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                    }}
                                >
                                    {index + 1}.
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                width: getRealWidth(deviceContext) * 0.9 - getCorrectWidth(deviceContext, 80),
                            }}
                        >
                            <View
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }}
                            >
                                <View>
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {item.name}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: '#fff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {item.duration}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    };

    return (
        <View style={{ flex: 1, backgroundColor: '#000' }} focusContext={focusContext}>
            {!hide && (
                <View style={{ flex: 1 }}>
                    {!show && (
                        <View style={{}}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    backgroundColor: '#000',
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : 100),
                                }}
                            >
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                                </View>
                            </View>
                        </View>
                    )}
                    {show && (
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext),
                                zIndex: 2,
                            }}
                        >
                            <Image
                                deviceContext={deviceContext}
                                isLocal={true}
                                source={require('../../../../assets/images/gradient-home1.png')}
                                resizeMethod={'scale'}
                                resizeMode={'stretch'}
                                style={{ height: getRealHeight(deviceContext), width: getRealWidth(deviceContext) }}
                            ></Image>
                        </View>
                    )}
                    {show && (
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext),
                                zIndex: 1,
                            }}
                        >
                            <ImageFadeBlurZoom
                                deviceContext={deviceContext}
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                style={{
                                    width: getRealWidth(deviceContext),
                                    height: getRealHeight(deviceContext),
                                }}
                                source={{ uri: appContext.cloudUrl + data.images?.square }}
                            />
                        </View>
                    )}

                    {show && (
                        <View
                            style={{
                                zIndex: 2,
                                width: getRealWidth(deviceContext),
                                height: getRealHeight(deviceContext) - global.menuHeight,
                                alignItems: 'center',
                                flex: 1,
                                paddingTop: getCorrectHeight(deviceContext, 10),
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                }}
                            >
                                {isFromSocialSharing == undefined && (
                                    <View style={{ flexDirection: 'row', marginTop: deviceContext.isIos ? 50 : 10 }}>
                                        <View style={{ flex: 1 }}>
                                            <Pressable
                                                style={{
                                                    marginRight: 10,
                                                    width: getCorrectWidth(deviceContext, 40),
                                                    height: getCorrectWidth(deviceContext, 40),
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderRadius: 100,
                                                    opacity: 0.6,
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => navigation.goBack()}
                                            >
                                                <View>
                                                    <FontAwesomeIcon icon={faArrowLeft} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                                                </View>
                                            </Pressable>
                                        </View>

                                        <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                            <Pressable
                                                style={{
                                                    width: getCorrectWidth(deviceContext, 40),
                                                    height: getCorrectWidth(deviceContext, 40),
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',

                                                    borderRadius: 100,
                                                    opacity: 0.6,
                                                }}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => manageFavoriteLocal()}
                                            >
                                                <View>
                                                    <FontAwesomeIcon
                                                        icon={isFavorite ? faHeartSolid : faHeart}
                                                        color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                                        size={getFontSize(deviceContext, 'Large')}
                                                    ></FontAwesomeIcon>
                                                </View>
                                            </Pressable>
                                        </View>
                                    </View>
                                )}
                                <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: getCorrectWidth(deviceContext, 20) }}>
                                    <Image
                                        deviceContext={deviceContext}
                                        resizeMethod={'scale'}
                                        resizeMode={'contain'}
                                        source={{ uri: appContext.cloudUrl + data.images?.square }}
                                        style={{
                                            width: getRealWidth(deviceContext) * 0.4,
                                            height: getRealWidth(deviceContext) * 0.4,
                                            borderRadius: appContext.application.theme.images.radius / 3,
                                        }}
                                    ></Image>
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            marginTop: 10,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'), //getFontSize(deviceContext, styling.texts.title_size),
                                            fontWeight: styling.texts.title_bold ? 'bold' : null,
                                            color: styling.texts.title_color,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {data.name}
                                    </Text>
                                </View>

                                {screenName == 'Podcast' && (
                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                        {data.creator != '' && data.creator != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {data.creator}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        {data.release_period != '' && data.release_period != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {data.release_period}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        {data.language != '' && data.language != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {data.language}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        {data.avarage_length != '' && data.avarage_length != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {data.avarage_length}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        {data.episodes != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'tracks')} {data.episodes.length}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                color: styling.texts.title_color,
                                                paddingBottom: getCorrectHeight(deviceContext, 15),
                                            }}
                                        >
                                            {getTotalLength()}
                                        </Text>
                                    </View>
                                )}
                                {screenName == 'Album' && (
                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                        {data.artist != '' && data.artist != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {data.artist}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        {data.songs != undefined && (
                                            <>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'tracks')} {data.songs.length}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                        color: styling.texts.title_color,
                                                        paddingBottom: getCorrectHeight(deviceContext, 15),
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </Text>
                                            </>
                                        )}
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                                color: styling.texts.title_color,
                                                paddingBottom: getCorrectHeight(deviceContext, 15),
                                            }}
                                        >
                                            {getTotalLength()}
                                        </Text>
                                    </View>
                                )}
                                <View style={{ width: getRealWidth(deviceContext) * 0.95, height: getRealHeight(deviceContext) * (deviceContext.isIos ? 0.38 : 0.42) }}>
                                    {screenName == 'Album' && (
                                        <FlashList
                                            data={data.songs}
                                            estimatedItemSize={getCorrectHeight(deviceContext, 50)}
                                            drawDistance={getCorrectHeight(deviceContext, 50) * 8}
                                            numColumns={1}
                                            horizontal={false}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderAlbum}
                                            initialScrollIndex={0}
                                            type={'row'}
                                            style={{ flex: 1 }}
                                            // focusOptions={{
                                            //     autoLayoutScaleAnimation: styling.animation.type.indexOf('scale') > -1 ? true : false,
                                            //     autoLayoutSize: styling.animation.type.indexOf('scale') > -1 ? 10 : 0,
                                            // }}
                                        />
                                    )}
                                    {screenName == 'Podcast' && (
                                        <FlashList
                                            data={data.episodes}
                                            estimatedItemSize={getCorrectHeight(deviceContext, 50)}
                                            drawDistance={getCorrectHeight(deviceContext, 50) * 8}
                                            numColumns={1}
                                            horizontal={false}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderPodcast}
                                            initialScrollIndex={0}
                                            type={'row'}
                                            style={{ flex: 1 }}
                                            // focusOptions={{
                                            //     autoLayoutScaleAnimation: styling.animation.type.indexOf('scale') > -1 ? true : false,
                                            //     autoLayoutSize: styling.animation.type.indexOf('scale') > -1 ? 10 : 0,
                                            // }}
                                        />
                                    )}
                                </View>
                            </View>
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};

export default Details_AudioStandard;
