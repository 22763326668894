import { CreateListRenderItemInfo, FlashList, Screen, View } from '@scriptx-com/xtv-toolkit';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Pressable from '../../builder/components/pressable/pressable';
import { AppContext } from '../../context/appContext';
import { DeviceContext } from '../../context/deviceContext';
import { SettingsContext } from '../../context/settingsContext';
import { UserContext } from '../../context/userContext';
import LANG from '../../languages/languages';
import Stacks from '../../stacks/StackSelector';
import { getCorrectHeight, getCorrectWidth } from '../../styling/correctSizes';
import { getFontSize } from '../../styling/fontSizes';
import { getMenuIcon } from '../../styling/menuIcon';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import { getRealWidth } from '../../styling/realDeviceSizes';
import Text from '../../builder/components/text';
import { ContentContext } from '../../context/contentContext';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../builder/components/helpers/helper';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { checkIfMenuHasWebview } from '../helpers/helpers';


const Tab = createMaterialTopTabNavigator();

function MyTabBar({ state, descriptors, navigation }) {
    var menu = '';
    var styling = [] as any;
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [activeSettings, setActiveSettings] = useState('');
    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    const onPress = (menu: any) => {
        contentContext.setActiveMenu(menu);
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: menu }],
            })
        );
    };

    var menuArray = [] as any;
    if (activeSettings == '') {
        menuArray = [];
        appContext.application.menus.forEach((element) => {
            if (
                element.name != 'Logout' &&
                element.name != 'Services' &&
                element.name != 'Disclaimer' &&
                element.name != 'Signin' &&
                element.name != 'Forgot' &&
                element.name != 'Check' &&
                element.name != 'Player' &&
                element.name != 'Profiles' &&
                element.name != 'Languages' &&
                element.name != 'Settings' &&
                element.name != 'Messages' &&
                element.name != 'Loader' &&
                element.name != 'Support'
            ) {
                if (
                    element.name == 'Search' ||
                    (userContext.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                    (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                    (userContext.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                    (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                    (checkIfMenuHasWebview(appContext, element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                ) {
                    //do nothing
                } else {
                    var menu_ = { name: element.name, menu: element.name };
                    menuArray.push(menu_);
                }
            }
        });
    }
    if (menuArray.length > 1 && menu == '') {
        menu = menuArray[1].menu;
    }
    const scrollRight = () => {
        rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };
    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable style={{ marginRight: 5 }} animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => onPress(item.menu)}>
                <>
                    {contentContext.activeMenu != item.menu && (
                        <View
                            style={{
                                margin: 5,
                                height: getCorrectHeight(deviceContext, 30) - 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                            }}
                        >
                            {<FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon(item.menu, userContext)} />}

                            <Text
                                numberOfLines={1}
                                style={{
                                    paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 5 : 0,
                                    paddingLeft: 10,
                                    paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                    {contentContext.activeMenu == item.menu && (
                        <View
                            style={{
                                margin: 5,
                                height: getCorrectHeight(deviceContext, 30) - 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                paddingHorizontal: getCorrectWidth(deviceContext, 15),
                                flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                            }}
                        >
                            {<FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: getSelectedStyling('Navigators', appContext).text_color }} icon={getMenuIcon(item.menu, userContext)} />}

                            <Text
                                numberOfLines={1}
                                style={{
                                    paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 5 : 0,
                                    paddingLeft: 10,
                                    paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                    color: getSelectedStyling('Navigators', appContext).text_color,
                                }}
                            >
                                {LANG.getTranslation(userContext, item.name)}
                            </Text>
                        </View>
                    )}
                </>
            </Pressable>
        );
    };
    if (settingsContext.showMainMenu) {
        return (
            <Screen focusOptions={{ stealFocus: false }}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    {menuArray.length > viewableItemsCount && (
                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => scrollLeft()} style={{ justifyContent: 'center' }}>
                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.8} style={{ color: '#fff' }} icon={faChevronLeft} />
                        </Pressable>
                    )}
                    <View
                        style={{
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, deviceContext.isPhone ? 150 : 200) * 0.9 - getCorrectWidth(deviceContext, deviceContext.isPhone ? 150 : 250) * 0.9,
                        }}
                    >
                        <FlashList
                            ref={rails}
                            type="row"
                            onViewableItemsChanged={onViewableItemsChanged}
                            extraData={menuArray}
                            data={menuArray}
                            estimatedItemSize={getCorrectWidth(deviceContext, 200)}
                            numColumns={1}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            renderItem={renderItem}
                            initialScrollIndex={0}
                            style={{ flex: 1 }}
                        />
                    </View>
                    {menuArray.length > viewableItemsCount && (
                        <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => scrollRight()} style={{ justifyContent: 'center' }}>
                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.8} style={{ color: '#fff' }} icon={faChevronRight} />
                        </Pressable>
                    )}
                </View>
            </Screen>
        );
    }

    return null;
}
export default () => {
    return (
        <Tab.Navigator tabBar={(props) => <MyTabBar {...props} />}>
            <Tab.Screen name="AppStack" component={Stacks} />
        </Tab.Navigator>
    );
};
