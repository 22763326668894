import { View } from '@scriptx-com/xtv-toolkit';
import moment from 'moment';
import React from 'react';
import { useSafeAreaInsets } from '../../../../../libs/react-native-safe-area-context';
import Event from '../../../../events';
import { getCurrentScreenName } from '../../../../hooks/getScreenName';
import { useEffectOnce } from '../../../../hooks/useEffectOnce';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import Scrubber from '../../../components/scrubber/Srubber';
import { getCatchupTV } from './functions';

export const ProgressBars = ({ onClose, playerContext, contentContext, onScrubberDrag, deviceContext, userContext, appContext, focusContext }) => {
    const [scrubberValue, setScrubberValue] = React.useState(0);
    const screenName = getCurrentScreenName();
    const isLandscape = (deviceContext.isPhone && deviceContext.isApple) || deviceContext.isPwaVertical;
    const insets = useSafeAreaInsets();
    const insetFromSides =
        isLandscape && screenName != 'Radio_Player' && screenName != 'Podcast_Player' && screenName != 'Album_Player'
            ? insets.top + insets.bottom
            : screenName == 'Radio_Player' || screenName == 'Podcast_Player' || screenName == 'Album_Player'
            ? 40
            : 0;
    const extraFromSides =
        deviceContext.isPhone && !deviceContext.isAndroid && !deviceContext.isTablet
            ? 30
            : deviceContext.isPhone && !deviceContext.isAndroid
            ? insets.bottom + insets.top + 40
            : deviceContext.isWebTV
            ? 50
            : deviceContext.isTablet
            ? 50
            : deviceContext.isPhone && deviceContext.isAndroid
            ? 5
            : deviceContext.isAndroid
            ? 30
            : 50;
    React.useEffect(() => {
        const eventListener = Event.subscribe('onPlayerTimeUpdate', (value) => {
            playerContext.setPosition(value);
            setScrubberValue(value);
            global.seekPosition = value;
        });

        return () => {
            eventListener();
        };
    }, []);

    useEffectOnce(() => {
        setScrubberValue(playerContext.position);
    });

    return (
        <View style={{ justifyContent: 'center', marginTop: getCorrectHeight(deviceContext, 5) }} focusContext={focusContext}>
            {screenName == 'CatchupTV_Player' && contentContext.channelData.program != undefined && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={(deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext)) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue}
                    onSlidingComplete={getCatchupTV(contentContext, screenName) ? onScrubberDrag : onScrubberDrag}
                    totalDuration={contentContext.channelData.program?.e - contentContext.channelData.program?.s}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'crimson'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    s={contentContext.channelData.program?.s ?? moment().unix()}
                    e={contentContext.channelData.program?.e ?? moment().add(1, 'hour').unix()}
                    live={true}
                />
            )}
            {screenName == 'Recording_Player' && contentContext.recordingData.program != undefined && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={(deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext)) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue}
                    onSlidingComplete={getCatchupTV(contentContext, screenName) ? onScrubberDrag : onScrubberDrag}
                    totalDuration={contentContext.recordingData.program?.e - contentContext.recordingData.program?.s}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'crimson'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    s={contentContext.recordingData.program?.s ?? moment().unix()}
                    e={contentContext.recordingData.program?.e ?? moment().add(1, 'hour').unix()}
                    live={true}
                />
            )}
            {screenName == 'Channel_Player' && contentContext.channelData.program != undefined && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={(deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext)) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue - contentContext.channelData.program?.s}
                    onSlidingComplete={getCatchupTV(contentContext, screenName) ? onScrubberDrag : onScrubberDrag}
                    totalDuration={contentContext.channelData.program?.e - contentContext.channelData.program?.s}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'transparent'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    s={contentContext.channelData.program?.s ?? moment().unix()}
                    e={contentContext.channelData.program?.e ?? moment().add(1, 'hour').unix()}
                    live={true}
                />
            )}
            {screenName == 'Radio_Player' && contentContext.radioData.program != undefined && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={(deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext)) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue - contentContext.radioData.program?.s}
                    onSlidingComplete={() => {}}
                    totalDuration={contentContext.radioData.program?.e - contentContext.radioData.program?.s}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'transparent'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    s={contentContext.radioData.program?.s ?? moment().unix()}
                    e={contentContext.radioData.program?.e ?? moment().add(1, 'hour').unix()}
                    live={true}
                />
            )}
            {playerContext.duration != undefined && (screenName == 'Short_Player' || screenName == 'Movie_Player' || screenName == 'Series_Player' || screenName == 'Course_Player') && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={(deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext)) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue}
                    onSlidingComplete={onScrubberDrag}
                    totalDuration={playerContext.duration ?? 0}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'transparent'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    live={false}
                />
            )}
            {playerContext.duration != undefined && (screenName == 'Podcast_Player' || screenName == 'Album_Player') && (
                <Scrubber
                    onClose={onClose}
                    scrubberWidth={deviceContext.isPhone ? getRealWidth(deviceContext) * 0.8 : getRealWidth(deviceContext) - getCorrectWidth(deviceContext, extraFromSides) - insetFromSides}
                    focusContext={focusContext}
                    userContext={userContext}
                    deviceContext={deviceContext}
                    value={scrubberValue}
                    onSlidingComplete={onScrubberDrag}
                    totalDuration={playerContext.duration ?? 0}
                    trackcolor={appContext.application.theme.progresses.base}
                    bufferedValue={playerContext.player.buffer != undefined ? playerContext.player.buffer : 0}
                    bufferedTrackColor={'transparent'}
                    scrubbedColor={appContext.application.theme.progresses.progress}
                    live={false}
                />
            )}
        </View>
    );
};
