export const getStreamType = (url) => {
    var type = 'm3u8';
    if (url == undefined || url == '') {
        return type;
    } else {
        var url_ = url.split('?');
        var extension = url_[0].split('.').pop();
        if (extension == 'mp4') {
            type = 'video/mp4';
        }
        if (extension == 'mp3') {
            type = 'audio/mp3';
        }
        if (extension == 'mpd') {
            type = 'application/dash+xml';
        }
        if (extension == 'm3u8') {
            type = 'application/x-mpegurl';
        }
        if (url.indexOf('udp://') > -1) {
            type = 'UDP';
        }
        return type;
    }
};
export const getStreamTypeCasting = (url) => {
    var type = 'application/x-mpegURL';
    if (url == undefined || url == '') {
        return type;
    } else {
        var url_ = url.split('?');
        var extension = url_[0].split('.').pop();
        if (extension == 'mp4') {
            type = 'video/mp4';
        }
        if (extension == 'mpd') {
            type = 'application/dash+xml';
        }
        if (extension == 'm3u8') {
            type = 'application/x-mpegURL';
        }
        return type;
    }
};

export const getFastParams = (url, params, deviceContext, appContext, userContext, content_id, content_name, content_type, random) => {
    var questionOrAnd = url.indexOf('?') > -1 ? '&' : '?';
    params?.params?.forEach((param, index) => {
        if (index == 0) {
            url += questionOrAnd + switchFastParams(param.type, param.name, deviceContext, appContext, userContext, content_id, content_name, content_type, random);
        } else {
            url += '&' + switchFastParams(param.type, param.name, deviceContext, appContext, userContext, content_id, content_name, content_type, random);
        }
    });
    return url;
};
export const switchFastParams = (type, name, deviceContext, appContext, userContext, content_id, content_name, content_type, random) => {
    switch (type) {
        case 'Random Number':
            return name + '=' + random;
            break;
        case 'UUID':
            return name + '=' + encodeURI(deviceContext.uniqueId);
            break;
        case 'App Bundle':
            return name + '=' + encodeURI(deviceContext.packageId);
            break;
        case 'App Store Url':
            return name + '=' + getCorrectAppStoreUrl(deviceContext, appContext);
            break;
        case 'App Store ID':
            return name + '=' + random;
            break;
        case 'App Store Name':
            return name + '=' + encodeURI(deviceContext.appName);
            break;
        case 'Site Domain':
            return name + '=' + random;
            break;
        case 'Site Name':
            return name + '=' + encodeURI(deviceContext.appName);
            break;
        case 'Content ID':
            return name + '=' + encodeURI(content_id);
            break;
        case 'Content Name Per Type':
            if (content_type == 'Channels') {
                return name + '=' + encodeURI(content_name);
                break;
            }
            if (content_type == 'Series') {
                return name + '=' + encodeURI(content_name);
                break;
            }
            if (content_type == 'Movies') {
                return name + '=' + encodeURI(content_name);
                break;
            }
        case 'Content Name':
            return name + '=' + encodeURI(content_name);
            break;
        case 'IAB Category':
            return name + '=' + encodeURI('no defined yet');
            break;
        case 'In App or Browser':
            return name + '=' + deviceContext.isWebTV ? encodeURI('no defined yet') : encodeURI('no defined yet');
            break;
        case 'Device User Agent':
            return name + '=' + encodeURI(deviceContext.userAgent);
            break;
        case 'Device Model':
            return name + '=' + encodeURI(deviceContext.model);
            break;
        case 'Device OS':
            return name + '=' + encodeURI(deviceContext.os);
            break;
        case 'Device OS Version':
            return name + '=' + encodeURI(deviceContext.osVersion);
            break;
        case 'Device Type':
            return name + '=' + encodeURI(deviceContext.type);
            break;
        case 'Device Make':
            return name + '=' + encodeURI(deviceContext.formFactor);
            break;
        case 'User IP Address':
            return name + '=' + encodeURI(deviceContext.ipAddress);
            break;
        case 'User Country':
            return name + '=' + encodeURI(userContext.country);
            break;
        case 'User Latitude':
            return name + '=' + encodeURI(userContext.latitude);
            break;
        case 'User Longitude':
            return name + '=' + encodeURI(userContext.longitude);
            break;
        case 'Height':
            return name + '=' + '1920';
            break;
        case 'Width':
            return name + '=' + '1080';
            break;
        case 'Output':
            return name + '=' + 'no defined yet';
            break;
        case 'US Privacy':
            return name + '=' + '1---';
            break;
        case 'GDRP':
            return name + '=' + 'false';
            break;
        case 'Geo Type':
            return name + '=' + 'IP';
            break;
        case 'Empty':
            return name + '=' + '';
            break;
        case 'Device Connection Type':
            return name + '=' + 'cable';
            break;
        case 'Device ID Type':
            return name + '=' + 'cookie';
            break;
        case 'Add a 0':
            return name + '=' + '0';
            break;
        case 'Add a 1':
            return name + '=' + '1';
            break;
        case 'App Domain':
            return name + '=' + encodeURI(deviceContext.appName);
            break;
    }
};
export const getStreamParams = (url, content_name, content_id, content_type, deviceContext, userContext, appContext, params) => {
    var random = Math.floor(1000 + Math.random() * 9000);
    if (url == undefined) {
        return url;
    }
    if (url.indexOf('distrotv') >= 0) {
        var path =
            'https://i.jsrdn.com/i/1.gif?r=' +
            random +
            '&e=vplay&u=' +
            encodeURI(deviceContext.uniqueId) +
            '&i=' +
            random +
            '&v=&f=&m=' +
            encodeURI(deviceContext.appName) +
            '&p=' +
            encodeURI(deviceContext.appName) +
            '&show=' +
            encodeURI(content_id) +
            '&ep=' +
            encodeURI(content_id);

        fetch(path, { method: 'GET', cors: 'no-cors' }).catch((err) => {});
    }
    if (params != undefined) {
        url = getFastParams(url, params, deviceContext, appContext, userContext, content_id, content_name, content_type, random);
    }
    return url;
};

var occurrenceShown = false;
const checkOccurrence = (elementContent) => {
    var occurrence = elementContent.castoolavast.occurrence;
    if (occurrence == 'eachtime') {
        return true;
    } else if (occurrence == 'onetime' && occurrenceShown == false) {
        occurrenceShown = true;
        return true;
    } else if (occurrence == 'everytime' && occurrenceShown == false) {
        occurrenceShown = true;
        return true;
    } else if (occurrence == 'everytime' && occurrenceShown == true) {
        occurrenceShown = false;
        return false;
    }
};
export const getCatoolaVast = (appContext, userContext, deviceContext, content_id, channel_name, genre, start, end, duration) => {
    var playerScreen = appContext.application.screens.find((s) => s.type == 'Player');
    var elementContent;
    var elementIsCastoola;
    if (playerScreen != undefined) {
        elementContent = playerScreen.content[0];
        if (elementContent != undefined && elementContent.castoolavast != undefined) {
            elementIsCastoola = true;
        }
    }
    if (elementIsCastoola) {
        if (elementContent.castoolavast?.types?.find((t) => t.name == 'Channels') != undefined && checkOccurrence(elementContent)) {
            var vastType = elementContent.castoolavast.single != undefined && elementContent.castoolavast.single == true ? 'position' : elementContent.castoolavast.single == undefined ? 'position' : 'multiple';
            var random = Math.floor(1000 + Math.random() * 9000);
            var deviceType = '5';
            var deviceOs = '0';
            if (deviceContext.deviceType == 'WebTV') {
                deviceType = '1';
                deviceOs = '1';
            } else if (deviceContext.deviceType == 'Android_Mobile') {
                deviceType = '2';
                deviceOs = '5';
            } else if (deviceContext.deviceType == 'iOS') {
                deviceType = '2';
                deviceOs = '6';
            } else if (deviceContext.deviceType == 'KaiOS') {
                deviceType = '2';
                deviceOs = 'Other';
            } else if (deviceContext.deviceType == 'Android_Tablet') {
                deviceType = '3';
                deviceOs = '5';
            } else if (deviceContext.deviceType == 'iPad') {
                deviceType = '3';
                deviceOs = '6';
            } else if (deviceContext.deviceType == 'SetTopBox') {
                deviceType = '4';
                deviceOs = '5';
            } else if (deviceContext.deviceType == 'SmartTV_Tizen') {
                deviceOs = '8';
            } else if (deviceContext.deviceType == 'SmartTV_LG') {
                deviceOs = '9';
            } else if (deviceContext.deviceType == 'AndroidTV') {
                deviceOs = '7';
            } else if (deviceContext.deviceType == 'FireTV') {
                deviceOs = '13';
            } else if (deviceContext.deviceType == 'SmartTV_Vidaa') {
                deviceOs = '11';
            } else if (deviceContext.deviceType == 'AppleTV') {
                deviceOs = '10';
            }

            var contentType = 'o';
            if (elementContent.castoolavast.type == 'Channels') {
                contentType = 'v';
            } else if (elementContent.castoolavast.type == 'CatchupTV') {
                contentType = 'c';
            } else if (elementContent.castoolavast.type == 'Movies') {
                contentType = 'v';
            } else if (elementContent.castoolavast.type == 'Series') {
                contentType = 'v';
            } else if (elementContent.castoolavast.type == 'Courses') {
                contentType = 'v';
            } else if (elementContent.castoolavast.type == 'Recordings') {
                contentType = 'c';
            }

            if (elementContent.castoolavast.type == 'Channels' || elementContent.castoolavast.type == 'Cams') {
                return (
                    'https://mwaretv.client-api.castoola.tv/' +
                    elementContent.castoolavast.publisher_id +
                    '/v2.5/ads/' +
                    vastType +
                    '?subscriber_id=' +
                    encodeURI(userContext.userId) +
                    '&device_type=' +
                    deviceType +
                    '&device_id=' +
                    encodeURI(deviceContext.uniqueId) +
                    '&consent=' +
                    (userContext.profile.consent != undefined ? userContext.profile.consent : true) +
                    '&profile_id=' +
                    userContext.profile.id +
                    '&operating_system=' +
                    deviceOs +
                    '&bust=' +
                    random +
                    '&content_type=' +
                    contentType +
                    '&content_id=' +
                    content_id +
                    '&channel=' +
                    encodeURI(channel_name) +
                    '&genre=' +
                    encodeURI(genre) +
                    '&start_ts=' +
                    start +
                    '&end=' +
                    end +
                    '&vast_version=3.0'
                );
            } else {
                return (
                    'https://mwaretv.client-api.castoola.tv/' +
                    elementContent.castoolavast.publisher_id +
                    '/v2.5/ads/multiple?subscriber_id=' +
                    encodeURI(userContext.userId) +
                    '&device_type=' +
                    deviceType +
                    '&device_id=' +
                    encodeURI(deviceContext.uniqueId) +
                    '&consent=' +
                    (userContext.profile.consent != undefined ? userContext.profile.consent : true) +
                    '&profile_id=' +
                    userContext.profile.id +
                    '&operating_system=' +
                    deviceOs +
                    '&bust=' +
                    random +
                    '&content_type=' +
                    contentType +
                    '&content_id=' +
                    content_id +
                    '&genre=' +
                    encodeURI(genre) +
                    '&duration=' +
                    duration +
                    '&vast_version=3.0'
                );
            }
        } else {
            return '';
        }
    } else {
        return '';
    }
};
export const getCorrectAppStoreUrl = (deviceContext, appContext) => {
    if (deviceContext.isPhone && deviceContext.isAndroid) {
        return encodeURI('https://play.google.com/store/apps/details?id=' + deviceContext.packageId);
    } else if (deviceContext.isPhone && deviceContext.isApple) {
        return encodeURI('https://apps.apple.com/us/app/' + appContext.application.branding.appleid); // speci
    } else if (deviceContext.isTablet && deviceContext.isAndroid) {
        return encodeURI('https://play.google.com/store/apps/details?id=' + deviceContext.packageId);
    } else if (deviceContext.isTablet && deviceContext.isApple) {
        return encodeURI('https://apps.apple.com/us/app/' + appContext.application.branding.appleid); // special id;
    } else if (deviceContext.isTelevision && deviceContext.isAndroid) {
        return encodeURI('https://play.google.com/store/apps/details?id=' + deviceContext.packageId);
    } else if (deviceContext.isTelevision && deviceContext.isApple) {
        return encodeURI('https://apps.apple.com/us/app/' + appContext.application.branding.appleid); // special id;
    } else if (deviceContext.isTizen) {
        return encodeURI('https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/' + deviceContext.tizenid);
    } else if (deviceContext.isWebos) {
        return encodeURI('https://us.lgappstv.com/' + appContext.application.branding.webosid); //special id
    } else if (deviceContext.isKaiOs) {
        return encodeURI('https://kaios.app/apps/' + appContext.application.branding.kaiosid); //special id
    } else if (deviceContext.isFireTV) {
        return encodeURI('https://www.amazon.com/gp/mas/dl/android?p=' + deviceContext.packageId);
    }
};

export const getCatchupLiveTVStream = (channel, program, stream) => {
    if (program != undefined) {
        var startTime = program?.s;
        if (channel.interactivetv.pausetv_enabled) {
            if (stream.indexOf('index.m3u8') > -1) {
                var replacePart = 'index-' + startTime + '-now.m3u8';
                stream = stream.replace('index.m3u8', replacePart);
            }
            if (stream.indexOf('video.m3u8') > -1) {
                var replacePart = 'video-' + startTime + '-now.m3u8';
                stream = stream.replace('video.m3u8', replacePart);
            }
            if (stream.indexOf('mono.m3u8') > -1) {
                var replacePart = 'mono-' + startTime + '-now.m3u8';
                stream = stream.replace('mono.m3u8', replacePart);
            }
            if (stream.indexOf('index.mpd') > -1) {
                var replacePart = 'index-' + startTime + '-now.mpd';
                stream = stream.replace('index.mpd', replacePart);
            }
            if (stream.indexOf('Manifest.mpd') > -1) {
                var replacePart = 'index-' + startTime + '-now.mpd';
                stream = stream.replace('Manifest.mpd', replacePart);
            }
        }
    }
    return stream;
};
export const getCatchupTVRecordingStream = (channel, program, stream) => {
    var startTime = program?.s;
    var endTime = program?.e;
    if (stream.indexOf('index.m3u8') > -1) {
        var replacePart = 'index-' + startTime + '-' + (endTime - startTime) + '.m3u8';
        stream = stream.replace('index.m3u8', replacePart);
    }
    if (stream.indexOf('video.m3u8') > -1) {
        var replacePart = 'index-' + startTime + '-' + (endTime - startTime) + '.m3u8';
        stream = stream.replace('video.m3u8', replacePart);
    }
    if (stream.indexOf('mono.m3u8') > -1) {
        var replacePart = 'index-' + startTime + '-' + (endTime - startTime) + '.m3u8';
        stream = stream.replace('mono.m3u8', replacePart);
    }
    if (stream.indexOf('index.mpd') > -1) {
        var replacePart = 'index-' + startTime + '-' + (endTime - startTime) + '.mpd';
        stream = stream.replace('index.mpd', replacePart);
    }
    if (stream.indexOf('Manifest.mpd') > -1) {
        var replacePart = 'index-' + startTime + '-' + (endTime - startTime) + '.mpd';
        stream = stream.replace('Manifest.mpd', replacePart);
    }
    if (stream.indexOf('?') > -1) {
        stream = stream + '&ignore_gaps=true';
    } else {
        stream = stream + '?ignore_gaps=true';
    }
    return stream;
};
