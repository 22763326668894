import { isPlatformAndroid, isPlatformAndroidtv, isPlatformFiretv, isPlatformIos, isPlatformTizen, isPlatformTvos, isPlatformWeb, isPlatformWebos } from '@rnv/renative';
import TVGuide, { TVGuideHandle } from '@scriptx-com/tv-guide';
import moment from 'moment';
import * as React from 'react';
import { ActivityIndicator, Dimensions, PixelRatio, TextStyle, View as RNView, Image } from 'react-native';
import { CoreManager, FocusContext, Pressable, setFocus, View } from '@scriptx-com/xtv-toolkit';
import { ContentContext } from '../../../../application/context/contentContext';
import useDataNormalizer from '../../../../application/hooks/useDataNormalizer';
import { getCorrectHeight, getCorrectWidth } from '../../../../application/styling/correctSizes';
import { getFontSize } from '../../../../application/styling/fontSizes';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';
import { useFocusEffect } from '../../../../screens/wrapper/useFocusEffect';
import { AppContext } from '../../../context/appContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { getEpgDataOtherDate } from '../../../data/content';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { useTVRemoteHandler } from '../../../hooks/useTVRemoteHandler';
import { TV_Guide_TV_Guide as styling } from '../../../models/elements/tvguide/tvguide/tvguide';
import Dropdown_ from '../../components/dropdown/dropdown';
import { getDropdownStyling, getFocusStyling } from '../../components/helpers/helper';
import { supportedFonts } from '../../components/text';
import { filterAgeRatings, getGridData } from '../grids/helpers/functions';
import { getCorrectCategories, getCorrectName } from '../players/helpers/functions';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ReminderContext } from '../../../context/reminderContext';

export function Ratio(pixels: number): number {
    if (!(isPlatformAndroidtv || isPlatformFiretv)) return pixels;
    const resolution = Dimensions.get('screen').height * PixelRatio.get();
    return Math.round(pixels / (resolution < 2160 ? 2 : 1));
}
const getCorrectFontProperty = ({ fontFamily, fontWeight }: { fontFamily: TextStyle['fontFamily']; fontWeight?: TextStyle['fontWeight'] }) => {
    let fixedFontFamily;

    if (fontWeight === 'bold' || (!isNaN(fontWeight as any) && fontWeight >= '600')) {
        if (supportedFonts.includes(`${fontFamily}-Bold`)) {
            fixedFontFamily = `${fontFamily}-Bold`;
        }
    } else if (supportedFonts.includes(fontFamily)) {
        return fontFamily;
    } else {
        if (supportedFonts.includes(`${fontFamily}-Regular`)) {
            fixedFontFamily = `${fontFamily}-Regular`;
        }
    }

    return fixedFontFamily;
};
export interface Data {
    image: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: FocusContext;
}

const removeColorAlpha = (color: string) => {
    if (isPlatformIos || isPlatformTvos) {
        if (color.startsWith('#') && color.length === 9) {
            return color.slice(0, -2);
        }
        if (color.startsWith('rgba')) {
            return color.replace(/[\d\.]+\)$/g, '1)');
        }
        return color;
    }
    return color;
};

const DEFAULT_TAB_BAR_HEIGHT = 50;

export const TVGuide_Horizontal = ({ styling, navigation, focusContext }: Props) => {
    const tvGuideRef = React.useRef<TVGuideHandle>(null);
    const backButtonShouldGoToHome = React.useRef(false);
    const wrapperRef = React.useRef<RNView>(null);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const reminderContext = React.useContext(ReminderContext);
    const [data, setData] = React.useState([]);
    const [dates, setDates] = React.useState([] as any);
    const [selectedDate, setSelectedDate] = React.useState(+new Date());
    const isLandscape = deviceContext.isPhone;
    const [orientationChanged, setOrientationChanged] = React.useState(isLandscape ? false : true);
    const { normalizedData, selectedDate: newDate } = useDataNormalizer(data, selectedDate);
    const insets = useSafeAreaInsets();
    const [offsetDay, setOffsetDay] = React.useState(0);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const [isDateDropdownOpen, setIsDateDropdownOpen] = React.useState(false);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = React.useState(false);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState('');

    const isDropdownOpenRef = React.useRef(false);

    React.useEffect(() => {
        if (isDateDropdownOpen || isCategoryDropdownOpen) isDropdownOpenRef.current = true;
        else isDropdownOpenRef.current = false;
    }, [isDateDropdownOpen, isCategoryDropdownOpen]);

    const [theme] = React.useState(() => {
        return {
            width: isLandscape ? Dimensions.get('window').height - DEFAULT_TAB_BAR_HEIGHT - insets.top : Dimensions.get('window').width - getCorrectWidth(deviceContext, global.menuWidth) - getCorrectWidth(deviceContext, 20),
            height: isLandscape ? Dimensions.get('window').width : Dimensions.get('window').height - getCorrectHeight(deviceContext, 50) - getCorrectHeight(deviceContext, 5) - getCorrectHeight(deviceContext, 5),
            backgroundColor: styling.tvguide.backgroundColor,

            timeline: {
                alignItems: isPlatformWeb ? 'left' : 'flex-start',
                marginHorizontal: getCorrectWidth(deviceContext, 10),
                height: getCorrectWidth(deviceContext, 40),
                fontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                fontSize: getFontSize(deviceContext, 'Normal'),
                backgroundColor: styling.tvguide.timeline.backgroundColor,
                color: styling.tvguide.timeline.color,
                borderRadius: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderColor: styling.tvguide.cell.backgroundColor,
            },
            cell: {
                backgroundColor: styling.tvguide.cell.backgroundColor,
                borderColor: styling.tvguide.cell.borderColor,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: styling.tvguide.cell.borderWidth,
                borderRightWidth: styling.tvguide.cell.borderWidth,
                height: getCorrectWidth(deviceContext, 50),
                titleColor: styling.tvguide.cell.titleColor,
                timeColor: styling.tvguide.cell.timeColor,
                titleFontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                subtitleFontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                borderRadius: styling.tvguide.cell.borderRadius,
                activeColor: styling.tvguide.cell.activeColor,
                padding: getCorrectWidth(deviceContext, 1),
                titleFontSize: getFontSize(deviceContext, 'Normal') * 1.3,
                titleFontType: 'bold',
                timeFontSize: getFontSize(deviceContext, 'Normal'),
                fontSize: getFontSize(deviceContext, 'Normal'),
            },
            cellLive: {
                imageWidth: getCorrectWidth(deviceContext, 40),
                height: getCorrectWidth(deviceContext, 50),
                backgroundColor: styling.tvguide.cellLive.backgroundColor,
                borderColor: styling.tvguide.cellLive.borderColor,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: styling.tvguide.cellLive.borderWidth,
                borderRightWidth: styling.tvguide.cellLive.borderWidth,
                color: styling.tvguide.cellLive.color,
                fontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                subtitleFontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                borderRadius: styling.tvguide.cellLive.borderRadius,
                activeColor: styling.tvguide.cellLive.activeColor,
                padding: getCorrectWidth(deviceContext, 1),
                titleFontSize: getFontSize(deviceContext, 'Large'),
                titleFontType: 'bold',
                titleColor: styling.tvguide.cellLive.color,
                timeColor: styling.tvguide.cellLive.color,
                timeFontSize: getFontSize(deviceContext, 'Normal'),
                fontSize: getFontSize(deviceContext, 'Normal'),
            },
            channelLine: {
                width: getCorrectWidth(deviceContext, 160),
                // channel line height is 50, so image is 45x45 to have 5 margin on sides
                imageWidth: getCorrectWidth(deviceContext, 45),
                imageHeight: getCorrectWidth(deviceContext, 45),
                height: getCorrectWidth(deviceContext, 50),
                channelFontSize: getFontSize(deviceContext, 'Normal'),
                backgroundColor: removeColorAlpha(styling.tvguide.channelLine.backgroundColor),
                fontFamily: deviceContext.isTablet && deviceContext.isAndroid ? deviceContext.fontType : getCorrectFontProperty({ fontFamily: deviceContext.fontType }),
                fontSize: getFontSize(deviceContext, 'Normal'), ///this one does nothing
                borderWidth: 2,
                padding: getCorrectWidth(deviceContext, 2),
                borderColor: styling.tvguide.cell.backgroundColor,
                textColor: styling.tvguide.channelLine.textColor,
                imageResizeMode: 'contain',
                textMarginLeft: Ratio(15),
            },
            marker: {
                backgroundColor: styling.tvguide.marker.backgroundColor,
                width: 4,
            },
            loader: {
                color: '#fff',
            },
            customDaySelectorHeight:
                isPlatformTizen || isPlatformWebos
                    ? getCorrectHeight(deviceContext, 50) + getCorrectHeight(deviceContext, styling.tvguide.margin_bottom) + getCorrectHeight(deviceContext, styling.tvguide.margin_top)
                    : getCorrectHeight(deviceContext, 30),
            daySelector: {
                height: 0,
            },
        };
    });
    const [loaded, setLoaded] = React.useState(false);

    useFocusEffect(
        React.useCallback(() => {
            setTimeout(() => {
                setFocus('dummy-focus-view');
            }, 100);
        }, [])
    );

    React.useEffect(() => {
        async function myAsynFunction() {
            var channels = await getGridData(userContext, 'Channels', contentContext, appContext);
            channels = filterAgeRatings(channels, userContext, appContext)
            setData(channelsCategoriesEpg(contentContext.epgTv, channels));
        }
        myAsynFunction();

        return () => {
            contentContext.setEpgOffSetDay(0);
        };
    }, []);

    React.useEffect(() => {
        const isFocusInsideTvGuide = !CoreManager.isKeyEventsEnabled();
        if (isFocusInsideTvGuide && reminderContext.currentReminder) {
            tvGuideRef.current?.disableTVGuideView();
            CoreManager.setKeyEventsEnabled(true);
        }
        return () => {
            if (reminderContext.currentReminder) {
                if (isFocusInsideTvGuide) {
                    tvGuideRef.current?.enableTVGuideView();
                    CoreManager.setKeyEventsEnabled(false);
                }
            }
        };
    }, [reminderContext.currentReminder?.channel._id]);

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const channelsCategoriesEpg = (epgtv, channels) => {
        var epg = [] as any;
        channels.forEach((channel) => {
            var epgFind = epgtv.find((e) => e.i == channel._id);
            if (epgFind != undefined) {
                epg.push(epgFind);
            }
        });
        return epg;
    };

    React.useEffect(() => {
        if (isLandscape && wrapperRef.current && insets && !orientationChanged) {
            setTimeout(() => {
                wrapperRef.current.measure((_, __, ___, ____, pageX, pageY) => {
                    wrapperRef.current.setNativeProps({
                        style: {
                            top: Math.round(Math.abs(pageX)) + (isPlatformIos ? insets.bottom : insets.top),
                            right: Math.round(Math.abs(pageY)),
                        },
                    });
                    setOrientationChanged(true);
                });
            }, 500);
        }
    }, [wrapperRef, insets]);

    useEffectOnce(() => {
        setFocus('dummy-focus-view');
        var max = 3;
        var min = (appContext.application.settings.epg_rentention - 1) * -1;
        var dates = [];
        const date = new Date();
        for (var i = min; i < max; i++) {
            dates.push({
                date: addDays(date, i),
            });
        }
        setDates(dates);

        setLoaded(true);
    });

    useTVRemoteHandler(({ eventType, eventKeyAction }) => {
        if (isDropdownOpenRef.current) return;
        if (eventKeyAction === 'down' && eventType === 'back' && tvGuideRef) {
            if (backButtonShouldGoToHome.current) {
                navigation.navigate('Home');
                contentContext.setActiveMenu('Home');
                return;
            }
            backButtonShouldGoToHome.current = true;
            tvGuideRef.current?.disableTVGuideView();
            CoreManager.setKeyEventsEnabled(true);
            setFocus('tv-guide-day-selector');
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            if (isDropdownOpenRef.current) return;
            const { keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 27 || keyCode == 461) {
                if (backButtonShouldGoToHome.current) {
                    navigation.navigate('Home');
                    contentContext.setActiveMenu('Home');
                    return;
                }
                backButtonShouldGoToHome.current = true;
                tvGuideRef.current?.disableTVGuideView();
                CoreManager.setKeyEventsEnabled(true);
                setFocus('tv-guide-day-selector');
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const showProgramInformation = (data: any) => {
        if (data != undefined) {
            if (data.program != undefined) {
                if (data.program.data != undefined && data.program.data.program_index != undefined) {
                    var epg = channelsCategoriesEpg(contentContext.epgTv, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels);
                    if (offsetDay != 0) {
                        epg = channelsCategoriesEpg(contentContext.epgTvExtra, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels);
                    }

                    //var indexes = findChannelIndexAndCategoryIndex(contentContext.channels.categories, contentContext.epgTv[data.program.data.channel_index].i);
                    contentContext.setChannelData({
                        ...contentContext.channelData,
                        channel: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels[data.program.data.channel_index],
                        channelIndex: data.program.data.channel_index,
                        channelList: contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels,

                        program: epg[data.program.data.channel_index]?.epg[data.program.data.program_index],
                        programIndex: data.program.data.program_index,

                        programList: epg[data.program.data.channel_index]?.epg,
                        programListIndex: data.program.data.program_index,
                    });

                    if (navigation && (isPlatformAndroidtv || isPlatformFiretv || isPlatformTizen || isPlatformWebos)) {
                        tvGuideRef.current?.disableTVGuideView();
                        CoreManager.setKeyEventsEnabled(true);
                    }

                    navigation && navigation.navigate('Program');
                }
            }
        }
    };

    const onSelectCategory = (e, i) => {
        if (isPlatformAndroid || isPlatformIos) {
            setSelectedCategoryIndex(i.toString());
        }
        setLoaded(false);
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channels.categories[i].channels,
            channelCategoryIndex: i,
            channelListCategoryIndex: i,
            localCategoryIndex: i,
        });
        if (offsetDay == 0) {
            setData(channelsCategoriesEpg(contentContext.epgTv, contentContext.channels.categories[i].channels));
        } else {
            setData(channelsCategoriesEpg(contentContext.epgTvExtra, contentContext.channels.categories[i].channels));
        }
        setLoaded(true);
        //same keydown event propagates to tv guide
        setTimeout(() => setFocus('dummy-focus-view'), 2);
    };
    const onSelectDate = async (e, i) => {
        if (e == selectedDate) {
            return;
        }
        setLoaded(false);
        setSelectedDate(+e);

        var day = Math.ceil(moment(e).diff(moment(), 'days', true));
        setOffsetDay(day);
        if (day != 0) {
            await getEpgDataOtherDate(appContext, contentContext, userContext, day).then((result) => {
                if (result) {
                    setTimeout(() => {
                        contentContext.setEpgTvExtra(result);
                        contentContext.setEpgOffSetDay(day);
                        setData(channelsCategoriesEpg(result, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels));
                        setLoaded(true);
                        setFocus('dummy-focus-view');
                    }, 1000);
                }
            });
        } else {
            contentContext.setEpgOffSetDay(0);
            setData(channelsCategoriesEpg(contentContext.epgTv, contentContext.channels.categories[contentContext.channelData.channelCategoryIndex].channels));
            setLoaded(true);
            setFocus('dummy-focus-view');
        }
    };

    const DateSelector = ({ onDateSelect, setIsLoading, dates }) => {
        return null;
    };

    const transform = isLandscape ? { transform: [{ rotate: '90deg' }] } : {};

    return (
        <View
            ref={wrapperRef}
            style={{
                ...transform,
                width: theme.width + getCorrectWidth(deviceContext, global.menuWidth),
                height: theme.height,
                paddingLeft: getCorrectWidth(deviceContext, styling.tvguide.margin_left ?? 0) + getCorrectWidth(deviceContext, global.menuWidth),
                marginRight: getCorrectWidth(deviceContext, styling.tvguide.margin_right ?? 0),
                marginTop: getCorrectHeight(deviceContext, styling.tvguide.margin_top == 0 ? 5 : styling.tvguide.margin_top ?? 5),
                marginBottom: getCorrectHeight(deviceContext, styling.tvguide.margin_bottom ?? 0),
            }}
            focusContext={focusContext}
        >
            <View
                pointerEvents={'none'}
                style={{
                    position: 'absolute',
                    width: getRealWidth(deviceContext),
                    height: '100%',
                    zIndex: 1,
                    opacity: 0.5,
                }}
            >
                <Image
                    source={require('../../../../assets/images/overlay-epg.png')}
                    resizeMode={'cover'}
                    style={{
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext),
                    }}
                ></Image>
            </View>
            <Pressable
                style={{ width: 1, height: 1, opacity: 0, top: 200, position: 'absolute', right: 0 }}
                focusOptions={{ focusKey: 'dummy-focus-view' }}
                onFocus={() => {
                    backButtonShouldGoToHome.current = false;
                    CoreManager.setKeyEventsEnabled(false);
                    tvGuideRef.current?.enableTVGuideView();
                }}
            />
            {!loaded && (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                </View>
            )}
            {loaded && orientationChanged && (
                <View>
                    <View
                        focusContext={focusContext}
                        style={{
                            flexDirection: 'row',
                            marginVertical: getCorrectWidth(deviceContext, 10),
                            height: getCorrectHeight(deviceContext, 30),
                            zIndex: 999,
                        }}
                    >
                        <View
                            style={{
                                marginRight: getCorrectWidth(deviceContext, 10),
                                // marginLeft: getCorrectWidth(deviceContext, 10),
                                flex: undefined,
                            }}
                        >
                            <Dropdown_
                                height={300}
                                width={getCorrectWidth(deviceContext, 200)}
                                setSelection={onSelectDate}
                                styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                animation={getFocusStyling('Dropdowns', appContext)}
                                value={moment(selectedDate).format('ll')}
                                dataIn={dates}
                                type={'Dates'}
                                onToggleDropdown={setIsDateDropdownOpen}
                                focusOptions={{
                                    focusKey: 'tv-guide-day-selector',
                                    nextFocusLeft: isDateDropdownOpen ? 'non-existing-key' : 'menu-drawer',
                                    nextFocusDown: isDateDropdownOpen ? 'non-existing-key' : 'dummy-focus-view',
                                }}
                            />
                        </View>
                        <View style={{ flex: undefined }}>
                            <Dropdown_
                                height={300}
                                width={getCorrectWidth(deviceContext, 200)}
                                setSelection={onSelectCategory}
                                styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                animation={getFocusStyling('Dropdowns', appContext)}
                                value={getCorrectName(contentContext.channels.categories[contentContext.channelData.channelCategoryIndex], userContext)}
                                dataIn={getCorrectCategories(contentContext.channels, userContext)}
                                onToggleDropdown={setIsCategoryDropdownOpen}
                                focusOptions={{
                                    nextFocusLeft: isCategoryDropdownOpen ? 'non-existing-key' : 'tv-guide-day-selector',
                                    nextFocusDown: isCategoryDropdownOpen ? 'non-existing-key' : 'dummy-focus-view',
                                }}
                                type={'Categories'}
                            />
                        </View>
                    </View>
                    <View
                        {...((isPlatformAndroid || isPlatformIos) && {
                            pointerEvents: isDateDropdownOpen || isCategoryDropdownOpen ? 'none' : undefined,
                        })}
                    >
                        <TVGuide
                            key={selectedCategoryIndex}
                            ref={tvGuideRef}
                            theme={theme}
                            data={normalizedData}
                            selectedDate={newDate}
                            onOutOfBounds={(direction) => {
                                if (direction === 'up') {
                                    tvGuideRef.current?.disableTVGuideView();
                                    CoreManager.setKeyEventsEnabled(true);
                                    setFocus('tv-guide-day-selector');

                                    // Prevent weird double focus
                                    if (isPlatformTizen || isPlatformWebos) {
                                        setTimeout(() => {
                                            tvGuideRef.current?.disableTVGuideView();
                                        }, 150);
                                    }
                                }
                                if (direction === 'left') {
                                    tvGuideRef.current?.disableTVGuideView();
                                    CoreManager.setKeyEventsEnabled(true);
                                    setFocus('menu-drawer');
                                }
                            }}
                            onCellPress={(data: any) => {
                                showProgramInformation(data);
                            }}
                            onCellFocus={(data: any) => {}}
                            renderDaySelector={(props) => <DateSelector {...props} />}
                            timeFormat={deviceContext.clockSetting == 'hh:mm A' ? 'regular' : 'military'}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};

export default TVGuide_Horizontal;
