import * as React from 'react';
import { View, CreateListRenderItemInfo, FlashList, FocusContext } from '@scriptx-com/xtv-toolkit';
import Menu from '../shared/menu';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { AppContext } from '../../../context/appContext';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { getFocusStyling } from '../../components/helpers/helper';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { GetScrollElement, GetTopPart } from './helpers/views';
import { EMPTY_DATA_ARRAY, extraTopBottomMargin, getRailsHeight, getRailsWidth } from './helpers/helper';
import useBaseRail from './useBaseRail';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    railIndex?: number;
}

export const Menu_Rails = React.memo(({ styling, focusContext, navigation, railIndex }: Props) => {
    const screenName = getCurrentScreenName();
    const [data, setData] = React.useState(screenName === 'Home' ? EMPTY_DATA_ARRAY : []);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement.height)).current : getCorrectHeight(deviceContext, styling.placement.height);
    if (height == 0 || isNaN(height)) {
        height = getCorrectHeight(deviceContext, 100);
    }
    if(deviceContext.formFactor == 'Phone'){
        height = height * 0.8
    }
    var width = (height / 9) * 16;

        //mobile width check
        if(width > getRealWidth(deviceContext) && deviceContext.formFactor == 'Phone'){
            height = ((getRealWidth(deviceContext) * 0.7) / 16) * 9;
            width = (height / 9) * 16;
        }

    const getData = async () => {
        if (styling.content.menu != undefined && styling.content.menu.length > 0) {
            setData(styling.content.menu);
            return;
        }
    };

    const { Loader, flashListProps, onViewableItemsChanged, scrollRight, scrollLeft, railsRef, viewableItemsCount } = useBaseRail({
        contentLoaderHeight: height,
        contentLoaderWidth: width,
        data,
        railIndex,
        railType: 'menu',
        getData,
        animator: getFocusStyling('Rails', appContext),
        isUserGeneratedListAndEmpty: false
    });

    const detailsItem = (item: any, index: any) => {
        navigation.navigate(item);
        contentContext.setActiveMenu(item);
    };

    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        if (userContext.userId.indexOf('_review') > -1 && item.name == 'Search') {
            return null;
        } else {
            return (
                <Menu
                    elementType={'Rails'}
                    styling={styling}
                    data={item}
                    height={height}
                    width={width}
                    focusRepeatContext={focusRepeatContext}
                    onPress={() => {
                        if (item.type !== 'empty') {
                            detailsItem(item, index);
                        }
                    }}
                    isFirst={index === 0}
                    isLast={data.length - 1 === index}
                ></Menu>
            );
        }
    };


    if (styling.content.type === 'Search' && data.length === 0) {
        return null;
    }



    return (
        <View focusContext={focusContext} style={{ paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
            <View
                style={{
                    flex: 1,
                    marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                    marginRight:  styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent' && deviceContext.formFactor != 'Phone' ? getCorrectWidth(deviceContext, 10) : 0,
                    marginTop: appContext.application.theme.rails_top_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext,styling),
                    marginBottom: appContext.application.theme.rails_bottom_margin / (deviceContext.formFactor == 'Phone' ? 2 : 1) + extraTopBottomMargin(deviceContext,styling),
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            backgroundColor: styling.placement.background_color,
                            height: styling.content.type === 'Search' && data.length === 0 ? 0 :  getRailsHeight(deviceContext, appContext, styling, height),
                            borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                            paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                            paddingHorizontal:
                                styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                    ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 5)
                                        : getCorrectWidth(deviceContext, styling.placement.padding)
                                    : 0,
         
                        }}
                    >
                        <GetTopPart
                            screenName={screenName}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            data={data.length >= 1 ? data : screenName === 'Home' ? [{ id: '', title: 'ok' }] : []}
                            focusContext={focusContext}
                            openOverviewPage={undefined}
                        ></GetTopPart>

                        <View style={{ flex: 1, width: getRailsWidth(deviceContext, appContext, styling, screenName)}} focusContext={focusContext}>
                            <View>{Loader()}</View>
                            {data != null && data.length > 0 && (
                                <FlashList
                                    type="row"
                                    ref={railsRef}
                                    data={data}
                                    onViewableItemsChanged={onViewableItemsChanged}
                                    estimatedItemSize={height}
                                    // drawDistance={height * 2}
                                    numColumns={1}
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={renderItem}
                                    initialScrollIndex={0}
                                    focusOptions={{
                                        autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                        autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                        ...flashListProps.focusOptions,
                                    }}
                                    {...flashListProps}
                                />
                            )}
                            {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
});
export default Menu_Rails;
