import * as React from 'react';
import { View, FocusContext } from '@scriptx-com/xtv-toolkit';
import { SearchBar as styling } from '../../../models/elements/misc/searchbar/searchbar';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { faSearch, faTrash } from '@fortawesome/pro-light-svg-icons';
import Input from '../../components/input/input';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import Pressable from '../../components/pressable/pressable';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { KeyboardContext } from '../../../context/keyboardContext';
import Text from '../../components/text';
import { BackHandler } from 'react-native';
import { useTVRemoteHandler } from '../../../hooks/useTVRemoteHandler';
import { AppContext } from '../../../context/appContext';
import { getPressableStyling, getFocusStyling, getPressableTextStyling, getInputStyling } from '../../components/helpers/helper';
import { CommonActions } from '@react-navigation/native';
import { getCurrentScreenName } from '../../../hooks/getScreenName';

export interface Data {
    items: [
        {
            square_image: string;
            name: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: styling;
    focusContext?: FocusContext;
    navigation: any;
}

export const SearchBar = ({ styling, focusContext, navigation }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const deviceContext = React.useContext(DeviceContext);
    const contextUser = React.useContext(UserContext);
    const [showKeyboard, setShowKeyboard] = React.useState(false);
    const [searches, setSearches] = React.useState(contextUser.profile.searches);
    const screenName = getCurrentScreenName();

    React.useEffect(() => {
        contentContext.setSearching(true);
        contentContext.setSearch(value);
    }, [value]);

    const setFilterLocal = (value: any) => {
        contentContext.setSearch(value);
    };
    const clearFilter = () => {
        contentContext.setSearch('');
    };

    var screenWidth = getRealWidth(deviceContext) - getCorrectWidth(deviceContext, styling.general.margin_left + styling.general.margin_right) - getCorrectWidth(deviceContext, global.menuWidth);

    const disableNativeHardwareBackPress = () => {
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    useTVRemoteHandler(
        ({ eventType, eventKeyAction }: any) => {
            if (!deviceContext.isWebTV && eventKeyAction === 'down') {
                if (['back', 'menu'].includes(eventType)) {
                    if (keyboardVisible) setKeyboardVisible(false);
                    else {
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            })
                        );
                        contentContext.setActiveMenu('Home');
                    }
                }
            }
        },
        [keyboardVisible]
    );

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        })
                    );
                    contentContext.setActiveMenu('Home');
                }
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, [keyboardVisible]);

    return (
        <View focusContext={focusContext} style={{ paddingLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
            <View
                style={{
                    height: getCorrectHeight(deviceContext, searches != undefined && searches.length > 0 ? 78 : 39),
                    backgroundColor: styling.general.background_color,
                    marginLeft: getCorrectWidth(deviceContext, styling.general.margin_left ?? 0),
                    marginRight: getCorrectWidth(deviceContext, styling.general.margin_right ?? 0),
                    marginTop: getCorrectHeight(deviceContext, styling.general.margin_top ?? 0),
                    marginBottom: getCorrectHeight(deviceContext, styling.general.margin_bottom ?? 0),
                    width: screenWidth,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        height: getCorrectHeight(deviceContext, 38),
                    }}
                >
                    {/* {deviceContext.isAndroidTV && (
                        <View style={{ marginRight: getCorrectHeight(deviceContext, 4) }}>
                            <Pressable
                                style={{
                                    height: getCorrectHeight(deviceContext, 33),
                                    width: getCorrectWidth(deviceContext, 37),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => clearFilter()}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMicrophone} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.5}></FontAwesomeIcon>
                                </View>
                            </Pressable>
                        </View>
                    )} */}
                    <Input
                        value={contentContext.search}
                        onChangText={(value) => setFilterLocal(value)}
                        icon={faSearch}
                        width={screenWidth}
                        isSecure={false}
                        placeholder={lang.getTranslation(contextUser, 'search')}
                        animation={getFocusStyling('Inputs', appContext)}
                        styling={getInputStyling(styling.components?.input?._id, appContext)}
                        focusOptions={{ focusKey: 'search-bar', hasPreferredFocus: screenName === 'Search' }}
                        onFocus={() => {
                            if (keyboardVisible) {
                                setKeyboardVisible(false);
                            }
                        }}
                        showKeyboard={() => {
                            setShowKeyboard(true);
                            openKeyboard({
                                focusOptions: { nextFocusUp: 'search-bar' },
                            });
                        }}
                    />
                    {/* <View>
                        <Pressable
                            style={{
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                height: getCorrectHeight(deviceContext, 31),
                                margin: getCorrectWidth(deviceContext, 4),
                            }}
                            focusContext={focusContext}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => clearFilter()}
                        >
                            <View
                                style={{
                                    width: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 40 : 84),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignSelf: 'center',
                                    flex: 1,
                                }}
                            >
                                {!deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                    <Text
                                        style={{
                                            paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                        }}
                                    >
                                        {lang.getTranslation(contextUser, 'clear_filter')}
                                    </Text>
                                )}
                                {(deviceContext.isPhone || deviceContext.isPwaVertical) && <FontAwesomeIcon icon={faTrash} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.2}></FontAwesomeIcon>}
                            </View>
                        </Pressable>
                    </View> */}
                </View>

                {searches != undefined && searches.length > 0 && (
                    <View
                        style={{
                            height: getCorrectHeight(deviceContext, 39),
                            backgroundColor: styling.general.background_color,
                            width: screenWidth,
                            flexDirection: 'row',
                        }}
                    >
                        {searches.map((search) => {
                            return (
                                <Pressable
                                    style={{
                                        ...getPressableStyling(styling.components?.button?._id, appContext),
                                        height: getCorrectHeight(deviceContext, 31),
                                        margin: getCorrectWidth(deviceContext, 4),
                                    }}
                                    focusContext={focusContext}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => setFilterLocal(search)}
                                >
                                    <View
                                        style={{
                                            width: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 40 : 84),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignSelf: 'center',
                                            flex: 1,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                paddingHorizontal: getCorrectWidth(deviceContext, 3),
                                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                            }}
                                        >
                                            {search}
                                        </Text>
                                    </View>
                                </Pressable>
                            );
                        })}
                    </View>
                )}
            </View>
        </View>
    );
};
export default SearchBar;
