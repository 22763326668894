import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useRef, useState } from 'react';
import Gradient from 'react-native-linear-gradient';
import { ScrollView as RNScrollView, BackHandler } from 'react-native';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { Animated, CoreManager, Image, Screen, ScrollView, setFocus, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import { Ratio } from '../../screens/wrapper/helpers/getFocusOptions';
import { CachingTaskView } from '../builder/components/downloadbutton/downloadbutton';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../builder/components/helpers/helper';
import Pressable from '../builder/components/pressable/pressable';
import Text from '../builder/components/text';
import { AppContext } from '../context/appContext';
import { ContentContext } from '../context/contentContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import utils from '../general/utils';
import LANG from '../languages/languages';
import Stacks from '../stacks/StackSelector';
import { getCorrectHeight, getCorrectWidth } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getMenuIcon } from '../styling/menuIcon';
import { createTVSideNavigator } from './helpers/SideNavigator';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from './helpers/helpers';
import { sendActionReport } from '../../reporting/apis/action';
import { actionType } from '../../reporting/models/apps/action';
import { updateDevicesJson } from '../data/account';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons';
import { colorShade } from '../styling/colorShade';

const LinearGradient = withParentContextMapper(Gradient);
const SideNavigator = createTVSideNavigator();
const Stack = createNativeStackNavigator();
const AnimatedText = Animated.createAnimatedComponent(Text);
const AnimatedGradient = Animated.createAnimatedComponent(LinearGradient);
const TRANSLATE_VAL_HIDDEN = Ratio(-500);

const settingsRoutes = ['Manual', 'Wallet', 'Subscription', 'Messages', 'Support', 'About', 'Devices', 'Updates', 'Disclaimer'];

const Menu = ({ navigation }: { navigation: any }) => {
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    const scrollViewRef = React.useRef<RNScrollView>();
    const nativeOnBackFired = React.useRef<boolean>();

    var styling = [] as any;
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    global.menuWidth = 50;
    global.menuHeight = 0;
    global.settingsMenu = 230;
    const [menuOpen, setMenuOpen] = useState(false);
    const [showLogo, setShowLogo] = useState(false);

    const translateBgAnim = useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current;
    const opacityAnim = [useRef(new Animated.Value(0)).current];

    const translateTextAnim = [useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current];
    // @ts-ignore
    const timing = (object: Animated.AnimatedValue, toValue: number, duration = 150): Animated.CompositeAnimation => {
        return Animated.timing(object, {
            toValue,
            duration,
            useNativeDriver: true,
        });
    };

    React.useEffect(() => {
        appContext.isMenuDrawerOpen.current = menuOpen;
    }, [menuOpen]);

    const onFocus = () => {
        setMenuOpen(true);
        setShowLogo(true);
        Animated.parallel([timing(translateBgAnim, 1), timing(opacityAnim[0], 1, 500), timing(translateTextAnim[0], 0, 500)]).start();
    };
    const onBlur = () => {
        setShowLogo(false);
        setMenuOpen(false);
        Animated.parallel([timing(translateBgAnim, TRANSLATE_VAL_HIDDEN), timing(opacityAnim[0], 0, 100), timing(translateTextAnim[0], TRANSLATE_VAL_HIDDEN)]).start();
    };

    const [activeSettings, setActiveSettings] = useState('');

    React.useEffect(() => {
        if (contentContext.activeMenu === 'Switch Profile') {
            setShowLogo(false);
            setMenuOpen(false);
            translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
            opacityAnim[0].setValue(0);
            translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
        }
    }, [contentContext.activeMenu]);

    React.useEffect(() => {
        // For settings screens only
        const handler = BackHandler.addEventListener('hardwareBackPress', () => {
            if (settingsRoutes.find((r) => contentContext.activeMenu === r)) {
                if (!menuOpen) {
                    setMenuOpen(true);
                    setFocus('menu-drawer');
                } else {
                    setActiveSettings('');
                    contentContext.setActiveMenu('Account');
                    CoreManager.setFocus(`menuitem-Account`);
                    scrollViewRef.current?.scrollTo({ y: 0 });
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Home' }],
                        })
                    );
                }
                return true;
            } else {
                onBlur();
            }
            return false;
        });

        return () => handler.remove();
    }, [contentContext.activeMenu, menuOpen]);

    React.useEffect(() => {
        const screen = CoreManager.getElementByFocusKey('menu-drawer', 'screen');
        const view = CoreManager.getElementByFocusKey(`menuitem-${contentContext.activeMenu}`, 'view');

        if (screen && view) {
            screen.setCurrentFocus(view);
        }

        if (contentContext.activeMenu === 'Home') {
            scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
        }
    }, [contentContext.activeMenu]);

    const onPress = (menu: any) => {
        nativeOnBackFired.current = false;
        if (menu != 'Account' && activeSettings == '') {
            onBlur();

            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        } else {
            if (menu == 'Back') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                contentContext.setActiveMenu('Account');
                CoreManager.setFocus(`menuitem-Account`);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Home' }],
                    })
                );
            } else if (menu == 'Account') {
                contentContext.setActiveMenu('Manual');
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Settings' }],
                    })
                );
            } else if (menu == 'Switch Profile') {
                onBlur();
                contentContext.setActiveMenu('Switch Profile');
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles' }],
                    })
                );
            } else if (menu == 'Logout') {
                onBlur();
                setActiveSettings('');
                setShowLogo(false);

                translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
                opacityAnim[0].setValue(0);
                translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
                settingsContext.setShowMainMenu(false);

                setMenuOpen(false);
                userContext.setAutoLogin(false);
                utils.storeJson('AutoLogin', false);

                userContext.setAllProfiles([]);

                //delete current device
                var devices = userContext.devices;
                sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_DEVICE);
                var index = devices.findIndex((d) => d.uuid == deviceContext.uniqueId);
                if (index != -1) {
                    devices.splice(index, 1);
                    updateDevicesJson(appContext, userContext, devices);
                }

                setTimeout(() => {
                    if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                        userContext.setAppId('');
                        utils.storeJson('ServiceID', '');

                        userContext.setUserId('');
                        utils.storeJson('UserID', '');
                        userContext.setPass('');
                        utils.storeJson('Pass', '');
                    }

                    if (appContext.application.settings?.enable_nested == true) {
                        contentContext.setActiveMenu('Services');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else if (appContext.application?.settings.enable_anonymous == true) {
                        contentContext.setActiveMenu('Anonymous');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Anonymous' }],
                            })
                        );
                    } else {
                        contentContext.setActiveMenu('Login');
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login' }],
                            })
                        );
                    }
                }, 1000);
            } else if (menu == 'Refresh') {
                setActiveSettings('');
                setShowLogo(false);
                setMenuOpen(false);
                translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
                opacityAnim[0].setValue(0);
                translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
                settingsContext.setShowMainMenu(false);
                contentContext.setActiveMenu('Loader');
                onBlur();
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader' }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation && navigation.push(menu);
            }
        }
    };

    const renderMenuButton = (item, index, prev, next) => (
        <View style={{ marginBottom: item.menu == 'Account' || item.menu == 'Switch Profile' ? getCorrectHeight(deviceContext, 35) : 0 }}>
            <Pressable
                style={{
                    alignSelf: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: getCorrectWidth(deviceContext, 40),
                    width: showLogo ? getCorrectWidth(deviceContext, 190) : getCorrectWidth(deviceContext, 40),
                    borderRadius: showLogo ? getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0 : getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                    backgroundColor: 'transparent',
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                key={index}
                onPress={() => onPress(item.menu)}
                onMouseOver={() => onFocus()}
                onMouseOut={() => onBlur()}
                focusOptions={{
                    focusKey: `menuitem-${item.menu}`,
                    hasPreferredFocus: item.menu === 'Home' ? true : false,
                    nextFocusDown: next ? `menuitem-${next.menu}` : undefined,
                    nextFocusUp: prev ? `menuitem-${prev.menu}` : undefined,
                }}
            >
                <View
                    style={{
                        flexGrow: 1,
                        alignSelf: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 40),
                            height: getCorrectHeight(deviceContext, 35),
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',

                            borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0,
                            backgroundColor: item.name[0].toUpperCase() + item.name.slice(1) == contentContext.activeMenu ? getSelectedStyling('Navigators', appContext).background_color : 'transparent',
                            zIndex: 999,
                        }}
                    >
                        {(item.menu == 'Account' || item.menu == 'Switch Profile') && (
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'cover'}
                                source={{ uri: userContext.profile.avatar }}
                                style={{
                                    width: getCorrectWidth(deviceContext, 30),
                                    height: getCorrectWidth(deviceContext, 30),
                                    borderRadius: 100,
                                    //borderColor: '#fff',
                                    //borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 2) : getCorrectWidth(deviceContext, 1),
                                }}
                            ></Image>
                        )}
                        {downloadStatus == 'loading' && item.menu == 'Downloads' && (
                            <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 15), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                            </View>
                        )}
                        {item.menu != 'Account' && item.menu != 'Switch Profile' && item.menu != 'Wallet' && item.menu != 'Search' && (
                            <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon(item.menu, userContext)} />
                        )}
                        {item.menu != 'Account' && item.menu != 'Switch Profile' && (item.menu == 'Wallet' || item.menu == 'Search') && (
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: activeSettings == '' ? colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95) : 'transparent',
                                    borderRadius: 30,
                                    width: getCorrectWidth(deviceContext, 30),
                                    height: getCorrectWidth(deviceContext, 30),
                                }}
                            >
                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon(item.menu, userContext)} />
                            </View>
                        )}
                    </View>

                    <AnimatedText
                        numberOfLines={1}
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            marginLeft: getCorrectWidth(deviceContext, 10),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            transform: [{ translateX: translateTextAnim[0] }],
                            opacity: opacityAnim[item.index],
                            borderBottomColor: 'transparent',
                            paddingVertical: getCorrectHeight(deviceContext, 4),
                            zIndex: 1,
                        }}
                    >
                        {LANG.getTranslation(userContext, item.name)} {userContext?.product?.settings?.wallet_enabled && item.menu == 'Wallet' ? '(' + userContext.walletBalance + ')' : ''}
                    </AnimatedText>
                </View>
            </Pressable>
        </View>
    );

    const renderMenuButtons = () => {
        var menuArray = [] as any;
        // if (activeSettings == '') {
        menuArray = [{ name: 'account', menu: 'Account' }];
        appContext.application.menus.forEach((element) => {
            if (
                element.name != 'Casting' &&
                element.name != 'Hamburger' &&
                element.name != 'Logout' &&
                element.name != 'Youtube' &&
                element.name != 'Services' &&
                element.name != 'Disclaimer' &&
                element.name != 'Signin' &&
                element.name != 'Forgot' &&
                element.name != 'Check' &&
                element.name != 'Player' &&
                element.name != 'Profiles' &&
                element.name != 'Languages' &&
                element.name != 'Settings' &&
                element.name != 'Messages' &&
                element.name != 'Support' &&
                element.name != 'Album_Details' &&
                element.name != 'Album_Player' &&
                element.name != 'Podcast_Details' &&
                element.name != 'Podcast_Player' &&
                element.name != 'Loader' &&
                element.name != 'Programs'
            ) {
                if (
                    (userContext.userId.indexOf('_review') > -1 && element.name == 'Search') ||
                    (userContext.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                    (userContext.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                    (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                    (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                    (checkIfMenuHasWebview(appContext, element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                ) {
                    //do nothing
                } else {
                    var menu_ = { name: element.name, menu: element.name };
                    menuArray.push(menu_);
                }
            }
        });

        if (userContext?.product?.settings?.wallet_enabled) {
            menuArray.push({ name: 'wallet', menu: 'Wallet' });
        }

        var first = 'Wallet';
        menuArray.sort(function (x, y) {
            return x.menu == first ? -1 : y.menu == first ? 1 : 0;
        });

        var first = 'Switch Profile';
        menuArray.sort(function (x, y) {
            return x.menu == first ? -1 : y.menu == first ? 1 : 0;
        });

        var first = 'Search';
        menuArray.sort(function (x, y) {
            return x.menu == first ? -1 : y.menu == first ? 1 : 0;
        });

        var menu = [];
        menuArray.map((item, index) => {
            if (item.name != undefined) {
                menu.push(renderMenuButton(item, index, menuArray[index - 1], menuArray[index + 1]));
            }
        });
        return menu;
    };

    //React.useEffect(() => {}, [settingsContext.showMainMenu]);

    if (settingsContext.showMainMenu) {
        return (
            <Screen
                style={{
                    height: '100%',
                    position: 'absolute',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                focusOptions={{
                    // screenState: "foreground",
                    stealFocus: false,
                    group: 'menu-drawer',
                    verticalViewportOffset: Ratio(100),
                    focusKey: 'menu-drawer',
                    nextFocusRight: ['tv-guide-day-selector', 'content-screen', 'settings-buttons'],
                    autoFocusEnabled: false,
                    ignoreInitialFocus: true,
                }}
            >
                {!menuOpen && (
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 50),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    ></View>
                )}
                {menuOpen && (
                    <LinearGradient
                        style={{
                            backgroundColor: 'transparent',
                            width: getCorrectWidth(deviceContext, 200),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        locations={[0.2, 0.7]}
                        colors={[styling.general.background_color1, styling.general.background_color2]}
                        start={{ x: 0, y: 1 }}
                        end={{ x: 1, y: 1 }}
                    />
                )}

                <AnimatedGradient
                    style={[
                        {
                            width: getCorrectWidth(deviceContext, 200),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            transform: [{ translateX: translateBgAnim }],
                        },
                    ]}
                    locations={[0.0, 0.8]}
                    colors={[styling.general.background_color1, styling.general.background_color2]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                />
                <View
                    style={[
                        {
                            width: menuOpen ? getCorrectWidth(deviceContext, 200) : getCorrectWidth(deviceContext, 50),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            //borderRightColor: styling.general.background_closed,
                            //borderRightWidth: 0,
                            backgroundColor: styling.general.background_closed,
                        },
                    ]}
                >
                    <View
                        style={{
                            height: getCorrectHeight(deviceContext, 65),
                            width: menuOpen ? getCorrectWidth(deviceContext, 200) : getCorrectWidth(deviceContext, 50),
                            paddingTop: showLogo ? 0 : getCorrectHeight(deviceContext, 10),
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'row',
                            // backgroundColor: showLogo ? colorShade(styling.general.background_closed, 10) : styling.general.background_closed,
                            alignItems: showLogo ? 'center' : 'flex-start',
                            //borderRightColor: styling.general.background_color1,
                            //borderRightWidth:0,
                        }}
                    >
                        {showLogo && (
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                style={{
                                    width: getCorrectWidth(deviceContext, 150),
                                    height: getCorrectWidth(deviceContext, 50),
                                }}
                                source={{ uri: appContext.application.branding.logos.logo }}
                            />
                        )}
                        {!showLogo && (
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                style={{
                                    width: getCorrectWidth(deviceContext, 25),
                                    height: getCorrectWidth(deviceContext, 25),
                                }}
                                source={{ uri: appContext.application.branding.logos.icon }}
                            />
                        )}
                    </View>

                    <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
                        {renderMenuButtons()}
                    </ScrollView>
                    <View
                        style={{
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowDown} size={getFontSize(deviceContext, 'Normal')} color={colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95)}></FontAwesomeIcon>
                    </View>
                </View>
            </Screen>
        );
    } else {
        return null;
    }
};

function SideNavigation() {
    return (
        <SideNavigator.Navigator drawerContent={(props: any) => <Menu {...props} />}>
            <SideNavigator.Screen name={'AppStack'} component={Stacks} />
        </SideNavigator.Navigator>
    );
}

export default function Main() {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Main" component={SideNavigation} />
        </Stack.Navigator>
    );
}
