import { CreateListRenderItemInfo, FlashList, View, Image as ImageNormal, ScrollView as CreateScrollView } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getDropdownStyling, getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../../components/helpers/helper';
import Pressable from '../../../components/pressable/pressable';
import Text from '../../../components/text/index';
import { getFontSize } from '../../../../styling/fontSizes';
import lang from '../../../../languages/languages';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft, faClapperboardPlay, faHeart, faPlus, faCheck, faTrash, faRotateLeft, faRecordVinyl, faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { favoriteStatus, payperviewStatus, recordStatus, remindStatus, watchingStatus } from '../../../../data/account';
import moment from 'moment';
import { checkMaxDays } from '../../players/helpers/functions';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { RemoteImageHeightWidth } from '../../../components/remoteimage/remoteimage';
import Image from '../../../components/image/image';
import { ImageFadeZoom } from '../../../animations/imageFadeZoom';
import DownloadButton from '../../../components/downloadbutton/downloadbutton';
import React from 'react';
import { ScrollView as RNGHScrollView } from 'react-native-gesture-handler';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { colorShade } from '../../../../styling/colorShade';
import Dropdown_ from '../../../components/dropdown/dropdown';


const ScrollView = isPlatformAndroid ? RNGHScrollView : CreateScrollView;

export const BackgroundImage = ({ item, type, deviceContext, appContext, contentContext }) => {
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 1,
                top: 10,
            }}
        >
            <View
                style={{
                    width: getRealWidth(deviceContext),
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {type == 'Program' && getProgramImage(contentContext.channelData?.program, contentContext.channelData?.channel, deviceContext, appContext)}
                {type == 'Recording' && getProgramImage(contentContext.recordingData?.program, contentContext.recordingData?.channel, deviceContext, appContext)}
                {type == 'Movie' && getMovieImage(item, deviceContext, appContext)}
                {type == 'Seasons' && getSeriesImage(item, deviceContext, appContext, contentContext)}
                {type == 'Course' && getCourseImage(item, deviceContext, appContext)}
                {type == 'Short' && (
                    <ImageFadeZoom
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                        source={{
                            uri: item.images?.background,
                        }}
                    />
                )}
             
            </View>
        </View>
    );
};
export const PayPerViewStatus = ({ deviceContext, userContext, appContext, styling, item, type }) => {
    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;

    var remainingPayPerViewDays = 0;
    if (isPayPerView) {
        var now = moment().unix();
        var diff = now - payperviewStatus(type, item._id, userContext).timePayPerViewStart;
        remainingPayPerViewDays = item.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
        if (remainingPayPerViewDays <= 0) {
            isPayPerView = false;
        }
    }
    return (
        <View style={{ position: 'absolute', zIndex: 999, top: 0, margin: getCorrectWidth(deviceContext, 10) }}>
            {isPayPerViewEnabled && isPayPerView && (
                <View
                    style={{
                        height: getCorrectHeight(deviceContext, 40),
                        width: getCorrectHeight(deviceContext, 40),
                        ...getPressableStyling(styling.components?.button?._id, appContext),
                        backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                        borderRadius: 100,
                        justifyContent: 'center',
                    }}
                >
                    <Text
                        style={{
                            textAlign: 'center',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontWeight: 'bold',
                        }}
                    >
                        {remainingPayPerViewDays} {lang.getTranslation(deviceContext, 'days')}
                    </Text>
                    <Text
                        style={{
                            textAlign: 'center',
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            fontWeight: 'bold',
                        }}
                    >
                        {lang.getTranslation(deviceContext, 'remaining')}
                    </Text>
                </View>
            )}
        </View>
    );
};
export const LogoRow = ({ item, type, deviceContext, appContext, contentContext, styling }) => {
    if (type == 'Program') {
        return (
            <View style={{ flexDirection: 'column' }}>
                <View
                    style={{
                        backgroundColor: getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,
                        borderRadius: 100,
                        flexDirection: 'row',
                        width: getCorrectWidth(deviceContext, 50),
                        height: getCorrectWidth(deviceContext, 50),
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}
                >
                    <Image
                        isLocal={item.images?.square.indexOf('https://') > -1}
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        source={{ uri: contentContext.channelData.channel?.images?.square.indexOf('https://') == -1 ? appContext.cloudUrl + contentContext.channelData.channel?.images?.square : contentContext.channelData.channel?.images?.square }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                            borderRadius: 100,
                        }}
                    ></Image>
                </View>
                <View>
                    {item.program?.l != undefined && item.program?.l != '' && (
                        <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 5) }}>
                            <RemoteImageHeightWidth uri={item.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.6} desiredHeight={0} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(item.program?.l == undefined || item.program?.l == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column' }}>
                <View>
                    <Image
                        isLocal={item.images?.widescreen.indexOf('https://') > -1}
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'contain'}
                        source={{ uri: appContext.cloudUrl + contentContext.recordingData.channel.images?.square }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    ></Image>
                </View>
                <View>
                    {contentContext.recordingData.program?.l != undefined && contentContext.recordingData.program?.l != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <RemoteImageHeightWidth uri={contentContext.recordingData.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={50} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(contentContext.recordingData.program?.l == undefined || contentContext.recordingData.program?.l == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.recordingData.program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
        return (
            <View style={{ flexDirection: 'column' }}>
                <View>
                    {item.images.logo != undefined && item.images.logo != '' && (
                        <View style={{ justifyContent: 'center', paddingVertical: getCorrectWidth(deviceContext, 5) }}>
                            <RemoteImageHeightWidth
                                uri={item?.images?.logo.indexOf('https://') == -1 ? appContext.cloudUrl + item?.images.logo : item.images.logo}
                                desiredWidth={getRealWidth(deviceContext) * 0.5}
                                desiredHeight={50}
                                deviceContext={deviceContext}
                            />
                        </View>
                    )}
                    {(item.images.logo == undefined || item.images.logo == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    }
};
export const StatusTimeRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Program') {
        var currentTime = moment().unix();
        var isLive = item.program.s < currentTime && item.program.e > currentTime;
        var isPast = item.program.s < currentTime && item.program.e < currentTime;
        var isRecorded = recordStatus(item.program.i, userContext);
        var isReminded = remindStatus(item.program.i, userContext);
        var recordingDone = false;
        var recordingPlanned = false;
        var recordingProgress = false;
        var currentTime = moment().unix();
        if (currentTime > item.program?.e) {
            recordingDone = true;
        } else if (currentTime > item.program?.s && currentTime < item.program?.e) {
            recordingProgress = true;
        } else {
            recordingPlanned = true;
        }
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    {isLive && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    fontWeight: 'bold',
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingProgress && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recording').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingPlanned && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'planned').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && recordingDone && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isReminded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    fontWeight: 'bold',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    fontWeight: 'bold',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: '#4169e1',
                                    fontWeight: 'bold',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'restart').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {(isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || isReminded) && (
                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>
                    )}
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(item.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(item.program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                        <Text
                            style={{
                                backgroundColor: 'crimson',
                                fontWeight: 'bold',
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                        <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                    </View>
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(contentContext.recordingData.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.recordingData.program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const MetaRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Program') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20), marginTop: getCorrectWidth(deviceContext, 5) }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 10) }}>
                    {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <View>{getTagsChannels(item.program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                    {item.program?.p != undefined && item.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.program?.p != undefined && item.program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {item.program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20), marginTop: getCorrectWidth(deviceContext, 5) }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 10) }}>
                    {contentContext.recordingData.program.mt?.ye != undefined && contentContext.recordingData.program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {contentContext.recordingData.program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {contentContext.recordingData.program.mt?.ye != undefined &&
                        contentContext.recordingData.program.mt?.ye != '' &&
                        contentContext.recordingData.program?.mt?.ge != undefined &&
                        contentContext.recordingData.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {contentContext.recordingData.program?.mt?.ge != undefined && contentContext.recordingData.program?.mt?.ge.length > 0 && (
                        <View>{getTagsChannels(contentContext.recordingData.program?.mt?.ge, appContext, deviceContext, userContext)}</View>
                    )}

                    {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {contentContext.recordingData.program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20), marginTop: getCorrectWidth(deviceContext, 5) }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 10) }}>
                    {item.released != undefined && item.released != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.released}
                            </Text>
                        </View>
                    )}
                    {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.tags != undefined && item.tags.length > 0 && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                    {item.tags != undefined && item.tags.length > 0 && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.runtime != undefined && item.runtime != '' && (
                        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                {'h:'}
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                {'m'}
                            </Text>
                        </View>
                    )}
                    {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.rating != undefined && item.rating != '' && (
                        <Text
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {item.rating}
                        </Text>
                    )}
                    {item.parental != undefined && item.parental.rating != undefined && item.parental.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.parental != undefined && item.parental.rating != undefined && item.parental.rating != '' && <View>{getRatings(item.parental.rating, appContext, deviceContext, userContext)}</View>}
                </View>
            </View>
        );
    }
};
export const DescriptionRow = ({ item, type, deviceContext, userContext, contentContext, appContext, styling }) => {
    if (type == 'Program') {
        return (
            <View
                style={{
                    flexDirection: 'column',
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30),
                    paddingBottom: getCorrectWidth(deviceContext, 10),
                    maxHeight: getCorrectHeight(deviceContext, 50),
                }}
            >
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                            color: styling.texts.subtext_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {item.program?.d}
                    </Text>
                </ScrollView>
            </View>
        );
    } else if (type == 'Recording') {
        return (
            <View
                style={{
                    flexDirection: 'column',
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30),
                    paddingBottom: getCorrectWidth(deviceContext, 10),
                    maxHeight: getCorrectHeight(deviceContext, 50),
                }}
            >
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                            color: styling.texts.subtext_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                        }}
                    >
                        {contentContext.recordingData.program?.d}
                    </Text>
                </ScrollView>
            </View>
        );
    } else if (type == 'Movie' || type == 'Course' || type == 'Short') {
        return (
            <View
                style={{
                    flexDirection: 'column',
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30),
                    paddingBottom: getCorrectWidth(deviceContext, 10),
                    maxHeight: getCorrectHeight(deviceContext, 50),
                }}
            >
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations[0]?.description}
                        </Text>
                    )}
                </ScrollView>
            </View>
        );
    } else if (type == 'Seasons') {
        var watchlist = watchingStatus(type, item._id, userContext);
        var episodeIndex = 0;
        var seasonIndex = 0;
        if (watchlist != undefined) {
            episodeIndex = watchlist.continue.episode_index ?? 0;
            seasonIndex = watchlist.continue.season_index ?? 0;
        }
        return (
            <View
                style={{
                    flexDirection: 'column',
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30),
                    paddingBottom: getCorrectWidth(deviceContext, 10),
                    maxHeight: getCorrectHeight(deviceContext, 50),
                }}
            >
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations?.length > 0 && (
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations[0]?.description}
                        </Text>
                    )}
                </ScrollView>
            </View>
        );
    }
};
export const ProgressRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    var progress = 0;
    var remaining = 0;
    if (type == 'Program') {
        var currentTime = moment().unix();
        var totalProgram = item.program?.e - item.program?.s;
        progress = ((currentTime - item.program?.s) / totalProgram) * 100;
        var isLive = item.program.s < currentTime && item.program.e > currentTime;
        remaining = totalProgram - (currentTime - item.program?.s);
        if (!isLive) {
            progress = 0;
        }
    }
    if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Recording') {
        var watching = watchingStatus(type, item._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            progress = Math.round((watching.position / watching.duration) * 100);
            remaining = watching.duration - watching.position;
        }
    }
    if (type == 'Seasons') {
        //
    }
    var width = getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20);
    if (progress > 0 && remaining > 0) {
        return (
            <View style={{ flexDirection: 'column', width: width }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: appContext.application.theme.progresses.base,
                            height: getCorrectHeight(deviceContext, 2),
                            flex: 7,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: appContext.application.theme.progresses.progress,
                                height: getCorrectHeight(deviceContext, 2),
                                width: progress + '%',
                            }}
                        ></View>
                    </View>
                    <View style={{ flex: 3, alignItems: 'flex-end' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                            }}
                        >
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('H')}
                            {'h:'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('mm')}
                            {'m'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('ss')}
                            {'s'} {lang.getTranslation(userContext, 'left')}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const ButtonRowTop = ({ deviceContext, appContext, userContext, styling, onPressBack, onPressShare, item }) => {
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 9999,
                left: getCorrectWidth(deviceContext, 10),
                right: getCorrectWidth(deviceContext, 10),
                top: isPlatformIos ? 20 : 20,
                flexDirection: 'row',
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
            }}
        >
            <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <ButtonIconTop
                    color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                    deviceContext={deviceContext}
                    appContext={appContext}
                    styling={styling}
                    onPress={onPressBack}
                    icon={faArrowLeft}
                    text={''}
                    userContext={userContext}
                ></ButtonIconTop>
            </View>
            <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ marginRight: 5 }}>
                    {deviceContext.isPhone && item?.share != undefined && item?.share == true && (
                        <ButtonIconTop
                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={onPressShare}
                            icon={faShareNodes}
                            text={''}
                            userContext={userContext}
                        ></ButtonIconTop>
                    )}
                </View>
            </View>
        </View>
    );
};
export const ButtonRowBig = ({
    item,
    type,
    deviceContext,
    appContext,
    userContext,
    contentContext,
    styling,
    onPressPlay,
    onPressPurchase,
    onPressContinue,
    onPressRemind,
    onPressPlayCatchupTV,
    onPressPlaySeason,
    onPressRecordingDelete,
    onPressPlayRecording,
}) => {
    var watchlist = watchingStatus(type, item._id, userContext);
    var hasProgress = watchlist != undefined && watchlist.duration - watchlist.position > 1 ? true : false;
    var progress = watchlist?.position;
    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;

    var remainingPayPerViewDays = 0;
    if (isPayPerView) {
        var now = moment().unix();
        var diff = now - payperviewStatus(type, item._id, userContext).timePayPerViewStart;
        remainingPayPerViewDays = item.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
        if (remainingPayPerViewDays <= 0) {
            isPayPerView = false;
        }
    }

    switch (type) {
        case 'Movie':
            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlay(item)} text={'play_movie'}></ButtonTextPlusIcon>
                        )}

                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                seasonIndex={undefined}
                                episodeIndex={undefined}
                                lessonIndex={undefined}
                                progress={0}
                            ></ButtonContinuePlusIcon>
                        )}

                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={onPressPurchase} text={'purchase_option'}></ButtonTextPlusIcon>
                        )}
                        {!hasProgress && isPayPerView && (
                            <ButtonPayPerViewPlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressPlay(item)}
                                text={'play_movie'}
                                rule={remainingPayPerViewDays + ' ' + lang.getTranslation(deviceContext, item.payperview.rule.access_type.toLowerCase() + 's') + ' ' + lang.getTranslation(deviceContext, 'remaining')}
                            ></ButtonPayPerViewPlusIcon>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Course':
            var lessonIndex = 0;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.lesson_index ?? 0;
            }
            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlay(item)} text={'play_course'}></ButtonTextPlusIcon>
                        )}

                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                seasonIndex={undefined}
                                episodeIndex={undefined}
                                lessonIndex={lessonIndex}
                                progress={0}
                            ></ButtonContinuePlusIcon>
                        )}

                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                //ule={remainingPayPerViewDays + ' ' + lang.getTranslation(deviceContext, item.payperview.rule.access_type.toLowerCase() + 's') + ' ' + lang.getTranslation(deviceContext, 'remaining')}
                                text={'purchase_option'}
                            ></ButtonTextPlusIcon>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Short':
            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlay(item)} text={'play_short'}></ButtonTextPlusIcon>
                        )}

                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressContinue(item)}
                                text={'continue'}
                                seasonIndex={undefined}
                                episodeIndex={undefined}
                                lessonIndex={undefined}
                                progress={0}
                            ></ButtonContinuePlusIcon>
                        )}

                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={onPressPurchase}
                                //   rule={remainingPayPerViewDays + ' ' + lang.getTranslation(deviceContext, item.payperview.rule.access_type.toLowerCase() + 's') + ' ' + lang.getTranslation(deviceContext, 'remaining')}
                                text={'purchase_option'}
                            ></ButtonTextPlusIcon>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Seasons':
            var episodeIndex = 0;
            var seasonIndex = 0;
            hasProgress = watchlist?.continue != undefined;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.episode_index ?? 0;
                seasonIndex = watchlist.continue.season_index ?? 0;
            }
            progress = watchlist?.continue?.episodes[watchlist.continue.episode_index]?.position || 0;
            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {((!hasProgress && !isPayPerViewEnabled && !isPayPerView) || (!hasProgress && isPayPerViewEnabled && isPayPerView)) && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)} text={'play'}></ButtonTextPlusIcon>
                        )}

                        {((!isPayPerViewEnabled && hasProgress) || (isPayPerViewEnabled && isPayPerView && hasProgress)) && (
                            <ButtonContinuePlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)}
                                text={'continue'}
                                seasonIndex={seasonIndex}
                                episodeIndex={episodeIndex}
                                lessonIndex={undefined}
                                progress={progress}
                            ></ButtonContinuePlusIcon>
                        )}

                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={onPressPurchase} text={'purchase_option'}></ButtonTextPlusIcon>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Program':
            var currentTime = moment().unix();
            var isLive = item.program.s < currentTime && item.program.e > currentTime;
            var isPast = item.program.s < currentTime && item.program.e < currentTime;
            var isFuture = item.program.s > currentTime && item.program.e > currentTime;
            var isRecorded = recordStatus(item.program.i, userContext);
            var isReminded = remindStatus(item.program.i, userContext);
            var watchlist = watchingStatus(type, contentContext.channelData.channel._id, userContext);

            var recordingDone = false;
            var recordingPlanned = false;
            var recordingProgress = false;
            var currentTime = moment().unix();
            if (currentTime > item.program?.e) {
                recordingDone = true;
            } else if (currentTime > item.program?.s && currentTime < item.program?.e) {
                recordingProgress = true;
            } else {
                recordingPlanned = true;
            }

            isPayPerViewEnabled = contentContext.channelData.channel.payperview?.enable;
            isPayPerView = payperviewStatus(type, contentContext.channelData.channel._id, userContext).isPayPerView;

            remainingPayPerViewDays = 0;
            if (isPayPerView) {
                var now = moment().unix();
                var diff = now - payperviewStatus(type, contentContext.channelData.channel._id, userContext).timePayPerViewStart;
                remainingPayPerViewDays = contentContext.channelData.channel.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
                if (remainingPayPerViewDays <= 0) {
                    isPayPerView = false;
                }
            }

            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        {((isLive && !isPayPerViewEnabled && !isPayPerView) || (isLive && isPayPerViewEnabled && isPayPerView)) && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlay(contentContext.channelData.channel)} text={'watch_now'}></ButtonTextPlusIcon>
                        )}
                        {!hasProgress && isPayPerView && (
                            <ButtonPayPerViewPlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressPlay(item)}
                                text={'play'}
                                rule={remainingPayPerViewDays + ' ' + lang.getTranslation(deviceContext, item.payperview.rule.access_type.toLowerCase() + 's') + ' ' + lang.getTranslation(deviceContext, 'remaining')}
                            ></ButtonPayPerViewPlusIcon>
                        )}
                        {isFuture && isRecorded && recordingDone && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlayRecording(item)} text={'watch_recording'}></ButtonTextPlusIcon>
                        )}
                        {isPast && isRecorded && recordingDone && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlayRecording(item)} text={'watch_recording'}></ButtonTextPlusIcon>
                        )}
                        {isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <ButtonTextPlusIcon
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        styling={styling}
                                        onPress={() => onPressPlayCatchupTV('live', contentContext.channelData.channel, contentContext.channelData.programIndex)}
                                        text={'restart'}
                                    ></ButtonTextPlusIcon>
                                )}
                            </>
                        )}
                        {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <ButtonTextPlusIcon
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        styling={styling}
                                        onPress={() => onPressPlayCatchupTV('normal', contentContext.channelData.channel, contentContext.channelData.programIndex)}
                                        text={'watch_now'}
                                    ></ButtonTextPlusIcon>
                                )}
                            </>
                        )}
                        {isFuture && (
                            <ButtonTextPlusIcon
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                styling={styling}
                                onPress={() => onPressRemind(isReminded)}
                                text={isReminded ? 'remove_reminder' : 'set_reminder'}
                            ></ButtonTextPlusIcon>
                        )}
                        {isPayPerViewEnabled && !isPayPerView && (
                            <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={onPressPurchase} text={'purchase_option'}></ButtonTextPlusIcon>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Recording':
            var currentTime = moment().unix();
            var isLive = item.program.s < currentTime && item.program.e > currentTime;
            var isPast = item.program.s < currentTime && item.program.e < currentTime;
            var isFuture = item.program.s > currentTime && item.program.e > currentTime;
            var isReminded = remindStatus(item.program.i, userContext);
            var watchlist = watchingStatus(type, contentContext.channelData.channel._id, userContext);

            return (
                <View style={{ flexDirection: 'column', marginTop: getCorrectHeight(deviceContext, 10) }}>
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressPlayRecording(item)} text={'watch_recording'}></ButtonTextPlusIcon>

                        <ButtonTextPlusIcon deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} onPress={() => onPressRecordingDelete(item)} text={'delete_recording'}></ButtonTextPlusIcon>
                    </View>
                </View>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};
export const ButtonRowRound = ({ item, type, deviceContext, appContext, userContext, contentContext, styling, onPressTrailer, onPressReplay, onPressFavorite, onPressWatchlist, onPressRecording }) => {
    var isFavorite = favoriteStatus(type, item._id, userContext);
    var watchlist = watchingStatus(type, item._id, userContext);
    var isWatchlist = watchlist == undefined ? false : true;
    var hasProgress = watchlist != undefined && watchlist?.position > 0 ? true : false;

    var isPayPerViewEnabled = item.payperview?.enable;
    var isPayPerView = payperviewStatus(type, item._id, userContext).isPayPerView;

    var remainingPayPerViewDays = 0;
    if (isPayPerView) {
        var now = moment().unix();
        var diff = now - payperviewStatus(type, item._id, userContext).timePayPerViewStart;
        remainingPayPerViewDays = item.payperview?.rule?.access_length - Math.round(moment.duration(diff, 'seconds').asDays());
        if (remainingPayPerViewDays <= 0) {
            isPayPerView = false;
        }
    }

    switch (type) {
        case 'Movie':
            return (
                <View style={{ flexDirection: 'column', marginBottom: getCorrectWidth(deviceContext, 15) }}>
                    <View style={{ flexDirection: 'row' }}>
                        {hasProgress && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft} text={'restart'} userContext={userContext}></ButtonIcon>
                            </View>
                        )}

                        {(item.streams.movies.trailer != undefined || (item.trailer != undefined && item.trailer != '')) && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressTrailer(item)} icon={faClapperboardPlay} text={'trailer'} userContext={userContext}></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_favorites && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressFavorite(isFavorite, item)}
                                    icon={isFavorite ? faHeartSolid : faHeart}
                                    text={'favorite'}
                                    userContext={userContext}
                                ></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_watchlist && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressWatchlist(isWatchlist, item)}
                                    icon={isWatchlist ? faCheck : faPlus}
                                    text={'watchlist'}
                                    userContext={userContext}
                                ></ButtonIcon>
                            </View>
                        )}
                 
                        {item.streams.movies.allow_downloads && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <DownloadButton styling={styling} screenName={type} item={item} text={'download'}></DownloadButton>
                                    </View>
                                )}
                            </>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Course':
            return (
                <View style={{ flexDirection: 'column', marginBottom: getCorrectWidth(deviceContext, 15) }}>
                    <View style={{ flexDirection: 'row' }}>
                        {hasProgress && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon text={'restart'} userContext={userContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_favorites && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'favorite'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressFavorite(isFavorite, item)}
                                    icon={isFavorite ? faHeartSolid : faHeart}
                                ></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_watchlist && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'watchlist'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressWatchlist(isWatchlist, item)}
                                    icon={isWatchlist ? faCheck : faPlus}
                                ></ButtonIcon>
                            </View>
                        )}
             
                    </View>
                </View>
            );
            break;
        case 'Short':
            return (
                <View style={{ flexDirection: 'column', marginBottom: getCorrectWidth(deviceContext, 15) }}>
                    <View style={{ flexDirection: 'row' }}>
                        {hasProgress && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon text={'restart'} userContext={userContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>
                            </View>
                        )}
                        {/* {appContext.application.settings.allow_favorites && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'favorite'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressFavorite(isFavorite, item)}
                                    icon={isFavorite ? faHeartSolid : faHeart}
                                ></ButtonIcon>
                            </View>
                        )} */}
                        {/* {appContext.application.settings.allow_watchlist && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'watchlist'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressWatchlist(isWatchlist, item)}
                                    icon={isWatchlist ? faCheck : faPlus}
                                ></ButtonIcon>
                            </View>
                        )} */}
                    
                        {item.streams.shorts.allow_downloads && (
                            <>
                                {(!isPayPerViewEnabled || (isPayPerViewEnabled && isPayPerView)) && (
                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <DownloadButton styling={styling} screenName={type} item={item} text={'download'}></DownloadButton>
                                    </View>
                                )}
                            </>
                        )}
                    </View>
                </View>
            );
            break;
        case 'Seasons':
            var episodeIndex = 0;
            var seasonIndex = 0;
            hasProgress = watchlist?.continue != undefined && watchlist?.continue?.position > 0 ? true : false;
            if (watchlist != undefined && hasProgress) {
                episodeIndex = watchlist.continue.episode_index;
                seasonIndex = watchlist.continue.season_index;
            }

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        {item.trailer != undefined && item.trailer != '' && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon text={'trailer'} userContext={userContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={() => onPressTrailer(item)} icon={faClapperboardPlay}></ButtonIcon>
                            </View>
                        )}
                        {hasProgress && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon text={'restart'} userContext={userContext} deviceContext={deviceContext} appContext={appContext} styling={styling} onPress={onPressReplay} icon={faRotateLeft}></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_favorites && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'favorite'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressFavorite(isFavorite, item)}
                                    icon={isFavorite ? faHeartSolid : faHeart}
                                ></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_watchlist && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    text={'watchlist'}
                                    userContext={userContext}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressWatchlist(isWatchlist, item, seasonIndex, episodeIndex)}
                                    icon={isWatchlist ? faCheck : faPlus}
                                ></ButtonIcon>
                            </View>
                        )}
                    
                    </View>
                </View>
            );
            break;
        case 'Program':
            var currentTime = moment().unix();
            var isFuture = item.program.s > currentTime && item.program.e > currentTime;
            var isRecorded = recordStatus(item.program.i, userContext);
            var isFavorite = favoriteStatus(type, contentContext.channelData.channel._id, userContext);
            var watchlist = watchingStatus(type, contentContext.channelData.channel._id, userContext);
            var isWatchlist = watchlist == undefined ? false : true;

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        {appContext.application.settings.allow_favorites && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    userContext={userContext}
                                    text={'favorite'}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressFavorite(isFavorite, contentContext.channelData.channel)}
                                    icon={isFavorite ? faHeartSolid : faHeart}
                                ></ButtonIcon>
                            </View>
                        )}
                        {appContext.application.settings.allow_watchlist && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    userContext={userContext}
                                    text={'watchlist'}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressWatchlist(isWatchlist, contentContext.channelData.channel)}
                                    icon={isWatchlist ? faCheck : faPlus}
                                ></ButtonIcon>
                            </View>
                        )}
                        {contentContext.channelData.channel.interactivetv.recordings_enabled && isFuture && checkMaxDays(contentContext, userContext) && userContext.userId.indexOf('_review') < 0 && (
                            <View style={{ flex: 1, alignItems: 'center' }}>
                                <ButtonIcon
                                    userContext={userContext}
                                    text={'recording'}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    styling={styling}
                                    onPress={() => onPressRecording(isRecorded)}
                                    icon={isRecorded ? faTrash : faRecordVinyl}
                                    style={{ color: 'crimson' }}
                                ></ButtonIcon>
                            </View>
                        )}
             
                    </View>
                </View>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};
export const ActorsRowText = ({ item, styling, appContext, deviceContext, userContext, type }) => {
    if (type == 'Season' && item.seasons?.length > 0) {
        item = item.seasons[0];
    }
    if (type != 'Program' && type != 'Recording') {
        if (item.actors != undefined && item.actors.length > 0) {
            if (item.actors[0].image == undefined || item.actors[0].image == '') {
                return (
                    <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30), marginBottom: getCorrectHeight(deviceContext, 5), opacity: 0.7 }}>
                        <Text
                            numberOfLines={2}
                            style={{
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                includeFontPadding: false,
                            }}
                        >
                            {lang.getTranslation(userContext, 'actors')}:{' '}
                            {item.actors.map((actor, index) => {
                                return actor.name + (index == item.actors.length - 1 ? '' : ', ');
                            })}
                        </Text>
                    </View>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    } else {
        if (item.program.mt?.cr?.ac != undefined && item.program.mt?.cr?.ac.length > 0) {
            if (item.program.mt?.cr?.ac[0].photo == null) {
                return (
                    <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 30), marginBottom: getCorrectHeight(deviceContext, 5), opacity: 0.7 }}>
                        <Text
                            numberOfLines={2}
                            style={{
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                includeFontPadding: false,
                            }}
                        >
                            {item.program.mt?.cr?.ac.map((actor, index) => {
                                return actor.name + (index == item.program.mt?.cr?.ac.length - 1 ? '' : ', ');
                            })}
                        </Text>
                    </View>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }
};
export const ActorsRowImages = ({ item, styling, appContext, deviceContext, userContext, type }) => {
    if (type == 'Season' && item.seasons?.length > 0) {
        item = item.seasons[0];
    }
    if (type != 'Program' && type != 'Recording') {
        if (item.actors != undefined && item.actors.length > 0) {
            if (item.actors[0].image == undefined || item.actors[0].image == '') {
                return <></>;
            } else {
                return (
                    <View
                        style={{
                            flex: 1,
                            marginLeft: getCorrectHeight(deviceContext, 5),
                            flexDirection: 'column',

                            marginBottom: getCorrectHeight(deviceContext, 10),
                        }}
                    >
                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 10) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'cast_and_crew')}
                            </Text>
                        </View>

                        <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext), paddingRight: getCorrectWidth(deviceContext, 10) }}>
                            <FlashList
                                type="row"
                                data={item.actors}
                                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item, focusRepeatContext, index, target }) => renderActor({ item, focusRepeatContext, index, target }, appContext, deviceContext)}
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                            />
                        </View>
                    </View>
                );
            }
        } else {
            return <></>;
        }
    } else {
        if (item.program.mt?.cr?.ac != undefined && item.program.mt?.cr?.ac.length > 0) {
            if (item.program.mt?.cr?.ac[0].photo == null) {
                return <></>;
            } else {
                return (
                    <View
                        style={{
                            flex: 1,
                            marginLeft: getCorrectHeight(deviceContext, 5),
                            flexDirection: 'column',

                            marginBottom: getCorrectHeight(deviceContext, 10),
                        }}
                    >
                        <View style={{ marginBottom: getCorrectHeight(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'cast_and_crew')}
                            </Text>
                        </View>

                        <View style={{ flex: 1, flexDirection: 'row', width: getRealWidth(deviceContext), paddingRight: getCorrectWidth(deviceContext, 10) }}>
                            <FlashList
                                type="row"
                                data={item.program.mt?.cr?.ac}
                                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item, focusRepeatContext, index, target }) => renderActor({ item, focusRepeatContext, index, target }, appContext, deviceContext)}
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                            />
                        </View>
                    </View>
                );
            }
        } else {
            return <></>;
        }
    }
};

export const EpisodeList = ({ series, seasons, styling, deviceContext, userContext, appContext, focusContext, contentContext, playEpisode, onSelectSeason, setDropdownOpen, selectedSeasonIndex }) => {
    if (seasons[selectedSeasonIndex].episodes != undefined && seasons[selectedSeasonIndex].episodes.length > 0) {
        return (
            <View style={{ flex: 1, flexDirection: 'column', paddingBottom: getCorrectHeight(deviceContext, 10) }}>
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            width: getRealWidth(deviceContext),
                            paddingHorizontal: getCorrectWidth(deviceContext, 10),
                            paddingBottom: getCorrectWidth(deviceContext, 5),
                        }}
                        focusContext={focusContext}
                    >
                        <View>
                            <Text
                                style={{
                                    marginTop: getCorrectHeight(deviceContext, 5),
                                    marginLeft: getCorrectWidth(deviceContext, 5),
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'seasons_and_episodes')}
                            </Text>
                        </View>
                        <View
                            style={{
                                zIndex: 9999,
                                flex: 1,
                                flexDirection: 'row',
                                borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                marginVertical: getCorrectHeight(deviceContext, 5),
                                width: getRealWidth(deviceContext) * 0.6,
                                marginTop: getCorrectHeight(deviceContext, 10),
                            }}
                        >
                            <Dropdown_
                                height={200}
                                width={getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20)}
                                setSelection={(seasonIndex) => onSelectSeason(contentContext, seasonIndex)}
                                styling={getDropdownStyling(styling.components?.dropdown, appContext)}
                                animation={getFocusStyling('Dropdowns', appContext)}
                                value={lang.getTranslation(userContext, 'season') + ' ' + (selectedSeasonIndex + 1)}
                                dataIn={series.seasons}
                                type={'Seasons'}
                                onToggleDropdown={setDropdownOpen}
                            />
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            <FlashList
                                type="row"
                                numColumns={1}
                                data={series.seasons[selectedSeasonIndex].episodes}
                                estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                horizontal={false}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item, focusRepeatContext, index, target }) =>
                                    renderEpisode({ item, focusRepeatContext, index, target }, styling, deviceContext, appContext, focusContext, playEpisode, userContext, contentContext, selectedSeasonIndex)
                                }
                                initialScrollIndex={0}
                                style={{ flex: 1 }}
                                focusOptions={{
                                    autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                    autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                }}
                            />
                        </View>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};

export const LessonList = ({ course, styling, deviceContext, userContext, appContext, focusContext, contentContext, playLesson, screenName }) => {
    if (course.lessons != undefined && course.lessons.length > 0) {
        return (
            <View style={{ flex: 1, flexDirection: 'column', paddingBottom: getCorrectHeight(deviceContext, 10) }}>
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            width: getRealWidth(deviceContext),
                            paddingHorizontal: getCorrectWidth(deviceContext, 10),
                            paddingBottom: getCorrectWidth(deviceContext, 5),
                        }}
                        focusContext={focusContext}
                    >
                        <View>
                            <Text
                                style={{
                                    marginTop: getCorrectHeight(deviceContext, 5),
                                    marginLeft: getCorrectWidth(deviceContext, 5),
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Large'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {lang.getTranslation(userContext, 'lessons')}
                            </Text>
                        </View>
                    </View>
                    <View style={{ flex: 1, marginTop: getCorrectHeight(deviceContext, 5) }}>
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                width: getRealWidth(deviceContext),
                                paddingHorizontal: getCorrectWidth(deviceContext, 10),
                                paddingBottom: getCorrectWidth(deviceContext, 5),
                            }}
                            focusContext={focusContext}
                        >
                            <View style={{ flex: 1 }}>
                                <FlashList
                                    type="grid"
                                    numColumns={1}
                                    data={course.lessons}
                                    estimatedItemSize={getCorrectHeight(deviceContext, 100)}
                                    drawDistance={getCorrectHeight(deviceContext, 100) * 2}
                                    horizontal={false}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={({ item, focusRepeatContext, index, target }) => renderLesson({ item, focusRepeatContext, index, target }, styling, deviceContext, appContext, focusContext, playLesson, userContext, contentContext)}
                                    initialScrollIndex={0}
                                    style={{ flex: 1 }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};

///extra functions

export const ButtonTextPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text }) => {
    return (
        <Pressable
            style={{
                marginBottom: getCorrectHeight(deviceContext, 5),
                height: getCorrectHeight(deviceContext, 30),
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            </View>
        </Pressable>
    );
};
export const ButtonContinuePlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, seasonIndex, episodeIndex, lessonIndex, progress }) => {
    return (
        <Pressable
            style={{
                height: getCorrectHeight(deviceContext, 30),
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: getCorrectHeight(deviceContext, 30),
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    flexDirection: 'column',
                }}
            >
                {!isNaN(seasonIndex) && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)} {lang.getTranslation(userContext, 'season')} {seasonIndex + 1} {lang.getTranslation(userContext, 'episode')} {episodeIndex + 1}
                    </Text>
                )}
                {isNaN(seasonIndex) && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontSize: getFontSize(deviceContext, 'Normal'),
                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        }}
                    >
                        {lang.getTranslation(userContext, text)}
                    </Text>
                )}
                {progress > 0 && (
                    <View style={{ flexDirection: 'row' }}>
                        <View
                            style={{
                                marginTop: getCorrectHeight(deviceContext, 5),
                                backgroundColor: appContext.application.theme.progresses.base,
                                height: getCorrectHeight(deviceContext, 2),
                                width: '50%',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: appContext.application.theme.progresses.progress,
                                    height: getCorrectHeight(deviceContext, 2),
                                    width: progress + '%',
                                }}
                            ></View>
                        </View>
                    </View>
                )}
            </View>
        </Pressable>
    );
};
export const ButtonPayPerViewPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, rule }) => {
    return (
        <Pressable
            style={{
                height: getCorrectHeight(deviceContext, 30),
                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)} ({rule})
                </Text>
            </View>
        </Pressable>
    );
};
export const ButtonIcon = ({ deviceContext, appContext, styling, onPress, icon, userContext, text }) => {
    return (
        <View>
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, 35),
                    width: getCorrectHeight(deviceContext, 35),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPress()}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: getCorrectHeight(deviceContext, 35),
                        width: getCorrectHeight(deviceContext, 35),
                    }}
                >
                    <FontAwesomeIcon icon={icon} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                </View>
            </Pressable>
            {text != '' && (
                <Text
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 2),
                        textAlign: 'center',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            )}
        </View>
    );
};
export const ButtonIconTop = ({ deviceContext, appContext, styling, onPress, icon, userContext, text, color }) => {
    return (
        <View>
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, 30),
                    width: getCorrectHeight(deviceContext, 30),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    borderRadius: 100,
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPress()}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: getCorrectHeight(deviceContext, 30),
                        width: getCorrectHeight(deviceContext, 30),
                    }}
                >
                    <FontAwesomeIcon icon={icon} color={color} size={getFontSize(deviceContext, 'Large') * 1.2}></FontAwesomeIcon>
                </View>
            </Pressable>
            {text != '' && (
                <Text
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 2),
                        textAlign: 'center',
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            )}
        </View>
    );
};
const getRatings = (ratings, appContext, deviceContext, userContext) => {
    var ratingOut = [] as any;
    var ratingsSplit = ratings.split(',');
    if (ratings.indexOf(',') == -1) {
        ratingOut.push(
            <Text
                style={{
                    backgroundColor: appContext.application.theme.tags.background,
                    color: appContext.application.theme.tags.color,
                    borderRadius: appContext.application.theme.tags.radius / 2,
                    fontSize: getFontSize(deviceContext, 'Small'),
                    margin: getCorrectWidth(deviceContext, 2),
                    padding: getCorrectWidth(deviceContext, 2),
                    paddingHorizontal: getCorrectWidth(deviceContext, 4),
                    includeFontPadding: false,
                }}
            >
                {ratings}
            </Text>
        );
    } else if (ratingsSplit != undefined && ratingsSplit[0] != undefined) {
        for (var rating of ratingsSplit) {
            ratingOut.push(
                <Text
                    style={{
                        backgroundColor: appContext.application.theme.tags.background,
                        color: appContext.application.theme.tags.color,
                        borderRadius: appContext.application.theme.tags.radius / 2,
                        fontSize: getFontSize(deviceContext, 'Small'),
                        margin: getCorrectWidth(deviceContext, 2),
                        padding: getCorrectWidth(deviceContext, 2),
                        paddingHorizontal: getCorrectWidth(deviceContext, 4),
                        includeFontPadding: false,
                    }}
                >
                    {rating}
                </Text>
            );
        }
    }
    if (ratingOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{ratingOut}</View>;
    } else {
        return null;
    }
};
const getTags = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
        tags.map((tag, index) => {
            if (tag.tag != undefined) {
                var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                if (test == undefined) {
                    tag.name = tag[0]?.description;
                } else {
                    tag.name = test?.description;
                }
            }
            if (index < 1 && tag.name != '') {
                tagsOut.push(
                    <Text
                        key={index}
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            color: appContext.application.theme.tags.color,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 5),
                            includeFontPadding: false,
                        }}
                    >
                        {tag.name}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags.length > 0) {
        tags.map((tag, index) => {
            if (index < 3) {
                tagsOut.push(
                    <Text
                        key={index}
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 5),
                            includeFontPadding: false,
                        }}
                    >
                        {tag}
                    </Text>
                );
            }
        });
    }
    if (tagsOut.length > 0) {
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    } else {
        return null;
    }
};
const DotInBetween = ({ deviceContext, styling }) => {
    return (
        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 3) }}>
            <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: styling.texts.title_color, borderRadius: 100 }}></View>
        </View>
    );
};

const getProgramImage = (program, data, deviceContext, appContext) => {
    if (program != undefined && program?.r != '' && program?.r != null && program?.r.indexOf('https://') > -1) {
        var image = program.r
            .replace('.jpg', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealHeight(deviceContext)) + '.jpg')
            .replace('.png', '_' + Math.round(getRealWidth(deviceContext)) + 'x' + Math.round(getRealHeight(deviceContext)) + '.png');
        return (
            <Image
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                style={{
                    width: getRealWidth(deviceContext) - 10,
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: deviceContext.isIos ? 10 : 10,
                    borderTopRightRadius: deviceContext.isIos ? 10 : 10,
                    alignSelf: 'center',
                }}
                source={{ uri: image }}
            />
        );
    } else {
        return (
            <Image
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                style={{
                    width: getRealWidth(deviceContext) - 10,
                    height: getRealWidth(deviceContext) * 1.5 - 10,
                    borderTopLeftRadius: deviceContext.isIos ? 10 : 10,
                    borderTopRightRadius: deviceContext.isIos ? 10 : 10,
                    alignSelf: 'center',
                }}
                source={{ uri: data?.images?.square.indexOf('https://') == -1 ? appContext.cloudUrl + data?.images?.square : data.images.square }}
            />
        );
    }
};
const getMovieImage = (item, deviceContext, appContext) => {
    return (
        <Image
            deviceContext={deviceContext}
            resizeMethod={'scale'}
            resizeMode={'cover'}

            style={{
                width: getRealWidth(deviceContext) - 10,
                height: getRealWidth(deviceContext) * 1.5 - 10,
                borderTopLeftRadius: deviceContext.isIos ? 10 : 10,
                borderTopRightRadius: deviceContext.isIos ? 10 : 10,
                alignSelf: 'center',
            }}
            source={{ uri: item?.images?.poster.indexOf('https://') == -1 ? appContext.cloudUrl + item?.images?.poster : item.images.poster }}
        />
    );
};
const getSeriesImage = (item, deviceContext, appContext, contentContext) => {
    return (
        <Image
            deviceContext={deviceContext}
            resizeMethod={'scale'}
            resizeMode={'contain'}
            style={{
                width: getRealWidth(deviceContext) - 10,
                height: getRealWidth(deviceContext) * 1.5 - 10,
                borderTopLeftRadius: deviceContext.isIos ? 10 : 10,
                borderTopRightRadius: deviceContext.isIos ? 10 : 10,
                alignSelf: 'center',
            }}
            source={{
                uri:
                    item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster.indexOf('https://') == -1
                        ? appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster
                        : item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster,
            }}
        />
    );
};
const getCourseImage = (item, deviceContext, appContext) => {
    return (
        <Image
            deviceContext={deviceContext}
            resizeMethod={'scale'}
            resizeMode={'contain'}
            style={{
                width: getRealWidth(deviceContext) - 10,
                height: getRealWidth(deviceContext) * 1.5 - 10,
                borderTopLeftRadius: deviceContext.isIos ? 10 : 10,
                borderTopRightRadius: deviceContext.isIos ? 10 : 10,
                alignSelf: 'center',
            }}
            source={{ uri: item.images?.poster.indexOf('https://') == -1 ? appContext.cloudUrl + item.images?.poster : item.images.poster }}
        />
    );
};

const renderActor = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, appContext, deviceContext) => {
    return (
        <Pressable
            style={{ marginRight: getCorrectHeight(deviceContext, 10), width: getCorrectWidth(deviceContext, 60) }}
            animatorOptions={{
                type: 'scale',
                focus: {
                    scale: 1.1,
                    duration: 150,
                },
            }}
            onPress={() => null}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <View>
                    <View style={{ flex: 1 }}>
                        <ImageNormal
                            source={{ uri: item?.photo ?? item?.image }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                borderColor: appContext.application.theme.tags.color,
                                borderWidth: 2,
                                height: getCorrectWidth(deviceContext, 60),
                                width: getCorrectWidth(deviceContext, 60),
                                borderRadius: getCorrectWidth(deviceContext, 100),
                            }}
                        />
                    </View>

                    <Text
                        numberOfLines={2}
                        style={{
                            width: getCorrectWidth(deviceContext, 60),
                            marginTop: getCorrectHeight(deviceContext, 12),
                            textAlign: 'center',
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            includeFontPadding: false,
                        }}
                    >
                        {item?.name}
                    </Text>
                    <Text
                        numberOfLines={1}
                        style={{
                            width: getCorrectWidth(deviceContext, 60),
                            textAlign: 'center',
                            color: appContext.application.theme.tags.color,
                            opacity: 0.5,
                            fontSize: getFontSize(deviceContext, 'X-Small'),
                            includeFontPadding: false,
                        }}
                    >
                        {item?.role}
                        {item?.charname}
                    </Text>
                </View>
            </View>
        </Pressable>
    );
};

const renderEpisode = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, styling, deviceContext, appContext, focusContext, playEpisode, userContext, contentContext, seasonIndex) => {
    var watching = watchingStatus('Seasons', contentContext.seriesData.series._id, userContext);
    var progress = 0;
    if (watching != undefined && watching.continue != undefined) {
        var episode = watching.continue.episodes.find((e) => e._id == item?._id);
        if (episode != undefined) {
            progress = (episode.position / episode.duration) * 100;
        } else {
            progress = 0;
        }
    } else {
        progress = 0;
    }
    return (
        <Pressable
            style={[
                {
                    ...getPressableStyling(styling.components?.button._id, appContext),
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 90),
                    marginBottom: getCorrectHeight(deviceContext, 5),
                },
            ]}
            focusContext={focusContext}
            focusRepeatContext={focusRepeatContext}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => playEpisode(item, seasonIndex, index)}
        >
            <View style={{ flex: 1, flexDirection: 'column' }}>
                <View style={{ flex: 1, flexDirection: 'row', marginTop: getCorrectWidth(deviceContext, 5) }}>
                    <View>
                        <ImageNormal
                            deviceContext={deviceContext}
                            source={{ uri: item.images?.widescreen.indexOf('https://') == -1 ? appContext.cloudUrl + item?.images?.widescreen : item.images.widescreen }}
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            style={{
                                marginLeft: getCorrectWidth(deviceContext, 5),
                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                height: getCorrectHeight(deviceContext, 50),
                                width: (getCorrectHeight(deviceContext, 50) / 9) * 16,
                            }}
                        />

                        {progress > -1 && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: getCorrectWidth(deviceContext, 5),
                                    left: getCorrectWidth(deviceContext, 8),
                                    right: getCorrectWidth(deviceContext, 2),
                                }}
                            >
                                <View
                                    style={{
                                        marginVertical: getCorrectHeight(deviceContext, 2),
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                        marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                        height: getCorrectHeight(deviceContext, 2),
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowOffset: { width: -1, height: 1 },
                                        shadowRadius: 2,
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.progresses.progress,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,

                                            width: progress + '%',
                                        }}
                                    ></View>
                                </View>
                            </View>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginLeft: getCorrectWidth(deviceContext, 5),
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, (getCorrectHeight(deviceContext, 50) / 9) * 16) - getCorrectWidth(deviceContext, 20),
                        }}
                    >
                        <Text
                            numberOfLines={2}
                            style={{
                                margin: getCorrectWidth(deviceContext, 3),
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            }}
                        >
                            {item.number}
                            {'. '}
                            {item.name}
                        </Text>

                        {item?.duration != undefined && (
                            <Text
                                numberOfLines={1}
                                style={{
                                    margin: getCorrectWidth(deviceContext, 3),
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {item?.duration > 0 && <>{moment().startOf('day').add('seconds', item?.duration).format('m') + 'm'}</>}
                            </Text>
                        )}
                        {item.streams.series.allow_downloads && (
                            <View style={{ position: 'absolute', right: 8, bottom: -8 }}>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <DownloadButton type={'mini'} styling={styling} screenName={'Episodes'} item={item}></DownloadButton>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <View style={{ padding: getCorrectWidth(deviceContext, 10), paddingTop: getCorrectWidth(deviceContext, 5) }}>
                    {item?.translations != undefined && (
                        <Text
                            numberOfLines={2}
                            style={{
                                color: styling.texts.title_color,

                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {getCorrectTranslation(item?.translations, userContext)}
                        </Text>
                    )}
                </View>
            </View>
        </Pressable>
    );
};
const renderLesson = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>, styling, deviceContext, appContext, focusContext, playLesson, userContext, contentContext) => {
    var watching = watchingStatus('Lessons', contentContext.courseData.course._id, userContext);
    var progress = 0;
    if (watching != undefined && watching.continue != undefined) {
        var episode = watching.continue.episodes.find((e) => e._id == item?._id);
        if (episode != undefined) {
            progress = (episode.position / episode.duration) * 100;
        } else {
            progress = 0;
        }
    } else {
        progress = 0;
    }
    return (
        <Pressable
            style={[
                {
                    ...getPressableStyling(styling.components?.button._id, appContext),
                    width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 90),
                    marginBottom: getCorrectHeight(deviceContext, 5),
                },
            ]}
            focusContext={focusContext}
            focusRepeatContext={focusRepeatContext}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => playLesson(item, index)}
        >
            <View style={{ flex: 1, flexDirection: 'column' }}>
                <View style={{ flex: 1, flexDirection: 'row', marginTop: getCorrectWidth(deviceContext, 5) }}>
                    <View>
                        <ImageNormal
                            deviceContext={deviceContext}
                            source={{ uri: item.images?.widescreen.indexOf('https://') == -1 ? appContext.cloudUrl + item?.images?.widescreen : item.images.widescreen }}
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            style={{
                                marginLeft: getCorrectWidth(deviceContext, 5),
                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                height: getCorrectHeight(deviceContext, 50),
                                width: (getCorrectHeight(deviceContext, 50) / 9) * 16,
                            }}
                        />

                        {progress > -1 && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: getCorrectWidth(deviceContext, 5),
                                    left: getCorrectWidth(deviceContext, 8),
                                    right: getCorrectWidth(deviceContext, 2),
                                }}
                            >
                                <View
                                    style={{
                                        marginVertical: getCorrectHeight(deviceContext, 2),
                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                        marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                        height: getCorrectHeight(deviceContext, 2),
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowOffset: { width: -1, height: 1 },
                                        shadowRadius: 2,
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.progresses.progress,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,

                                            width: progress + '%',
                                        }}
                                    ></View>
                                </View>
                            </View>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginLeft: getCorrectWidth(deviceContext, 5),
                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, (getCorrectHeight(deviceContext, 50) / 9) * 16) - getCorrectWidth(deviceContext, 25),
                        }}
                    >
                        <Text
                            numberOfLines={2}
                            style={{
                                margin: getCorrectWidth(deviceContext, 3),
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                ...getPressableTextStyling(styling.components?.button?._id, appContext),
                            }}
                        >
                            {item.number}
                            {'. '}
                            {item.name}
                        </Text>

                        {item?.duration != undefined && (
                            <Text
                                numberOfLines={1}
                                style={{
                                    margin: getCorrectWidth(deviceContext, 3),
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                }}
                            >
                                {item?.duration > 0 && <>{moment().startOf('day').add('seconds', item?.duration).format('m') + 'm'}</>}
                            </Text>
                        )}
                        {item.streams.courses.allow_downloads && (
                            <View style={{ position: 'absolute', right: 8, bottom: -8 }}>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <DownloadButton type={'mini'} styling={styling} screenName={'Episodes'} item={item}></DownloadButton>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <View style={{ padding: getCorrectWidth(deviceContext, 10), paddingTop: getCorrectWidth(deviceContext, 5) }}>
                    {item?.translations != undefined && (
                        <Text
                            numberOfLines={2}
                            style={{
                                color: styling.texts.title_color,

                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {getCorrectTranslation(item?.translations, userContext)}
                        </Text>
                    )}
                </View>
            </View>
        </Pressable>
    );
};
const getCorrectTranslation = (translations, userContext) => {
    return translations.find((trans) => trans.name == userContext.selectedLanguage)?.description;
};
