import { ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faFontCase } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useState } from 'react';
import lang from '../../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFontSize } from '../../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import Pressable from '../../../components/pressable/pressable';
import Text from '../../../components/text';
import { getFocusStyling } from '../../../components/helpers/helper';
import { EdgeStyle } from 'react-native-theoplayer';
import { manageProfiles } from '../../../../data/account';
import { BackHandler } from 'react-native';

export const SettingsView = ({ playerContext, changeScreenSetting, changeAudioSettings, changeTextSetting, deviceContext, appContext, styling, setshowTextSizingMenu, setShowSettingMenu, userContext, focusContext }) => {
    const resizeModes = [{ name: 'Contain' }, { name: 'Cover' }, { name: 'Stretch' }];
    const hasSubs = playerContext.player.textTracks != undefined && playerContext.player?.textTracks?.length > 0;
    const hasAudio = playerContext.player.audioTracks != undefined && playerContext.player?.audioTracks?.length > 1;
    const width = hasSubs && hasAudio ? 0.6 : !hasAudio && hasSubs ? 0.6 : !hasSubs && hasAudio ? 0.6 : 0.4;

    const disableNativeHardwareBackPress = () => {
        global.showContentList = false;
        setShowSettingMenu(false);
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, []);
    return (
        <View
            focusContext={focusContext}
            style={{
                position: 'absolute',
                zIndex: 9999,
                top: 0,
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                width: deviceContext.isPhone ? '100%' : getRealWidth(deviceContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    height: deviceContext.isPhone ? getRealWidth(deviceContext) * 0.65 : getRealHeight(deviceContext) * 0.45,
                    width: deviceContext.isPhone ? getRealHeight(deviceContext) * width : getRealWidth(deviceContext) * width,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    padding: getCorrectWidth(deviceContext, 10),
                }}
            >
                <View
                    style={{
                        marginBottom: getCorrectHeight(deviceContext, 10),
                        flexDirection: 'row',
                        flex: 1,
                    }}
                >
                    <View style={{ height: getCorrectHeight(deviceContext, 30), justifyContent: 'center', width: getCorrectWidth(deviceContext, 35) }}>
                        <Pressable
                            style={{
                                width: getCorrectWidth(deviceContext, 25),
                                height: getCorrectWidth(deviceContext, 25),
                                borderRadius: 100,
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            hasPreferredFocus={true}
                            onPress={() => {
                                global.showContentList = false;
                                setShowSettingMenu(false);
                            }}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'back',
                                nextFocusDown: 'screen',
                                nextFocusRight: 'screen',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: getCorrectWidth(deviceContext, 25),
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: 100,
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    >
                        <View style={{ height: getCorrectHeight(deviceContext, 30), justifyContent: 'center' }}>
                            <Text
                                style={{
                                    color: '#fff',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                    fontWeight: 'normal',
                                }}
                            >
                                {lang.getTranslation(userContext, 'screensize')}
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                marginTop: getCorrectHeight(deviceContext, 15),
                            }}
                        >
                            <ScrollView contentContainerStyle={{ flex: 1 }}>
                                {resizeModes.map((style, index) => {
                                    return (
                                        <Pressable
                                            style={{
                                                borderRadius: getCorrectWidth(deviceContext, 3),
                                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                width: '100%',
                                                marginBottom: getCorrectWidth(deviceContext, 2),
                                            }}
                                            key={index}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => changeScreenSetting(style.name)}
                                            focusContext={focusContext}
                                            focusOptions={{
                                                focusKey: 'screen',
                                                nextFocusUp: index === 0 ? 'back' : undefined,
                                                nextFocusLeft: 'back',
                                                nextFocusRight: playerContext.player.audioTracks > 1 ? 'audio' : 'subs',
                                                nextFocusDown: '',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    height: getCorrectHeight(deviceContext, 25),
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: '#fff',
                                                        fontFamily: deviceContext.fontType,

                                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                                        fontWeight: 'normal',
                                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                                    }}
                                                >
                                                    {style.name}
                                                </Text>
                                                {style.name == playerContext.aspectRatio && (
                                                    <View
                                                        style={{
                                                            position: 'absolute',
                                                            right: getCorrectWidth(deviceContext, 10),
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                                    </View>
                                                )}
                                            </View>
                                        </Pressable>
                                    );
                                })}
                            </ScrollView>
                        </View>
                    </View>
                    {playerContext.player.audioTracks != undefined && playerContext.player.audioTracks?.length > 1 && (
                        <View
                            style={{
                                flex: 1,
                                marginLeft: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <View style={{ height: getCorrectHeight(deviceContext, 30), justifyContent: 'center' }}>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'audio')}
                                </Text>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    marginTop: getCorrectHeight(deviceContext, 15),
                                }}
                            >
                                <ScrollView contentContainerStyle={{ flex: 1 }}>
                                    {playerContext.player.audioTracks.map((audio, index) => {
                                        if (audio.language == '') {
                                            return null;
                                        }
                                        return (
                                            <Pressable
                                                style={{
                                                    borderRadius: getCorrectWidth(deviceContext, 3),

                                                    width: '100%',
                                                    marginBottom: getCorrectWidth(deviceContext, 2),
                                                }}
                                                key={index}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => changeAudioSettings(audio.uid, getLanguagFromPlayer(audio.language))}
                                                focusContext={focusContext}
                                                focusOptions={{
                                                    focusKey: 'audio',
                                                    nextFocusUp: index === 0 ? 'back' : undefined,
                                                    nextFocusLeft: 'screen',
                                                    nextFocusRight: 'subs',
                                                    forbiddenFocusDirections: index === playerContext.player.audioTracks.length - 1 ? ['down'] : undefined,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        height: getCorrectHeight(deviceContext, 25),
                                                        justifyContent: 'center',
                                                        alignContent: 'center',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                                            fontWeight: 'normal',
                                                            marginLeft: getCorrectWidth(deviceContext, 15),
                                                        }}
                                                    >
                                                        {getLanguagFromPlayer(audio.language)}
                                                    </Text>
                                                    {audio.uid == playerContext.audioTrackIndex && (
                                                        <View
                                                            style={{
                                                                position: 'absolute',
                                                                right: getCorrectWidth(deviceContext, 10),
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                                        </View>
                                                    )}
                                                </View>
                                            </Pressable>
                                        );
                                    })}
                                </ScrollView>
                            </View>
                        </View>
                    )}
                    {playerContext.player.textTracks != undefined && playerContext.player.textTracks?.length > 0 && (
                        <View
                            style={{
                                flex: 1,
                                marginLeft: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            <View style={{ height: getCorrectHeight(deviceContext, 30), justifyContent: 'center' }}>
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'subtitles')}
                                </Text>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    marginTop: getCorrectHeight(deviceContext, 15),
                                }}
                            >
                                <ScrollView contentContainerStyle={{ flex: 1 }}>
                                    {playerContext.player.textTracks.map((text, index) => {
                                        if (text.language == '') {
                                            return null;
                                        }
                                        return (
                                            <Pressable
                                                style={{
                                                    borderRadius: getCorrectWidth(deviceContext, 3),

                                                    width: '100%',
                                                    marginBottom: getCorrectWidth(deviceContext, 2),
                                                }}
                                                key={index}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => changeTextSetting(text.uid, getLanguagFromPlayer(text.language))}
                                                focusContext={focusContext}
                                                focusOptions={{
                                                    focusKey: 'subs',
                                                    nextFocusUp: index === 0 ? 'back' : undefined,
                                                    nextFocusLeft: playerContext.player.audioTracks > 1 ? 'audio' : 'screen',
                                                    nextFocusRight: 'style',
                                                    forbiddenFocusDirections: index === playerContext.player.textTracks.length - 1 ? ['down'] : undefined,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        height: getCorrectHeight(deviceContext, 25),
                                                        justifyContent: 'center',
                                                        alignContent: 'center',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                                            fontWeight: 'normal',
                                                            marginLeft: getCorrectWidth(deviceContext, 15),
                                                        }}
                                                    >
                                                        {text.language == 'disable' ? text.label : getLanguagFromPlayer(text.language)}
                                                    </Text>

                                                    {((playerContext.textTrackIndex === undefined && text.language === 'disable') || text.uid === playerContext.textTrackIndex) && (
                                                        <View
                                                            style={{
                                                                position: 'absolute',
                                                                right: getCorrectWidth(deviceContext, 10),
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                                        </View>
                                                    )}
                                                </View>
                                            </Pressable>
                                        );
                                    })}
                                </ScrollView>
                            </View>
                        </View>
                    )}
                    <View style={{ height: getCorrectHeight(deviceContext, 30), justifyContent: 'center', width: getCorrectWidth(deviceContext, 35), alignItems: 'flex-end' }}>
                        {playerContext.player.textTracks != undefined && playerContext.player.textTracks?.length > 0 && (
                            <Pressable
                                style={{
                                    width: getCorrectWidth(deviceContext, 25),
                                    height: getCorrectWidth(deviceContext, 25),
                                    borderRadius: 100,
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                hasPreferredFocus={false}
                                onPress={() => {
                                    setShowSettingMenu(false);
                                    setshowTextSizingMenu(true);
                                }}
                                focusContext={focusContext}
                                focusOptions={{
                                    focusKey: 'style',
                                    nextFocusUp: 'back',
                                    nextFocusLeft: 'back',
                                    nextFocusDown: 'screen',
                                }}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        borderRadius: 100,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFontCase} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                </View>
                            </Pressable>
                        )}
                        {playerContext.player.audioTracks == undefined && playerContext.player.audioTracks?.length < 2 && playerContext.player.textTracks == undefined && playerContext.player.textTracks?.length < 1 && (
                            <View style={{ height: getCorrectHeight(deviceContext, 30), width: getCorrectWidth(deviceContext, 35), justifyContent: 'center' }}></View>
                        )}
                    </View>
                </View>
            </View>
        </View>
    );
};

export const TextSizingView = ({ userContext, playerContext, deviceContext, appContext, styling, setshowTextSizingMenu, setShowSettingMenu, focusContext }) => {
    const [style, setStyle] = useState({
        backgroundColor: playerContext.trackStyle.backgroundColor,
        color: playerContext.trackStyle.fontColor,
        fontSize:
            playerContext.trackStyle.fontSize == '100%'
                ? getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge')
                : playerContext.trackStyle.fontSize == '75%'
                ? getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'ExtraLarge')
                : getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraExtraLarge' : 'ExtraExtraExtraLarge'),
        textShadowColor: playerContext.trackStyle.edgeStyle == EdgeStyle.dropshadow ? '#000' : 'transparent',
        textShadowOffset: { width: 2, height: 4 },
        textShadowRadius: 1,
    });

    const disableNativeHardwareBackPress = () => {
        setshowTextSizingMenu(false);
        setShowSettingMenu(true);
        return true;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, []);

    const setTrackStylingTest = (type) => {
        switch (type) {
            case 'small':
                setStyle({
                    ...style,
                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'ExtraLarge'),
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    fontSize: '75%',
                });
                break;
            case 'medium':
                setStyle({
                    ...style,
                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraLarge' : 'ExtraExtraLarge'),
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    fontSize: '100%',
                });
                break;
            case 'large':
                setStyle({
                    ...style,
                    fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'ExtraExtraLarge' : 'ExtraExtraExtraLarge'),
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    fontSize: '150%',
                });
                break;
            case 'dropshadow':
                setStyle({
                    backgroundColor: 'transparent',
                    color: '#fff',
                    fontSize: style.fontSize,
                    textShadowColor: '#000000',
                    textShadowOffset: { width: 2, height: 4 },
                    textShadowRadius: 1,
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    backgroundColor: 'transparent',
                    fontColor: '#ffffff',
                    edgeStyle: EdgeStyle.dropshadow,
                });
                break;
            case 'dark':
                setStyle({
                    backgroundColor: '#00000060',
                    color: '#fff',
                    fontSize: style.fontSize,
                    textShadowColor: 'transparent',
                    textShadowOffset: { width: 2, height: 4 },
                    textShadowRadius: 1,
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    backgroundColor: '#00000060',
                    fontColor: '#ffffff',
                    edgeStyle: EdgeStyle.none,
                });
                break;
            case 'contrast':
                setStyle({
                    backgroundColor: '#999999',
                    color: '#fff',
                    fontSize: style.fontSize,
                    textShadowColor: '#00000080',
                    textShadowOffset: { width: 0, height: 0 },
                    textShadowRadius: 10,
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    backgroundColor: '#999999',
                    fontColor: '#ffffff',
                    edgeStyle: EdgeStyle.raised,
                });
                break;
            case 'light':
                setStyle({
                    backgroundColor: '#ffffff',
                    color: '#444444',
                    fontSize: style.fontSize,
                    textShadowColor: 'transparent',
                    textShadowOffset: { width: 2, height: 4 },
                    textShadowRadius: 1,
                });
                playerContext.setTrackStyle({
                    ...playerContext.trackStyle,
                    backgroundColor: '#ffffff',
                    fontColor: '#444444',
                    edgeStyle: EdgeStyle.none,
                });
                break;

            default:
                break;
        }
    };

    return (
        <View
            focusContext={focusContext}
            style={{
                position: 'absolute',
                top: 0,
                zIndex: 9999,
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                width: deviceContext.isPhone ? '100%' : getRealWidth(deviceContext),
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    height: deviceContext.isPhone ? getRealWidth(deviceContext) * 0.7 : getRealHeight(deviceContext) * 0.45,
                    width: deviceContext.isPhone ? getRealHeight(deviceContext) * 0.7 : getRealWidth(deviceContext) * 0.5,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',

                    padding: getCorrectWidth(deviceContext, 10),
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        marginBottom: 10,
                    }}
                >
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectHeight(deviceContext, 30),
                            justifyContent: 'center',
                        }}
                    >
                        <Pressable
                            style={{
                                width: getCorrectWidth(deviceContext, 25),
                                height: getCorrectWidth(deviceContext, 25),
                                borderRadius: 100,
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            hasPreferredFocus={true}
                            onPress={() => {
                                if (userContext.profile.trackStyle != playerContext.trackStyle) {
                                    manageProfiles(userContext, userContext.profile, 'trackStyle', playerContext.trackStyle, appContext.application);
                                }
                                setShowSettingMenu(true);
                                setshowTextSizingMenu(false);
                            }}
                            focusOptions={{
                                focusKey: 'back',
                                nextFocusDown: 'size1',
                                nextFocusRight: 'size1',
                            }}
                            focusContext={focusContext}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: 100,
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            height: getCorrectHeight(deviceContext, 30),
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                fontFamily: deviceContext.fontType,

                                fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                fontWeight: 'normal',
                                marginLeft: getCorrectWidth(deviceContext, 15),
                            }}
                        >
                            {lang.getTranslation(userContext, 'subtitle_styling')}
                        </Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                        }}
                    ></View>
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                        }}
                    ></View>
                </View>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                    }}
                >
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                        }}
                    ></View>
                    <View
                        style={{
                            flex: 1,
                            marginLeft: getCorrectWidth(deviceContext, 15),
                        }}
                    >
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),

                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('small')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'size1',
                                nextFocusRight: 'style1',
                                nextFocusUp: 'back',
                                nextFocusLeft: 'back',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,

                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'X-Small' : 'Small'),
                                        fontWeight: 'normal',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'small')})
                                </Text>
                                {playerContext.trackStyle.fontSize == '75%' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),

                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('medium')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'size2',
                                nextFocusRight: 'style1',
                                nextFocusLeft: 'back',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,

                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        fontWeight: 'normal',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'medium')})
                                </Text>
                                {playerContext.trackStyle.fontSize == '100%' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),

                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('large')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'size3',
                                nextFocusRight: 'style1',
                                nextFocusLeft: 'back',
                                forbiddenFocusDirections: ['down'],
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,

                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Normal' : 'Large'),
                                        fontWeight: 'normal',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'large')})
                                </Text>
                                {playerContext.trackStyle.fontSize == '150%' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            marginLeft: getCorrectWidth(deviceContext, 15),
                        }}
                    >
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),
                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('dropshadow')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'style1',
                                nextFocusRight: '',
                                nextFocusUp: 'back',
                                nextFocusLeft: 'size1',
                                nextFocusDown: '',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        fontFamily: deviceContext.fontType,

                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        fontWeight: 'normal',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                        textShadowColor: '#000',
                                        textShadowOffset: { width: 2, height: 4 },
                                        textShadowRadius: 1,
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'dropshadow')})
                                </Text>
                                {playerContext.trackStyle.edgeStyle == EdgeStyle.dropshadow && playerContext.trackStyle.backgroundColor == 'transparent' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),
                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('dark')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'style2',
                                nextFocusRight: '',
                                nextFocusUp: '',
                                nextFocusLeft: 'size1',
                                nextFocusDown: '',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                        fontFamily: deviceContext.fontType,
                                        backgroundColor: '#00000060',
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        fontWeight: 'normal',
                                        padding: getCorrectWidth(deviceContext, 4),
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'dark')})
                                </Text>
                                <View style={{ flex: 1 }}></View>
                                {playerContext.trackStyle.backgroundColor == '#00000060' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),
                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('contrast')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'style3',
                                nextFocusRight: '',
                                nextFocusUp: '',
                                nextFocusLeft: 'size1',
                                nextFocusDown: '',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#fff',
                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                        fontFamily: deviceContext.fontType,
                                        backgroundColor: '#999',
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        fontWeight: 'bold',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                        padding: getCorrectWidth(deviceContext, 4),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'contrast')})
                                </Text>
                                <View style={{ flex: 1 }}></View>
                                {playerContext.trackStyle.backgroundColor == '#999999' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                        <Pressable
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, 3),
                                width: '100%',
                                margin: getCorrectWidth(deviceContext, 2),
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => setTrackStylingTest('light')}
                            focusContext={focusContext}
                            focusOptions={{
                                focusKey: 'style4',
                                nextFocusRight: '',
                                nextFocusUp: '',
                                nextFocusLeft: 'size1',
                                nextFocusDown: '',
                            }}
                        >
                            <View
                                style={{
                                    height: getCorrectHeight(deviceContext, 25),
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                }}
                            >
                                <Text
                                    style={{
                                        color: '#333',
                                        borderRadius: getCorrectWidth(deviceContext, 3),
                                        fontFamily: deviceContext.fontType,
                                        backgroundColor: '#fff',
                                        fontSize: getFontSize(deviceContext, deviceContext.isPhone ? 'Small' : 'Normal'),
                                        fontWeight: 'normal',
                                        marginLeft: getCorrectWidth(deviceContext, 15),
                                        padding: getCorrectWidth(deviceContext, 4),
                                    }}
                                >
                                    Aa ({lang.getTranslation(userContext, 'light')})
                                </Text>
                                <View style={{ flex: 1 }}></View>
                                {playerContext.trackStyle.backgroundColor == '#ffffff' && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, deviceContext.isPhone ? 'Large' : 'Large')}></FontAwesomeIcon>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                    </View>
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                        }}
                    ></View>
                </View>
            </View>
            <View style={{ margin: 10, height: deviceContext.isPhone ? null : 100 }}>
                <Text
                    style={[
                        style,
                        {
                            fontFamily: deviceContext.isApple ? deviceContext.fontType : 'monospace',
                        },
                    ]}
                >
                    {lang.getTranslation(userContext, 'your_subtitles_will_look_like_this')}
                </Text>
            </View>
        </View>
    );
};
export const getLanguagFromPlayer = (c) => {
    try {
        if (c == null || c == '' || !c) {
            return '';
        } else {
            return new Intl.DisplayNames([lang.getLanguageCode(global.selectedLanguage)], { type: 'language' }).of(c);
        }
    } catch (e) {
        return '';
    }
};
