import * as React from 'react';
import { useState, Dispatch } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { DeviceContext } from './deviceContext';
import { stat } from 'fs/promises';

interface NetworkContext {
    connected: boolean;
    setConnected: Dispatch<any>;
}
export const NetworkContext = React.createContext<NetworkContext>(null);

export function NetworkProvider({ children }: { children: any }) {
    const [connected, setConnected] = useState(true);
    const deviceContext = React.useContext(DeviceContext);

    React.useEffect(() => {
        const unsubscribe = NetInfo.addEventListener((state) => {
            global.NetworkType = state.type;
            setConnected(state.isInternetReachable);
            // if (connected != state.isConnected) {
            //     setConnected(state.isConnected);
            // }
        });
        return unsubscribe();
    }, []);

    // if (deviceContext.isTizen) {
    //     // @ts-expect-error
    //     webapis.network.addNetworkStateChangeListener(function (value) {
    //         // @ts-expect-error
    //         if (value == webapis.network.NetworkState.GATEWAY_DISCONNECTED) {
    //             setConnected(false);
    //             // @ts-expect-error
    //         } else if (value == webapis.network.NetworkState.GATEWAY_CONNECTED) {
    //             setConnected(true);
    //         }
    //     });
    // }

    // maybe lg works like webtv??
    // if (deviceContext.isWebos) {
    //     window.addEventListener('online', () => {
    //         setConnected(true);
    //     });
    //     window.addEventListener('offline', () => {
    //         setConnected(false);
    //     });
    // }

    return (
        <NetworkContext.Provider
            value={{
                connected,
                setConnected,
            }}
        >
            {children}
        </NetworkContext.Provider>
    );
}

// export function useUser() {
//     const context = React.useContext(NetworkContext);
//     return context;
// }
