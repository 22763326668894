import * as React from 'react';
import { View, Image } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFontSize } from '../../../../styling/fontSizes';
import Text from '../../../components/text';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Pressable from '../../../components/pressable/pressable';
import { getFocusStyling, getPressableStyling } from '../../../components/helpers/helper';
import { faCirclePlus, faHandBackPointUp } from '@fortawesome/pro-light-svg-icons';
import { getCorrectLeftMargin } from '../../../../styling/appLeftMargin';
import { getFavoriteEmptyTextForRails, getWatchlistEmptyTextForRails } from './helper';

export const GetTopPart = ({ deviceContext, appContext, userContext, styling, focusContext, openOverviewPage, data, screenName }) => {
    return (
        <View style={{ flexDirection: 'column', marginBottom: getCorrectHeight(deviceContext, 10), marginLeft:2 }}>
            <View style={{ flexDirection: 'row' }}>
                {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && styling.content.list?.image != undefined && styling.content.list?.image != '' && (
                    <View style={{ marginRight: 10 }}>
                        <Image
                            source={{ uri: styling.content.list?.image }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.images.radius) / 2,
                                width: getCorrectHeight(deviceContext, 40),
                                height: getCorrectHeight(deviceContext, 40),
                            }}
                        />
                    </View>
                )}
                <View style={{ justifyContent: 'center' }}>
                    {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.show_title && (
                        <>
                            <Text
                                numberOfLines={1}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontWeight: styling.placement.title_bold ? 'bold' : null,
                                    fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                    color: styling.placement.title_color,

                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                    paddingRight: getCorrectWidth(deviceContext, 30),
                                }}
                            >
                                {getTitle(styling.content.list?.title, styling, userContext)}
                            </Text>
                        </>
                    )}
                    {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && getTagline(styling.content.list?.tagline, styling, userContext) != 'Edit to add your text' && (
                        <>
                            <Text
                                numberOfLines={1}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontWeight: styling.placement.title_bold ? 'bold' : null,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    color: styling.placement.title_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                    paddingRight: getCorrectWidth(deviceContext, 10),
                                }}
                            >
                                {getTagline(styling.content.list?.tagline, styling, userContext)}
                            </Text>
                        </>
                    )}
                </View>
                {deviceContext.isPhone && (styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && (
                    <View style={{flex:1, justifyContent: 'center' , alignItems:'flex-end', paddingRight:10}}>
                        <Pressable focusContext={focusContext} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => openOverviewPage()}>
                            <FontAwesomeIcon icon={faChevronRight} color={'#fff'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                        </Pressable>
                    </View>
                )}
            </View>
        </View>
    );
};
export const GetScrollElement = ({ height, scrollLeft, scrollRight, deviceContext, appContext }) => {
    return (
        <View pointerEvents={'none'} style={{ position: 'absolute', flex: 1, height: height, width: '100%', justifyContent: 'center' }}>
            <View style={{ flexDirection: 'row' }}>
                <View pointerEvents={'box-none'}>
                    <Pressable
                        onPress={() => scrollLeft()}
                        style={{
                            marginLeft: getCorrectWidth(deviceContext, 10),

                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                            backgroundColor: getFocusStyling('Buttons', appContext).focus.backgroundColor,
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                    >
                        <View
                            style={{
                                minWidth: getCorrectWidth(deviceContext, 25),
                                minHeight: getCorrectWidth(deviceContext, 25),
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} color={'#fff'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
                <View pointerEvents={'box-none'} style={{ position: 'absolute', right: 0 }}>
                    <Pressable
                        onPress={() => scrollRight()}
                        style={{
                            marginRight: getCorrectWidth(deviceContext, 10),

                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                            backgroundColor: getFocusStyling('Buttons', appContext).focus.backgroundColor,
                        }}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                    >
                        <View
                            style={{
                                minWidth: getCorrectWidth(deviceContext, 25),
                                minHeight: getCorrectWidth(deviceContext, 25),
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} color={'#fff'} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
                        </View>
                    </Pressable>
                </View>
            </View>
        </View>
    );
};
export const GetBottomPart = ({ height, width, styling, deviceContext, appContext, screenName, extraTopBottomMargin, focusContext, lang, userContext }) => {
    return (
        <View
            style={{
                height: height,
                flexDirection: 'row',
                backgroundColor: styling.placement.background_color,
                marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName) + 5,
                marginTop: appContext.application.theme.rails_top_margin + extraTopBottomMargin(),
                marginBottom: appContext.application.theme.rails_bottom_margin + extraTopBottomMargin(),
            }}
        >
            <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} focusContext={focusContext}>
                <View
                    style={{
                        marginRight: 0,
                        borderColor: styling.placement.title_color,
                        borderStyle: 'dashed',
                        borderWidth: 2,
                        height: height,
                        width: width,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: getPressableStyling(styling.components?.button._id, appContext).borderRadius,
                    }}
                >
                    <FontAwesomeIcon icon={faCirclePlus} color={styling.placement.title_color} size={getFontSize(deviceContext, 'ExtraExtraLarge')}></FontAwesomeIcon>
                </View>
            </Pressable>
            <View
                style={{
                    height: height,
                    justifyContent: 'center',
                    // alignItems: 'center',
                    marginLeft: getCorrectWidth(deviceContext, 25),
                    flex: 1,
                }}
            >
                {styling.content.type == 'Favorites' && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontWeight: styling.placement.title_bold ? 'bold' : null,
                            fontSize: getFontSize(deviceContext, 'Large'),
                            color: styling.placement.title_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                            paddingRight: getCorrectWidth(deviceContext, 30),
                        }}
                    >
                        {lang.getTranslation(userContext, getFavoriteEmptyTextForRails(styling.content.list?.type))}
                    </Text>
                )}
                {styling.content.type == 'Watched' && (
                    <Text
                        style={{
                            fontFamily: deviceContext.fontType,
                            fontWeight: styling.placement.title_bold ? 'bold' : null,
                            fontSize: getFontSize(deviceContext, 'Large'),
                            color: styling.placement.title_color,
                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                            textShadowOffset: { width: -1, height: 1 },
                            textShadowRadius: 2,
                            paddingRight: getCorrectWidth(deviceContext, 30),
                        }}
                    >
                        {lang.getTranslation(userContext, getWatchlistEmptyTextForRails(styling.content.list?.type))}
                    </Text>
                )}
            </View>
        </View>
    );
};
export const PressAndHold = ({ deviceContext, appContext, userContext, lang }) => {
    return (
        <View style={{ alignItems: 'flex-end', marginTop: getCorrectHeight(deviceContext, 5) }}>
            <View
                style={{
                    backgroundColor: appContext.application.theme.tags.background,
                    padding: getCorrectWidth(deviceContext, 4),
                    borderRadius: getCorrectWidth(deviceContext, appContext.application.theme.tags.radius),
                    paddingHorizontal: getCorrectWidth(deviceContext, 8),
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: getCorrectWidth(deviceContext,5)
                }}
            >
                <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal')} style={{ color: '#999', marginRight: getCorrectWidth(deviceContext, 2) }} icon={faHandBackPointUp} />
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontWeight: 'bold',
                        fontSize: getFontSize(deviceContext, 'Small'),
                        color: '#999',
                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                        textShadowOffset: { width: -1, height: 1 },
                        textShadowRadius: 2,
                    }}
                >
                    {lang.getTranslation(userContext, 'press_and_hold')}
                </Text>
            </View>
        </View>
    );
};

const getTitle = (titles, styling, userContext) => {
    if (titles == undefined || titles.length == 0) {
        return '';
    }
    var test = styling.content.list?.title.find((t) => t.language == userContext.selectedLanguage);
    if (test != undefined) {
        return test.title;
    } else {
        if (styling.content.list?.title != undefined) {
            return styling.content.list?.title[0].title;
        } else {
            return '';
        }
    }
};

const getTagline = (taglines, styling, userContext) => {
    if (taglines == undefined || taglines.length == 0) {
        return '';
    }
    var test = styling.content.list?.tagline.find((t) => t.language == userContext.selectedLanguage);
    if (test != undefined) {
        return test.title;
    } else {
        if (styling.content.list?.tagline != undefined) {
            return styling.content.list?.tagline[0].title;
        } else {
            return '';
        }
    }
};
