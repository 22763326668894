import { Pressable, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';
import { getMessageStyling } from '../../../application/builder/components/helpers/helper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCoins } from '@fortawesome/pro-light-svg-icons';
import { ContentContext } from '../../../application/context/contentContext';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const insets = useSafeAreaInsets();
    const [show, setShow] = React.useState(false);
    const [transactions, setTransactions] = React.useState([] as any);

    React.useEffect(() => {
        var transactions_ = [] as any;
        userContext.profile?.payperview?.channels.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.radios.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.cams.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.movies.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile.payperview?.series.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.shorts.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.games?.forEach(element => {
            transactions_.push(element)
        });
        userContext.profile?.payperview?.podcasts.forEach((element) => {
            transactions_.push(element);
        });
        userContext.profile?.payperview?.music?.forEach(element => {
            transactions_.push(element)
        });
        setTransactions(transactions_);
        setShow(true);
    }, [navigation, route]);

    useFocusEffect(
        React.useCallback(() => {
            contentContext.setActiveMenu(route.name);
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.WALLET, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <ScreenWrapper
            style={{ flex: 1 }}
            focusOptions={{
                ...focusOptions,
            }}
        >
            {show && (
                <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: appContext.application.theme.settings.background_color1,
                            borderRadius: appContext.application.theme.settings.border_radius,
                            margin:getCorrectWidth(deviceContext,10)
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                            }}
                        >
                            {lang.getTranslation(userContext, 'your_wallet_transactions')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                                fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'your_wallet_transactions_info').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 4, alignItems: 'center', paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}>
                        {transactions?.length > 0 && (
                            <ScrollView
                                contentContainerStyle={{
                                    paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                                }}
                            >
                                <Pressable
                                    focusOptions={{
                                        hasPreferredFocus: true,
                                        animator: {
                                            type: 'scale',
                                            focus: {
                                                scale: 1.01,
                                            },
                                        },
                                    }}
                                    style={{
                                        padding: getCorrectWidth(deviceContext, 10),
                                        flexDirection: 'row',
                                        width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                        backgroundColor: appContext.application.theme.settings.background_color1,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                        marginHorizontal: getCorrectWidth(deviceContext, 10),
                                        marginVertical: getCorrectWidth(deviceContext, 3),
                                    }}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            margin: getCorrectHeight(deviceContext, 8),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(deviceContext, 'transaction_type')}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            margin: getCorrectHeight(deviceContext, 8),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(deviceContext, 'Content')}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            margin: getCorrectHeight(deviceContext, 8),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(deviceContext, 'period')}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            margin: getCorrectHeight(deviceContext, 8),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(deviceContext, 'date_from_to')}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            flex: 1,
                                            margin: getCorrectHeight(deviceContext, 8),
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: getMessageStyling(appContext).color,
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {lang.getTranslation(deviceContext, 'amount')}
                                        </Text>
                                    </View>
                                </Pressable>
                                {transactions?.map((transaction) => {
                                    return (
                                        <Pressable
                                            focusOptions={{
                                                animator: {
                                                    type: 'scale',
                                                    focus: {
                                                        scale: 1.01,
                                                    },
                                                },
                                            }}
                                            style={{
                                                padding: getCorrectWidth(deviceContext, 10),
                                                flexDirection: 'row',
                                                width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                                backgroundColor: appContext.application.theme.settings.background_color2,
                                                borderRadius: appContext.application.theme.settings.border_radius,
                                                marginHorizontal: getCorrectWidth(deviceContext, 10),
                                                marginVertical: getCorrectWidth(deviceContext, 3),
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    margin: getCorrectHeight(deviceContext, 8),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: getMessageStyling(appContext).color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {transaction?.transaction_type?.toUpperCase()}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    margin: getCorrectHeight(deviceContext, 8),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: getMessageStyling(appContext).color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {lang.getTranslation(deviceContext, transaction.content_type)}: {transaction.content.name}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    margin: getCorrectHeight(deviceContext, 8),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: getMessageStyling(appContext).color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {transaction.access_length} {lang.getTranslation(deviceContext, transaction.access_type + 's')}
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    margin: getCorrectHeight(deviceContext, 8),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: getMessageStyling(appContext).color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {moment.unix(transaction.ppv_start).format('ll')} - {moment.unix(transaction.ppv_end).format('ll')}
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flex: 1,
                                                    margin: getCorrectHeight(deviceContext, 8),
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: getMessageStyling(appContext).color,
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        fontWeight: 'bold',
                                                        marginRight:10
                                                    }}
                                                >
                                                    {transaction.transaction_amount}
                                                </Text>
                                                <FontAwesomeIcon size={getFontSize(deviceContext, 'Large')} style={{ color: appContext.application.theme.icons.background }} icon={faCoins} />
                                            </View>
                                        </Pressable>
                                    );
                                })}
                            </ScrollView>
                        )}
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
